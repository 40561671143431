import styles from './styles.module.scss'
import { add, subtract, multiply, divide, round } from 'lodash-es'
// import { atan2, chain, derivative, e, evaluate, log, pi, pow, sqrt } from 'mathjs'

type cprops = {
  value: number
  w?: string
  h?: string
}

export default function Progress(props: cprops) {
  const num = props.value > 1 ? 1 : props.value < 0 ? 0 : round(props.value, 8)
  const n1 = round(num * 10000, 8)
  const n2 = round(n1 / 100, 8)

  const percentage = `${n2}%`

  const mid = 70
  return (
    <div className={styles.progressWrap}>
      <div className={styles.progress} style={{ width: percentage }}></div>
      <div className={styles.desc}>
        {percentage}
      </div>
    </div>
  )
}
