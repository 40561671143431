import React, { useState } from 'react'
import { CheckIcon } from '@chakra-ui/icons'
import { Box, Flex } from '@chakra-ui/react'
import styles from './style.module.scss'
import { Link } from 'react-router-dom'
import { PageTitle } from '../../components'
import { Tooltip } from 'antd'
type cprops = {
  list?: any[]
}

export default function ProposedBlock({ list }: cprops) {
  return (
    <Box className={styles.wrapperBox}>
      <Flex justifyContent="space-between" alignItems="center">
        <PageTitle size="sm" title={'Uptime'}></PageTitle>
        <Box>Last 100 Blocks</Box>
      </Flex>

      <Flex wrap="wrap">
        {list?.map((item: any, index: any) => {
          return (
            <Tooltip placement="top" trigger="hover" title={item.key} color="#444948" key={index}>
              <Box className={styles.uptimeItem}>{!item.value ? <Box className={styles.uptimeItemError}>X</Box> : ''}</Box>
            </Tooltip>
          )
        })}
      </Flex>

      <Flex mt="18px" justifyContent="space-between"></Flex>
    </Box>
  )
}
