import { u2unit } from './u2unit'
import { getSrcAmountAndDenom } from './getSrcAmount'
import { isObject, isArray } from '../utils'

/**
 * 把usrg, usrc单位转换成srg, src
 * @param data
 */
export const unitDataConvert = (data: any): void => {
  if (!data) {
    return
  }
  if (isArray(data)) {
    data.forEach((item: any) => unitDataConvert(item))
    return
  }
  if (isArray(data.amount)) {
    data.amount.forEach((item: any) => unitDataConvert(item))
    return
  }
  if (isObject(data?.amount)) {
    data = data.amount
  }
  const { denom, amount }: {denom: string, amount: string | number} = data ?? {}
  switch (denom) {
    case 'usrg':
      data.amount = u2unit(amount)
      data.denom = 'srg'
      break
    case 'usrc':
      data.amount = u2unit(amount)
      data.denom = 'src'
      break
  }
}
