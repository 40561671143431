export enum FixedDepositPeriod {
  PERIOD_1_MONTHS = 0,
  PERIOD_3_MONTHS = 1,
  PERIOD_6_MONTHS = 2,
  PERIOD_12_MONTHS = 3,
  PERIOD_24_MONTHS = 4,
  PERIOD_48_MONTHS = 5,
  UNRECOGNIZED = -1,
}

export const FixedDepositData: any = {
  PERIOD_1_MONTHS: {
    period: 1,
    name: 'Time limit 30 Days',
    code: 0,
    reward: 0.12,
    alias: 'annualRate_1_months',
  },
  PERIOD_3_MONTHS: {
    period: 3,
    name: 'Time limit 90 Days',
    code: 1,
    reward: 0.15,
    alias: 'annualRate_3_months',
  },
  PERIOD_6_MONTHS: {
    period: 6,
    name: 'Time limit 180 Days',
    code: 2,
    reward: 0.2,
    alias: 'annualRate_6_months',
  },
  PERIOD_12_MONTHS: {
    period: 12,
    name: 'Time limit 360 Days',
    code: 3,
    reward: 0.3,
    alias: 'annualRate_12_months',
  },
  PERIOD_24_MONTHS: {
    period: 24,
    name: 'Time limit 720 Days',
    code: 4,
    reward: 0.4,
    alias: 'annualRate_24_months',
  },
  PERIOD_48_MONTHS: {
    period: 48,
    name: 'Time limit 1440 Days',
    code: 5,
    reward: 0.5,
    alias: 'annualRate_48_months',
  },
}

export const FixedDepositData2: any = (() => {
  const data: any = {}
  for (const key in FixedDepositData) {
    if (Object.prototype.hasOwnProperty.call(FixedDepositData, key)) {
      const element = FixedDepositData[key]
      data[element.alias] = element
    }
  }

  return data
})()

const gas_limit = 200000
const gas_price = 5
export const baseFee = gas_limit * gas_price / 1e6

// 最小交易费
export const minFee = 200 / 1e6

// 费率
export const rate = 0.0001

// 交易费用阙值对应的金额阙值
export const amountThreshold = 20000 / 0.0001

export const gas = 200000

export type NET_TYPES_ATTR = 'Mainnet' | 'Devnet'

// 网络类型本地存储名称
export const NET_TYPE_LOCAL_STORAGE_NAME = 'net_type'

let baseUrl_1 = `./`
let baseUrl_2 = `./`
if(/localhost/.test(location.href)){
  // baseUrl_1 = `//52.199.174.6`
  // baseUrl_2 = `//52.199.174.6`
  baseUrl_1 = `http://118.175.0.242:1317`
  baseUrl_2 = `http://118.175.0.242:8081`
}

/**
 * 网络类型配置
 */
export const NET_TYPES = {
  Devnet: '//192.168.0.206:1317',
  // Mainnet: '//118.175.0.242:1317'
  // Mainnet: '//52.199.174.6'
  Mainnet: baseUrl_1
}

export const BACKEND_NET_TYPES = {
  Devnet: '//192.168.0.206:8081',
  // Mainnet: '//118.175.0.242:8081'
  // Mainnet: '//52.199.174.6'
  Mainnet: baseUrl_2
}

const type: NET_TYPES_ATTR = (process.env.BASE_URL_TYPE as any) || 'Mainnet'

export const CHAINEND_BASE_URL = NET_TYPES[localStorage[NET_TYPE_LOCAL_STORAGE_NAME] ? (localStorage[NET_TYPE_LOCAL_STORAGE_NAME] as NET_TYPES_ATTR ) : type]

export const BACKEND_BASE_URL = BACKEND_NET_TYPES[localStorage[NET_TYPE_LOCAL_STORAGE_NAME] ? (localStorage[NET_TYPE_LOCAL_STORAGE_NAME] as NET_TYPES_ATTR ) : type]

