import React, { useState } from 'react'
import { CheckIcon } from '@chakra-ui/icons'
import { Table, Thead, Tbody, Th, Tr, Td, TableContainer, Box, Flex, Image, Button, Checkbox } from '@chakra-ui/react'
import styles from './style.module.scss'
import { Link } from 'react-router-dom'
import { PageTitle } from '../../components'
import MobileTable from '../../components/mobileTable';
import { Tooltip } from 'antd'
import { howLongAgo } from '../../utility/tools'
import { cutText } from 'common'
import dayjs from 'dayjs'
type cprops = {
  list?: any[]
  amount: any
}

export default function ProposedBlock({ list, amount }: cprops) {
  const columns = [
    {
      title: 'Height',
      dataIndex: 'height',
      render: (height: string) => {
        return (
          <span className={styles.highlight}>{height}</span>
        )
      }
    },
    {
      title: 'Block Hash',
      dataIndex: 'hash',
      render: (hash: string, item: any) => {
        return (
          <Tooltip placement="top" title={item.hash} color="#444948">
            {/* <span className={styles.highlight}>{cutText(item.hash)}</span> */}
            <Link to={'/blockDetail?height=' + item.height} className={styles.highlight}>
              {cutText(hash)}
            </Link>
          </Tooltip>
        )
      }
    },
    {
      title: 'Txs',
      dataIndex: 'num_txs',
      render: (num_txs: string) => {
        return (
          <span>{num_txs}</span>
        )
      }
    },
    {
      title: 'Time',
      dataIndex: 'timestamp',
      render: (timestamp: string) => {
        return (
          <Tooltip placement="top" title={dayjs(timestamp).format('MMM D, YYYY, h:mm A')} color="#444948">
            { howLongAgo(timestamp) }
          </Tooltip>
        )
      }
    }
  ]

  return (
    <Box className={styles.wrapperBox}>
      <Flex justifyContent="space-between">
        <PageTitle size="sm" title={'Proposed Blocks'}></PageTitle>
        <Flex className={styles.total} alignItems="center" lineHeight="14px">
          <Image src="./images/total@2x.png" w="14px" h="14px" mr="4px"></Image>
          <span>Total</span>
          <span>: {amount || 0} blocks</span>
        </Flex>
      </Flex>

      <TableContainer className='pc_table_container'>
        <Table variant="striped" colorScheme="blackAlpha" size="md">
          <Thead>
            <Tr>
              {columns?.map((item: any) => {
                return <Th key={item.title}>{item.title}</Th>
              })}
            </Tr>
          </Thead>

          <Tbody>
            {list?.map((item: any) => {
              return (
                <Tr key={item.hash}>
                  {
                    columns.map((td: any, index: number) => {
                      const _val = item[td.dataIndex]
                      return (
                        <Td key={`${item.hash}_td_${index}`} {...(td.tdAttr || {})}>
                          { td.render(_val, item) }
                        </Td>
                      )
                    })
                  }
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <MobileTable
        className={`${styles.proposed_block_mobile_table}`}
        dataSource={list}
        columns={columns}
        rowKey={(record: any) => record?.hash}
      />
      <Flex mt="18px" justifyContent="space-between"></Flex>
    </Box>
  )
}
