import React, { useEffect, useState } from 'react'
import { Table, Thead, Tbody, Th, Tr, Td, TableContainer, Box, Flex, Button, Checkbox } from '@chakra-ui/react'
import { Tooltip } from 'antd'
import styles from './style.module.scss'
import { Link } from 'react-router-dom'
import { getBlocksByLatest, getBlocksByHeight, validator } from '../../resources/api'
import { howLongAgo, hash16 } from '../../utility/tools'
import axios from 'axios'
import dayjs from 'dayjs'

export default function BlokTable() {
  const columns = [{ title: 'Height' }, { title: 'Proposer' }, { title: 'Txs' }, { title: 'Time' }]
  const [data, setData] = useState<any[]>([])
  const [isAuto, setIsAuto] = useState<boolean>(true)
  const [newBlockNum, setNewBlockNum] = useState<any>(0)
  // const ref = useRef(data)
  // ref.current = data
  const [loading, setLoading] = useState(false as boolean)

  const CancelToken = axios.CancelToken

  const blocksByHeight = async (height: any) => {
    const res = await getBlocksByHeight(height).catch((e) => console.error('e'))
    // res.block.header.proposer_address = 'BEAEEF13EB2235991CD157828C032D04B059FC10'
    const res2 = await validator(hash16(res.block.header.proposer_address)).catch((e) => console.error('e'))
    return {
      ...res,
      ...res2.validator,
    }
  }

  // const blocksByLatest = async (height: any) => {
  //   const res = await getBlocksByLatest(height).catch((e) => console.log('e'))
  //   const res2 = await validator(hash16(res.block.header.proposer_address)).catch((e) => console.log('e'))
  //   return {
  //     ...res,
  //     ...res2,
  //   }
  // }

  let loopId: any = null
  const loopLoad = () => {
    // if (!isAuto) {
    //   clearInterval(loopId)
    //   return
    // }
    try {
      let source: any
      const request = async () => {
        if (source?.cancel) {
          source.cancel('cancel')
        }
        source = CancelToken.source()
        const last = await getBlocksByLatest({ cancelToken: source.token })

        // last.block.header.proposer_address = 'BEAEEF13EB2235991CD157828C032D04B059FC10'
        const res2 = await validator(hash16(last.block.header.proposer_address)).catch((e) => console.error('e'))

        const _data = {
          ...last,
          ...res2?.validator,
        }

        if (isAuto) {
          setData((val) => {
            if (val.find((v) => v.block.header.height === last.block.header.height)) {
              return val
            }
            return [_data, ...val.slice(0, 4)]
          })
        } else {
          setNewBlockNum((val: any) => {
            return val + 1
          })

          // setNotice(true)
          // setTimeout(() => {
          // setNotice(false)
          // }, 2500)
        }
      }

      loopId = setInterval(request, 3100)
    } catch (error) {
      console.error('loopLoad error: ', error)
    }
  }

  const initData = async () => {
    try {
      setLoading(true)
      const last = await getBlocksByLatest()
      // last.block.header.proposer_address = 'BEAEEF13EB2235991CD157828C032D04B059FC10'
      const res2 = await validator(hash16(last.block.header.proposer_address)).catch((e) => console.error(e))
      let height = Number(last.block.header.height)

      const _data = {
        ...last,
        ...res2?.validator,
      }
      const temp = new Array(4).fill(1)
      const requestLsit = temp.map(() => blocksByHeight(--height))
      const list = await Promise.all(requestLsit)

      // console.log('block list:::', res2.validator)
      setData([_data, ...list])
      setLoading(false)
      return last
    } catch (error) {
      console.error('initData error: ', error)
    }
  }

  useEffect(() => {
    initData().then(loopLoad)
    return () => {
      clearInterval(loopId)
    }
  }, [isAuto])

  return (
    <Box className='dashboard_block_box' position="relative">
      {/* {loading ? <Loading></Loading> : ''} */}

      <TableContainer className={styles.blockTable} mt="0px">
        <Table variant="striped" colorScheme="blackAlpha" size="lg">
          <Thead>
            <Tr>
              {columns?.map((item: any) => {
                return <Th key={item.title}>{item.title}</Th>
              })}
            </Tr>
          </Thead>

          <Tbody>
            {data?.map((item: any) => {
              return (
                <Tr key={item.block.header.height}>
                  <Td>
                    <Link to={{ pathname: '/blockDetail', search: `?height=${item.block.header.height}` }}>
                      <span className={styles.highlight}>{item.block.header.height}</span>
                    </Link>
                  </Td>
                  <Td>
                    {/* <Link to={{ pathname: '/validatorDetail', search: `?id=${item.block_id.hash}` }}>
                    <Tooltip placement="top" title={hash16(item.block.header.proposer_address)} color="#444948">
                      <span className={styles.highlight}>{cutText(hash16(item.block.header.proposer_address))}</span>
                    </Tooltip>
                    </Link> */}
                    {item.RegionName && item.RegionName !== 'Global' ? (
                      <Link to={{ pathname: '/validatorDetail', search: `?name=${item.RegionName}` }}>
                        <span className={styles.highlight}>{item.RegionName}</span>
                      </Link>
                    ) : item.RegionName ? (
                      <span className={styles.highlight}>{item.RegionName}</span>
                    ) : (
                      ''
                    )}
                  </Td>
                  <Td>{item.block.data.txs.length}</Td>
                  <Td>
                    <Tooltip placement="top" title={dayjs(item.block.header.time).format('MMM D, YYYY, h:mm A')} color="#444948">
                      {howLongAgo(item.block.header.time)}
                    </Tooltip>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <Flex className='block_more-chunk' justifyContent="space-between" minH="">
        <Link to="/blockchain">
          <Button w="202px" size="sm" h="30px">
            Show More
          </Button>
        </Link>
        <Box className='new_tips_box' textAlign="right">
          {!isAuto && newBlockNum ? <Box lineHeight="28px">{newBlockNum} New block!</Box> : ''}
        </Box>
        {/* <Checkbox
          defaultChecked
          borderColor="gray.400"
          isChecked={isAuto}
          color="gray.500"
          onChange={() => {
            setIsAuto(!isAuto)
            setNewBlockNum(0)
          }}
        >
          Auto
        </Checkbox> */}

        {/* <FormLabel mb="0">Auto</FormLabel> */}
        {/* <Switch id="email-alerts">Auto</Switch> */}
      </Flex>
    </Box>
  )
}
