import React, { useEffect, useState } from 'react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { Table, Thead, Tbody, Th, Tr, Td, TableContainer, Image, Flex, Box, Center } from '@chakra-ui/react'
import styles from './style.module.scss'
import { PageTitle } from '../../components'
import { dealType } from '../../utility/tools'
import { unitDataConvert, cutText, getAmountByValue } from 'common'
import { useChain } from '@cosmos-kit/react'
import { getTransByHash, messageByAccount } from '../../resources/api'
import dayjs from 'dayjs'
import { Pagination, Tooltip } from 'antd'
import { Link } from 'react-router-dom'

export default function TransactionTable(props: any) {
  const [list, setList] = useState<any[]>([])
  const [page, setPage] = useState<any>({
    total: 0,
    pageSize: 10,
  })
  const walletManager = useChain('srs')
  const { status, username, address } = walletManager

  const columns = [
    { title: 'Hash' },
    { title: 'Type' },
    // { title: 'From' },
    // { title: 'To' },
    { title: 'Amount' },
    { title: 'Fee' },
    { title: 'Time' },
    { title: 'Memo' },
  ]

  const srcMap: any = {
    def: './images/type@2x1.png',
    'IBC Transfer': './images/type@2x1.png',
    'Withdraw Rewards': './images/type@2x5.png',
    Send: './images/type@2x2.png',
    Delegate: './images/type@2x3.png',
    Vot: './images/type@2x4.png',
    Undelegate: './images/type@2x4.png',
    NewKyc: './images/type@2x4.png',
  }

  const initData = async (address: any, page_number = 1) => {
    // const res = await getAccount()
    // res.address = 'sil157ykw7kanea77pkwkrhw6v6a7gpzlwwcwjztup'
    const res2: any = await messageByAccount({ account: address, page_number })
    // console.log('res2.data:::', res2.data)
    const trans = res2.data.map(async (item: any) => {
      const info = await getTransByHash({ transaction_hash: item.transaction_hash })
      unitDataConvert(info?.data?.fee?.amount)
      unitDataConvert(info?.data?.messages?.[0]?.amount)
      unitDataConvert(info?.data?.messages?.[0]?.AgAmount)
      return {
        ...item,
        ...info.data,
        // timestamp: info.timestamp,
        accountAddress: address,
      }
    })
    const res3 = await Promise.all(trans)

    // console.log('res3::', res3)
    // console.log('category::', category)
    // console.log('list::', list)
    setPage((val: any) => {
      return {
        ...val,
        total: res2.amount,
      }
    })
    setList(res3)
  }

  useEffect(() => {
    initData(address)
  }, [address, props.updateNum])

  return (
    <>
      <TableContainer mt="10px" mb="15px">
        <PageTitle size="md" mt="12px" title={'Activity'} subTitle={''}></PageTitle>
        <Table variant="striped" colorScheme="blackAlpha" size="lg">
          <Thead>
            <Tr>
              {columns?.map((item: any, index: number) => {
                if (index === 0) {
                  return (
                    <Th key="{item.title}" pl="50px">
                      {item.title}
                    </Th>
                  )
                }

                return <Th key={item.title}>{item.title}</Th>
              })}
            </Tr>
          </Thead>

          <Tbody>
            {list?.map((item: any, index) => {
              return (
                <Tr key={item.hash}>
                  <Td pl="18px">
                    <Flex alignItems="center">
                      {item.success === true ? (
                        <Image boxSize="17px" src="./images/su.png" mt="-2px" mr="13px"></Image>
                      ) : (
                        // <Image boxSize="17px" src="./images/su.png" mt="-2px" mr="13px"></Image>
                        <Center style={{ background: '#E53E3E', borderRadius: '100%' }} mr="13px">
                          <SmallCloseIcon color="#fff"></SmallCloseIcon>
                        </Center>
                      )}
                      <Box className={styles.highlight}>
                        <Link to={{ pathname: '/transactionDetail', search: `?hash=${item.hash}` }}>
                          <Tooltip placement="top" title={item.hash} color="#444948">
                            <span className={styles.highlight}>{cutText(item.hash)}</span>
                          </Tooltip>
                        </Link>
                      </Box>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <Image boxSize="17px" src={srcMap[dealType(item.type)] || srcMap['def']} mt="-1px" mr="6px"></Image>
                      <Box className={styles.highlight}>{dealType(item.type)}</Box>
                    </Flex>
                  </Td>
                  <Td>
                    {getAmountByValue(item?.messages[0])/*{dealType(item.messages[0]['@type']) === 'Send'
                      ? setDigit(item.messages[0]?.['amount']?.[0]?.['amount'] || '0')
                      : typeof item.messages[0]?.['amount'] === 'string'
                      ? item.messages[0]?.['amount']
                      : setDigit(item.messages[0]?.['amount']?.['amount'] || '0')}*/}
                    <span className={styles.highlight}> { item?.messages[0]?.AgAmount ? 'GEAG' : 'GEAC' }</span>
                  </Td>
                  <Td>
                    {typeof item.fee?.amount === 'string' ? item.fee.amount : item.fee?.amount[0]?.['amount']}
                    <span className={styles.highlight}> GEAC</span>
                  </Td>
                  <Td>{dayjs(item.timestamp).format('MMM D, YYYY, h:mm A')}</Td>
                  <Td>
                    <div className="textOver" style={{ maxWidth: '100px' }}>
                      {item.memo}
                    </div>
                  </Td>
                  {/* <Td>
                  <div className="textOver" style={{ maxWidth: '100px' }}>
                    {item.memo}
                  </div>
                </Td> */}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {page.total > 10 && (
        <Flex justifyContent="flex-end">
          <Pagination
            total={page.total}
            showSizeChanger={false}
            showQuickJumper
            showTotal={(total) => `Total ${total} items`}
            onChange={(page, pagesize) => initData(address, page)}
          />
        </Flex>
      )}
    </>
  )
}
