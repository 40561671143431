import { ClientNotExistError } from '@cosmos-kit/core'

import { Srs } from './types'

interface SrsWindow {
  srs?: Srs
}

export const getSrsFromExtension: () => Promise<Srs | undefined> = async () => {
  if (typeof window === 'undefined') {
    return void 0
  }

  const srs = (window as SrsWindow).srs

  if (srs) {
    return srs
  }

  if (document.readyState === 'complete') {
    if (srs) {
      return srs
    } else {
      throw ClientNotExistError
    }
  }

  return new Promise((resolve, reject) => {
    const documentStateChange = (event: Event) => {
      if (event.target && (event.target as Document).readyState === 'complete') {
        if (srs) {
          resolve(srs)
        } else {
          reject(ClientNotExistError.message)
        }
        document.removeEventListener('readystatechange', documentStateChange)
      }
    }

    document.addEventListener('readystatechange', documentStateChange)
  })
}
