import React, { useEffect, useState, Fragment } from 'react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { Table, Thead, Tbody, Th, Tr, Td, TableContainer, Box, Flex, Image, Center } from '@chakra-ui/react'
import MobileTable from '../../components/mobileTable';
import styles from './style.module.scss'
import { getTxsByHash } from '../../resources/api'
import { Link } from 'react-router-dom'
import { dealType, setDigit } from '../../utility/tools'
import { cutText, getSrcAmountAndDenom } from 'common'
import classNames from "classnames";

type cprops = {
  hash: string[]
}

const renderAmount = (item: any) => {
  const _amount = item.tx.body.messages[0]?.amount || item.tx.body.messages[0]?.AgAmount || {}
  const res = getSrcAmountAndDenom(_amount)
  return <>
    {
      res.amount
    }
    <span
        className={styles.highlight}> {res.denom}</span>
  </>
}

export default function BlokTable({ hash }: cprops) {
  const [data, setData] = useState<any[]>([])

  const columns = [
    { 
      title: 'Hash', 
      dataIndex: 'txhash',
      render: (txhash: string, item: any) => {
        return (
          <Link to={{pathname: '/transactionDetail', search: `?hash=${item.tx_response.txhash}`}}>
            <span className={styles.highlight}>{cutText(item.tx_response.txhash)}</span>
          </Link>
        )
      }
    },
    { 
      title: 'Type',
      dataIndex: 'messages',
      render: (messages: string, item: any) => {
        return (
          <Flex alignItems="center">
            <Image
                boxSize="17px"
                src={srcMap[dealType(item.tx_response.tx?.body?.messages[0]['@type'])] || srcMap['def']}
                mt="-1px"
                mr="6px"
            ></Image>
            <Box
                className={styles.highlight}>{dealType(item.tx_response.tx?.body?.messages[0]['@type'])}</Box>
          </Flex>
        )
      } 
    },
    { 
      title: 'Result',
      render: (_: string, item: any) => {
        return (
          <Flex alignItems="center">
            {item.tx_response.code === 0 ? (
                <Image boxSize="17px" src="./images/su.png" mt="-2px" mr="6px"></Image>
            ) : (
                <Center style={{background: '#E53E3E', borderRadius: '100%'}} mr="6px">
                  <SmallCloseIcon color="#fff"></SmallCloseIcon>
                </Center>
            )}
            <Box>{item.tx_response.code === 0 ? 'Success' : 'fail'}</Box>
          </Flex>
        )
      }
    },
    { 
      title: 'Amount',
      render: (_: string, item: any) => {
        return (
          renderAmount(item)
        )
      } 
    },
    { 
      title: 'Fee',
      dataIndex: 'tx',
      render: (tx: string, item: any) => {
        const res = getSrcAmountAndDenom(item.tx.auth_info?.fee?.amount[0])
        return (
          <Fragment>
            { res.amount || '0'}
            <span className={styles.highlight}>
              {
                res.denom
              }
            </span>
          </Fragment>
        )
      }
    },
  ]

  const srcMap: any = {
    def: './images/type@2x1.png',
    'IBC Transfer': './images/type@2x1.png',
    'Withdraw Rewards': './images/type@2x5.png',
    Send: './images/type@2x2.png',
    Delegate: './images/type@2x3.png',
    Vot: './images/type@2x4.png',
    Undelegate: './images/type@2x4.png',
    NewKyc: './images/type@2x4.png',
  }


  const initData = async () => {
    const requestLsit = hash.map((v) => getTxsByHash(v))
    const list = await Promise.all(requestLsit)
    setData(list)
    // setLoading(false)
  }

  useEffect(() => {
    // console.log(123)
    initData()
  }, [hash])

  return (
    <Fragment>
      <TableContainer mt="0px" className='pc_table_container'>
        <Table variant="striped" colorScheme="blackAlpha" size="lg">
          <Thead>
            <Tr>
              {columns?.map((item: any, index: number) => {
                if (index === 0) {
                  return <Th key="{item.title}">{item.title}</Th>
                }
                return <Th key={item.title}>{item.title}</Th>
              })}
            </Tr>
          </Thead>

          <Tbody>
            {data?.map((item: any) => (
                <Tr key={item.tx_response.txhash}>
                  {
                    columns.map((td: any, index: number) => {
                      const _val = item[td.dataIndex]
                      return ( 
                        <Td key={`${item.txhash}_td_${index}`} {...(td.tdAttr || {})}>
                          { td.render(_val, item) }
                        </Td>
                      )
                    })
                  }
                </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <MobileTable
        dataSource={data}
        columns={columns}
        rowKey={(record: any) => record?.txhash}
      />
  </Fragment>
  )
}
