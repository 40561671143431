import React, {useEffect, useState} from 'react'
import {SmallCloseIcon} from '@chakra-ui/icons'
import {Box, Center, Flex, Image} from '@chakra-ui/react'
import {Loading, PageTitle, CopyItem} from '../../components'
import styles from './style.module.scss'
import {useLocation} from 'react-router-dom'
import {getTxsByHash} from '../../resources/api'
import {qs} from 'url-parse'
import {dealType, howLongAgo} from '../../utility/tools'
import TypeField from './typeField'
import dayjs from 'dayjs'

import {JsonViewer} from '@textea/json-viewer'
import {renderAmount} from "./renderAmount";
import {useRequest} from "ahooks";
import classNames from 'classnames'

export default function Home() {
    const [curTab, setCurTab] = useState(1)
    const [data, setData] = useState({tx: {}, tx_response: {}} as any)
    const {search} = useLocation()
    const searchs = qs.parse(search)
    const {loading} = useRequest(() => getTxsByHash(searchs.hash), {
        ready: !!searchs.hash,
        refreshDeps: [searchs.hash],
        onSuccess: (res: any) => {
            const _message = res.tx_response.tx?.body?.messages
            if(_message.length && Array.isArray(_message[0]?.address)){
                res.tx_response.tx.body.messages[0].address = res.tx_response.tx.body.messages[0].address[0]
            }
            setData(res)
        }
    })

    return (
        <Box position="relative">
            {loading ? <Loading></Loading> : ''}
            <div className={styles.container}>
                <PageTitle title={'Transaction Details'} mb='0px' subTitle={''}></PageTitle>

                <Flex justifyContent="space-between" alignItems="center">
                    <PageTitle size="md" title={'Information'} subTitle={''}></PageTitle>
                    <div className={styles.tabs}>
                        <div className={[styles.tab,curTab === 1 ? styles.tabActive : ''].join(' ')}
                             onClick={() => setCurTab(1)}>
                            Summary
                        </div>
                        <div className={[styles.tab,curTab === 2 ? styles.tabActive : ''].join(' ')}
                             onClick={() => setCurTab(2)}>
                            JSON
                        </div>
                    </div>
                </Flex>
                {
                    curTab === 1 ? (
                        <div className={styles.summary}>
                            <div className={styles.line}>
                                <div className={classNames(styles.key, "mobile_global_normal")}>Chain Id</div>
                                <div className={classNames(styles.val, "mobile_global_bold")}>gea</div>
                            </div>
                            <div className={styles.line}>
                                <div className={classNames(styles.key, "mobile_global_normal")}>TxHash</div>
                                <div className={classNames(styles.val, "mobile_global_bold")}>{data.tx_response.txhash} <CopyItem text={data?.tx_response?.txhash} /></div>
                            </div>
                            <div className={styles.line}>
                                <div className={classNames(styles.key, "mobile_global_normal")}>Status</div>
                                {/* <div className={classNames(styles.val, "mobile_global_bold")}>{data.tx_response.code === 0 ? 'Success' : 'fail'}</div> */}
                                <div className={classNames(styles.val, "mobile_global_bold")}>
                                    {data.tx_response?.code === 0 ? (
                                        <Flex alignItems="center" color="green.500">
                                            <Image className={styles.status_success_icon} src="images/su.png" mr="6px"></Image>
                                            <Box>Success</Box>
                                        </Flex>
                                    ) : data.tx_response?.code ? (
                                        <Flex alignItems="center">
                                            <Center mr="6px" w="18px" h="18px" bg="red.400" borderRadius="100%">
                                                <SmallCloseIcon color="#fff" fontSize="14px"></SmallCloseIcon>
                                            </Center>{' '}
                                            <Box lineHeight="25px" color="red.500">
                                                fail
                                            </Box>
                                        </Flex>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            <div className={styles.line}>
                                <div className={classNames(styles.key, "mobile_global_normal")}>Height</div>
                                <div className={classNames(styles.val, "mobile_global_bold")}>
                                    <span className={styles.highlight}>{data.tx_response.height}</span>
                                </div>
                            </div>
                            <div className={styles.line}>
                                <div className={classNames(styles.key, "mobile_global_normal")}>Time</div>
                                <div className={classNames(styles.val, "mobile_global_bold")}>
                                    {howLongAgo(data.tx_response.timestamp)}({dayjs(data.tx_response.timestamp).format('MMM D, YYYY, h:mm A')})
                                </div>
                            </div>
                            <div className={styles.line}>
                                <div className={classNames(styles.key, "mobile_global_normal")}>Fee</div>
                                {renderAmount(data?.tx?.auth_info?.fee?.amount)}
                            </div>
                            <div className={styles.line}>
                                <div className={classNames(styles.key, "mobile_global_normal")}>Gas(used/wanted)</div>
                                <div className={classNames(styles.val, "mobile_global_bold")}>
                                    {data.tx_response.gas_used} / {data.tx_response.gas_wanted}
                                </div>
                            </div>
                            <div className={styles.line}>
                                <div className={classNames(styles.key, "mobile_global_normal")}>Memo</div>
                                <div className={classNames(styles.val, "mobile_global_bold")}>{data.tx.body?.memo || '--'}</div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.jsonwrap}>
                            <JsonViewer value={data} rootName={false} defaultInspectDepth={4}/>
                        </div>
                    )
                }
                <PageTitle mt="32px" size="sm" title={'Messages'} subTitle={''}></PageTitle>
                <div className={classNames(styles.summary, styles.message_info)}>
                    <div className={styles.tit}><span className={styles.message_icon}></span>{dealType(data.tx_response.tx?.body?.messages[0]?.['@type'])}</div>
                    {/*{*/}
                    {/*    <RenderMessage message={data.tx_response.tx?.body?.messages[0]}/>*/}
                    {/*}*/}
                    {
                        data.tx_response.tx?.body?.messages[0] && <TypeField message={data.tx_response.tx?.body?.messages[0]}></TypeField>
                    }
                </div>
            </div>
        </Box>
    )
}
