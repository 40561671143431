import { AES, enc } from 'crypto-js'
import { PREFIX } from './config'
/**
 * 加密
 * @param message
 */
export const aesEncrypt = (message: string): string => {
  return AES.encrypt(message, PREFIX).toString()
}

/**
 * 解密
 * @param ciphertext
 */
export const aesDecrypt = (ciphertext: string): string => {
  return AES.decrypt(ciphertext, PREFIX).toString(enc.Utf8)
}
