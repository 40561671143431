import React, { ReactComponentElement, useEffect, useState } from 'react'
import MsgSend from './msgSend'

import MsgMultiSend from './msgMultiSend'
import MsgSubmitEvidence from './msgSubmitEvidence'
import MsgUnjail from './msgUnjail'

import MsgCreateValidator from './msgCreateValidator'
import MsgExitDelegate from './msgExitDelegate'
import MsgCreateRegion from './msgCreateRegion'
import MsgUpdateRegion from './msgUpdateRegion'
import MsgWithdraw from './msgWithdraw'
import MsgKickValidatorByAddress from './msgKickValidatorByAddress'
import MsgCreateDelegate from './msgCreateDelegate'
import MsgKickValidatorByPubkey from './msgKickValidatorByPubkey'
import MsgUndelegate from './msgUndelegate'
import MsgDeleteRegion from './msgDeleteRegion'
import MsgDelegate from './msgDelegate'

import MsgSetKycMaxStaking from './msgSetKycMaxStaking'
import MsgNewKyc from './msgNewKyc'
import MsgTransferKYC from './msgTransferKYC'
import MsgDoFixedWithdraw from './msgDoFixedWithdraw'
import MsgAgToAc from './msgAgToAc'
import MsgDoFixedDeposit from './msgDoFixedDeposit'
import MsgSetKycMinStaking from './msgSetKycMinStaking'
import MsgSetRegionFeeRate from './msgSetRegionFeeRate'
import MsgSetFixedDepositInterestRate from './msgSetFixedDepositInterestRate'
import MsgRemoveKyc from './msgRemoveKyc'

import MsgVerifyInvariant from './msgVerifyInvariant'

import MsgWithdrawValidatorCommission from './msgWithdrawValidatorCommission'
import MsgWithdrawDelegatorReward from './msgWithdrawDelegatorReward'
import MsgSetWithdrawAddress from './msgSetWithdrawAddress'
import MsgFundCommunityPool from './msgFundCommunityPool'

import MsgRevokeAllowance from './msgRevokeAllowance'
import MsgGrantAllowance from './msgGrantAllowance'

import MsgSubmitProposal from './msgSubmitProposal'
import MsgVoteWeighted from './msgVoteWeighted'
import MsgVote from './msgVote'
import MsgDeposit from './msgDeposit'
import MsgDefault from './msgDeposit'
import MsgBeginRedelegate from './msgBeginRedelegate'
import MsgEditValidator from './msgEditValidator'
import MsgCreateVestingAccount from './msgCreateVestingAccount'
// import { unitDataConvert } from 'common'

export default function TypeField(props: any) {
  const newMessage = Object.keys(props.message).reduce((prev: any, key: string) => {
    if (props.message[key] === '') return prev

    prev[key] = props.message[key]

    return prev
  }, {})
  
  // unitDataConvert(newMessage)

  switch (newMessage?.['@type'].match(/(\w+)$/g)?.[0] || '') {
    case 'MsgSend':
      return <MsgSend message={newMessage}></MsgSend>
      break

    case 'MsgMultiSend':
      return <MsgMultiSend message={newMessage}></MsgMultiSend>
      break

    case 'MsgSubmitEvidence':
      return <MsgSubmitEvidence message={newMessage}></MsgSubmitEvidence>
      break

    case 'MsgUnjail':
      return <MsgUnjail message={newMessage}></MsgUnjail>
      break

    // case '/srspoa.srstaking.MsgCreateValidator':
    //   return <MsgCreateValidator message={newMessage}></MsgCreateValidator>
    //   break

    case 'MsgExitDelegate':
      return <MsgExitDelegate message={newMessage}></MsgExitDelegate>
      break

    case 'MsgCreateRegion':
      return <MsgCreateRegion message={newMessage}></MsgCreateRegion>
      break

    case 'MsgUpdateRegion':
      return <MsgUpdateRegion message={newMessage}></MsgUpdateRegion>
      break

    case 'MsgWithdraw':
      return <MsgWithdraw message={newMessage}></MsgWithdraw>
      break

    case 'MsgKickValidatorByAddress':
      return <MsgKickValidatorByAddress message={newMessage}></MsgKickValidatorByAddress>
      break

    case 'MsgCreateDelegate':
      return <MsgCreateDelegate message={newMessage}></MsgCreateDelegate>
      break

    case 'MsgKickValidatorByPubkey':
      return <MsgKickValidatorByPubkey message={newMessage}></MsgKickValidatorByPubkey>
      break

    case 'MsgUnDelegate':
      return <MsgUndelegate message={newMessage}></MsgUndelegate>
      break

    case 'MsgDeleteRegion':
      return <MsgDeleteRegion message={newMessage}></MsgDeleteRegion>
      break

    case 'MsgDelegate':
      return <MsgDelegate message={newMessage}></MsgDelegate>
      break

    case 'MsgSetKycMaxStaking':
      return <MsgSetKycMaxStaking message={newMessage}></MsgSetKycMaxStaking>
      break

    case 'MsgNewKyc':
      return <MsgNewKyc message={newMessage}></MsgNewKyc>
      break

    case 'MsgTransferKYC':
      return <MsgTransferKYC message={newMessage}></MsgTransferKYC>
      break

    case 'MsgDoFixedWithdraw':
      return <MsgDoFixedWithdraw message={newMessage}></MsgDoFixedWithdraw>
      break

    case 'MsgAgToAc':
      return <MsgAgToAc message={newMessage}></MsgAgToAc>
      break

    case 'MsgDoFixedDeposit':
      return <MsgDoFixedDeposit message={newMessage}></MsgDoFixedDeposit>
      break

    case 'MsgSetKycMinStaking':
      return <MsgSetKycMinStaking message={newMessage}></MsgSetKycMinStaking>
      break

    case 'MsgSetRegionFeeRate':
      return <MsgSetRegionFeeRate message={newMessage}></MsgSetRegionFeeRate>
      break

    case 'MsgSetFixedDepositInterestRate':
      return <MsgSetFixedDepositInterestRate message={newMessage}></MsgSetFixedDepositInterestRate>
      break

    case 'MsgRemoveKyc':
      return <MsgRemoveKyc message={newMessage}></MsgRemoveKyc>
      break

    case 'MsgVerifyInvariant':
      return <MsgVerifyInvariant message={newMessage}></MsgVerifyInvariant>
      break

    case 'MsgWithdrawValidatorCommission':
      return <MsgWithdrawValidatorCommission message={newMessage}></MsgWithdrawValidatorCommission>
      break

    case 'MsgWithdrawDelegatorReward':
      return <MsgWithdrawDelegatorReward message={newMessage}></MsgWithdrawDelegatorReward>
      break

    case 'MsgSetWithdrawAddress':
      return <MsgSetWithdrawAddress message={newMessage}></MsgSetWithdrawAddress>
      break

    case 'MsgFundCommunityPool':
      return <MsgFundCommunityPool message={newMessage}></MsgFundCommunityPool>
      break

    case 'MsgRevokeAllowance':
      return <MsgRevokeAllowance message={newMessage}></MsgRevokeAllowance>
      break

    case 'MsgGrantAllowance':
      return <MsgGrantAllowance message={newMessage}></MsgGrantAllowance>
      break

    case 'MsgSubmitProposal':
      return <MsgSubmitProposal message={newMessage}></MsgSubmitProposal>
      break

    case 'MsgVoteWeighted':
      return <MsgVoteWeighted message={newMessage}></MsgVoteWeighted>
      break

    case 'MsgVote':
      return <MsgVote message={newMessage}></MsgVote>
      break

    case 'MsgDeposit':
      return <MsgDeposit message={newMessage}></MsgDeposit>
      break

    case 'MsgBeginRedelegate':
      return <MsgBeginRedelegate message={newMessage}></MsgBeginRedelegate>
      break

    // case '/cosmos.staking.v1beta1.MsgUndelegate':
    //   return <MsgUndelegate message={newMessage}></MsgUndelegate>
    //   break

    // case '/cosmos.staking.v1beta1.MsgCreateValidator':
    //   return <MsgCreateValidator message={newMessage}></MsgCreateValidator>
    //   break

    // case '/cosmos.staking.v1beta1.MsgDelegate':
    //   return <MsgDelegate message={newMessage}></MsgDelegate>
    //   break

    case 'MsgEditValidator':
      return <MsgEditValidator message={newMessage}></MsgEditValidator>
      break

    case 'MsgCreateVestingAccount':
      return <MsgCreateVestingAccount message={newMessage}></MsgCreateVestingAccount>
      break

    default:
      return <MsgDefault message={newMessage}></MsgDefault>
      break
  }
}
