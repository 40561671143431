import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import RootComponent from "./RootComponent";
import { persistor, store } from "./store/reducers/store";
import responsive from "./utility/responsive";
const App: React.FC = () => {
  const cbOfSizeChange = ({ isKeepSize, size }: any) => {
    const _event = new CustomEvent(`event_sizeChange`, {
      detail: { isKeepSize, size },
    });
    document.dispatchEvent(_event);
  };
  responsive.init(cbOfSizeChange);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RootComponent />
      </PersistGate>
    </Provider>
  );
};

export default App;
