import React, { useEffect, useState } from 'react'
import { Flex, Text, Box, Button, Avatar, useToast, useDisclosure } from '@chakra-ui/react'
import { PageTitle } from '../../components'
import styles from './style.module.scss'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { round } from 'lodash-es'
import { add, multiAdd } from '../../utility/utils'
import TransactionTable from './transactionTable'
import { useChain } from '@cosmos-kit/react'
import FixedDepositTable from './fixedDepositTable'
import {
  getBalanceByAddr,
  getKyc,
  delegationByAddress,
  getRegionVaultById,
  delegation,
  getTakingRegionById,
  // getFixedDeposit,
  fixedDepositByAcct,
  kycBonus,
  delegationAmount,
  fixedDepositByRegion,
} from '../../resources/api'
import getTotalStakingBalance from '../../resources/getTotalStakingBalance'
import Send from './send'
import Exchange from './exchange'
import Mange from './mange'
import Stake from './stake'
import Unbonding from './unbonding'
import Qrcode from './qrcode'
import { unitDataConvert, u2unit, BASE_FEE, GAS } from 'common'

const defData = {
  fixedInterest: '0',
  kycBonus: {
    src: '0',
    srg: '0',
  },
  ac: 0,
  ag: 0,
  showAc: '0',
  showAg: '0',
  // stakedAmount: 0,
  // staked: '',
  power: 0,
  flexibleBalance: 0,
  totalStaked: 0,
  showTotalStaked: '0',
  total: 0,
}

const defArea = {
  regionId: '',
  areaSrc: 0,
  areaSrg: 0,
  areaShowSrc: '0',
  areaShowSrg: '0',
  stakeSrc: '0',
  allowStakePower: '0',
  totalStakePower: '0',
  maxRate: '',
  fixedTotal: 0,
  areaTotalPledge: 0,
  // fixedInterest: 0,
  fixedInterestRegion: 0,
}

const statusMsgMap: any = {
  STATUS_DELEGATE_NOCHANGE: 'Delegate NoChange',
  STATUS_CREATE_DELEGATE: 'Create Delegate',
  STATUS_DELEGATE: 'Delegate',
  STATUS_UNDELEGATE: 'Undelegate',
  STATUS_EXIT_DELEGATE: 'Exit Delegate',
}

const getStatusMsg = (stakedStatus: any) => {
  return `Please wait for ${statusMsgMap[stakedStatus]} to finish before trying again`
}

export default function Wallet() {
  const toast = useToast()
  const toastIdRef: any = React.useRef()
  const {search} = useLocation()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [ data, setData ] = useState<any>(defData)
  const [ isKyc, setIsKyc ] = useState<any>(false)
  const [ fixedList, setFixedList ] = useState<any[]>([])
  const [ fixedRegionList, setFixedRegionList ] = useState<any[]>([])
  const [ fixedExpiredList, setFixedExpiredList ] = useState<any[]>([])
  const [ updateNum, setUpdateNum ] = useState<any>(1)
  // const [power, setPower] = useState<any>(0.0025)
  const [ areaInfo, setAreaInfo ] = useState<any>(defArea)
  // const [address, setAddress] = useState<any>('sil1uvq35nxwslx55m2av86cypj6gpz2x57l0kcuyg')
  const {isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2} = useDisclosure()
  const {isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3} = useDisclosure()
  const {isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4} = useDisclosure()
  const {isOpen: isOpen5, onOpen: onOpen5, onClose: onClose5} = useDisclosure()
  const {isOpen: isOpen6, onOpen: onOpen6, onClose: onClose6} = useDisclosure()
  const walletManager = useChain('srs')
  const {status, username, address} = walletManager
  // const { status, username } = walletManager

  const searchs = qs.parse(search)

  const initData = async (ms = 500) => {
    if (!address) {
      return
    }

    try {
      // 如果不是kyc用户，调用getKyc http是404
      const [ res2, res3 ]: any = await Promise.allSettled([ getBalanceByAddr(address), getKyc(address) ])
      // console.log('res2:::', res2)
      // let stakedAmount = 0
      // let staked = '0'
      let ac = 0
      let ag = 0
      let showAc = '0'
      let showAg = '0'
      let areaSrc = 0
      let areaSrg = 0
      let areaShowSrc = '0'
      let areaShowSrg = '0'
      unitDataConvert(res2?.value?.balances)
      res2?.value?.balances?.forEach((item: any) => {
        if (item.denom === 'src') {
          ac = Number(item.amount)
          // showAc = ac
          showAc = ac + ''
        }
        if (item.denom === 'srg') {
          ag = Number(item.amount)
          // showAg = ag
          showAg = ag + ''
        }
      })

      // console.log('res3:::::', res3)

      if (res3 && res3.status !== 'rejected' && res3?.value?.kyc) {
        setIsKyc(true)
        try {
          const r3 = await delegation()
          const user = new Map()
          // 质押列表 根据区分组
          r3.delegation.forEach((item: any) => {
            if (user.has(item.RegionID)) {
              user.get(item.RegionID).push(item.amountAC)
            } else {
              user.set(item.RegionID, [ item.amountAC ])
            }
          })

          const res5 = await getRegionVaultById(res3.value.kyc.regionId)
          const res6 = await getBalanceByAddr(res5.region.baseAccountAddr)
          // const res7 = await getTakingRegionById(res3.value.kyc.regionId)

          // 获取总质押金额
          const totalStakingBalance = await getTotalStakingBalance(res5.region.depositAddress, res5.region.fixedDepositAccountAddr)

          // console.log('res7777777777:::', res7)

          unitDataConvert(res6?.balances)
          // kyc用户
          const quota1 = Array.isArray(res6.balances)
            ? res6.balances.reduce((prev: number, item1: any) => {
              unitDataConvert(item1)
              if (item1.denom === 'src') {
                return prev + Number(item1.amount)
              }
              return prev
            }, 0)
            : 0

          // console.log('quota1::', quota1)

          // console.log('user.get(item.regionId)', user.get(res3.value.kyc.regionId))
          const quota2 = Array.isArray(user.get(res3.value.kyc.regionId))
            ? user.get(res3.value.kyc.regionId).reduce((prev: number, item2: any) => {
              return prev + Number(item2)
            }, 0)
            : 0

          // console.log('quota2::', quota2)

          // console.log('res6:::::', res6)
          res6?.balances.forEach((item: any) => {
            if (item.denom === 'src') {
              areaSrc = Number(item.amount)
              areaShowSrc = areaSrc + ''
            } else if (item.denom === 'srg') {
              areaSrg = Number(item.amount)
              areaShowSrg = areaSrg + ''
            }
          })

          let max = 0
          for (const key in res5.region.annualRate) {
            if (Object.prototype.hasOwnProperty.call(res5.region.annualRate, key)) {
              const element = Number(res5.region.annualRate[key])
              max = max > element ? max : element
            }
          }
          const stakeSrc = quota1 + quota2
          // const quota = stakeSrc / 400

          const regionTotalAS = Number(res5?.region?.regionTotalAS)

          const allowStakePower = regionTotalAS - +res5?.region_commission?.currentOccupyAS

          setAreaInfo((val: any) => {
            return {
              ...val,
              ...res5.region,
              // ...res7.region,
              areaSrc,
              areaSrg,
              areaShowSrc,
              areaShowSrg,
              stakeSrc: stakeSrc || 0,
              areaTotalPledge: Number(totalStakingBalance),
              allowStakePower: Number(allowStakePower),
              totalStakePower: Number(regionTotalAS),
              maxRate: `${round(max * 100, 0)}%`,
            }
          })
        } catch (error) {
          setTimeout(() => initData(ms * 2), ms * 2)
          console.error('MaxRate error: ', error)
        }
      }
      // console.log('ac:::', ac)
      // console.log('ag:::', ag)

      setData((val: any) => {
        return {
          ...val,
          ac,
          ag,
          showAc,
          showAg,
        }
      })
    } catch (error) {
      console.error('InitData Error: ', error)
    }
  }

  // 获取账户未过期 的定期列表
  const getFixed = async () => {
    // console.log('address:::', address)
    const res: any = await fixedDepositByAcct(address, 1)
    // console.log('getFixed:::', res)
    setFixedList(res.FixedDeposit || [])
  }

  // 获取区域未过期和所有 的定期列表
  const getFixedRegion = async () => {
    if (!areaInfo?.regionId) {
      return
    }
    // console.log('getFixedRegion areaInfo.regionId:::', areaInfo.regionId)
    // const [res, res2]: any = await Promise.all([fixedDepositByRegion(areaInfo.regionId, 1), fixedDepositByRegion(areaInfo.regionId, 0)])
    const res: any = await fixedDepositByRegion(areaInfo.regionId)
    // console.log('getFixedRegion:::', res)

    let fixedInterestRegion = 0
    res.FixedDeposit
      .filter((item: any) => item?.state === 'NOT_EXPIRED')
      .forEach((item: any) => {
        fixedInterestRegion += Number(item.interest)
      })
    let fixedTotal = 0
    res.FixedDeposit
      .filter((item: any) => item?.state === 'ALL_STATE')
      .forEach((item: any) => {
        fixedTotal += Number(item.amount)
      })

    // console.log('res000011111111::::::', fixedInterestRegion)
    setFixedRegionList(res.FixedDeposit || [])
    setAreaInfo((val: any) => {
      return {
        ...val,
        fixedInterestRegion: Number(u2unit(fixedInterestRegion)),
        fixedTotal: u2unit(fixedTotal) || 0,
      }
    })
  }

  // 获取赠送质押src的活期收益
  const getKycBonus = async () => {
    let res: any = {
      src: 0,
      srg: 0,
    }
    try {
      res = await kycBonus(address)
    } catch (error) {
      console.error('getKycBonus error', error)
    }

    // console.log('getKycBonus::', res)
    setData((val: any) => {
      return {
        ...val,
        kycBonus: {
          src: res?.delegation?.interestAmount < 1 ? 0 : u2unit(res?.delegation?.interestAmount),
          srg: res?.delegation?.interestAmount < 1 ? 0 : u2unit(res?.delegation?.interestAmount),
        },
      }
    })
  }

  // 获取质押相关信息
  const getDelegationAndFixed = async (ms = 500) => {
    const [ delegationResult, allStateFixedDepositResult, notExpiredFixedDepositResult, expiredFixedDepositResult ]: any = await Promise.allSettled([ delegationAmount(address), fixedDepositByAcct(address, 0), fixedDepositByAcct(address, 1), fixedDepositByAcct(address, 2) ])

    if (delegationResult.status === 'rejected' ||
      allStateFixedDepositResult.status === 'rejected' ||
      notExpiredFixedDepositResult.status === 'rejected' ||
      expiredFixedDepositResult.status === 'rejected'
    ) {
      setTimeout(() => getDelegationAndFixed(ms * 2), ms * 2)
      return
    }

    const delegation = delegationResult?.value?.delegation || {}
    const allStateFixedDeposit = allStateFixedDepositResult?.value?.FixedDeposit || []
    const notExpiredFixedDeposit = notExpiredFixedDepositResult?.value?.FixedDeposit || []
    const expiredFixedDeposit = expiredFixedDepositResult?.value?.FixedDeposit || []

    setFixedList(notExpiredFixedDeposit)

    let fixedInterest = 0
    expiredFixedDeposit.forEach((item: any) => {
      fixedInterest += Number(item.interest)
    })
    setFixedExpiredList(expiredFixedDeposit)

    const fixedBalance = allStateFixedDeposit.reduce((value: number, item: any) => value + +item.amount, 0) || 0

    setData((val: any) => {
      const totalStaked = multiAdd(fixedBalance, Number(delegation?.amountAC || 0), Number(delegation?.unmovableAmount || 0))
      return {
        ...val,
        fixedInterest: Number(u2unit(fixedInterest)),
        totalFixed: Number(fixedBalance || 0),
        showTotalFixed: Number(fixedBalance || 0),
        flexibleBalance: Number(delegation?.amountAC),
        showFlexibleBalance: Number(delegation?.amountAC),
        totalStaked,
        showTotalStaked: Number(u2unit(totalStaked)),
        power: (Number(delegation?.amountAC || 0) + Number(delegation?.unmovableAmount || 0)) / 400 / 1e6,
      }
    })
  }

  // 获取用户的活期质押信息
  const getDelegation = async () => {
    const res = await delegationByAddress(address)
    setData((val: any) => {
      // const totalStaked = Number(res.fixedBalance) + Number(res.flexibleBalance) + Number(res.kycBalance)
      // status === STATUS_DELEGATE_NOCHANGE 表示当前没有进行中的操作，可以进行质押、赎回等操作
      return {
        ...val,
        stakedStatus: res.delegation.status,
        canStake: res.delegation.status === 'STATUS_DELEGATE_NOCHANGE',
      }
    })
  }

  const checkStakeStatus = () => {
    if (data.stakedStatus && data.stakedStatus !== 'STATUS_DELEGATE_NOCHANGE') {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current)
      }
      toastIdRef.current = toast({
        title: getStatusMsg(data.stakedStatus),
        position: 'bottom',
        status: 'info',
        duration: 4000,
        isClosable: true,
      })
      return false
    }
    return true
  }

  const onStake = () => {
    if (checkStakeStatus()) {
      onOpen4()
    }
  }

  const onMange = () => {
    // if (checkStakeStatus()) {
    onOpen3()
    // }
  }

  let isInit = false

  const create = () => {
    if (!address) {
      setData({...defData})
      setAreaInfo({...defArea})
      return
    }

    if (isInit === true) {
      return
    }

    isInit = true
    setTimeout(() => {
      isInit = false
    }, 1000)

    setUpdateNum((val: any) => {
      return val + 1
    })

    getDelegation()
    // getFixed()
    // getFixedInterest()
    getKycBonus()
    // getDelegationAmount()
    setTimeout(getDelegationAndFixed, 250)
    setTimeout(initData, 250)
    setTimeout(getFixedRegion, 250)
  }

  useEffect(create, [ address ])

  useEffect(() => {
    getFixedRegion()
  }, [ areaInfo.regionId ])

  useEffect(() => {
    setData((val: any) => {
      return {
        ...val,
        total: add(+u2unit((val.totalStaked || 0)), (val.ac || 0)),
      }
    })
  }, [ data.totalStaked, data.ac ])

  // 提取定期收益
  const extractFixed = async () => {
    setData((val: any) => {
      return {
        ...val,
        extractFixedLoading: true,
      }
    })
    const list: any[] = []
    let isSuccess = false
    // let currentIndex = 0
    // console.log('extractFixed11111111::::', fixedExpiredList)
    // return

    const send = async (index: number) => {
      // console.log('index::::::', index)
      if (index <= fixedExpiredList.length - 1) {
        try {
          const res = await window.srs.sendTx({
            msgType: 'msgDoFixedWithdraw',
            id: Number(fixedExpiredList[index].id),
            feeAmount: BASE_FEE,
            gas: GAS,
            memo: '',
          })
          isSuccess = true
          list.push(res)
          send(index + 1)
        } catch (error) {
          console.error(`extractFixed ${index} error: `, error)
        }
      } else {
        // console.log('list::::', list)
        setData((val: any) => {
          return {
            ...val,
            extractFixedLoading: false,
          }
        })
        if (isSuccess) {
          setTimeout(() => {
            create()
          }, 200)
          toast({
            title: 'Success',
            position: 'bottom',
            status: 'success',
            duration: 4000,
            isClosable: true,
          })
        } else {
          toast({
            title: 'Failed',
            description: list[0]?.rawLog || '',
            position: 'bottom',
            status: 'error',
            duration: 4000,
            isClosable: true,
          })
        }
      }
    }

    send(0)
  }

  // 提取活期收益
  const extractCurren = async () => {
    setData((val: any) => {
      return {
        ...val,
        extractCurrenLoading: true,
      }
    })
    try {
      const res = await window.srs.sendTx({msgType: 'msgWithdraw', feeAmount: BASE_FEE, gas: GAS, memo: ''})
      // console.log('extractCurren res: ', res)

      toast({
        title: 'Success',
        position: 'bottom',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setData((val: any) => {
        return {
          ...val,
          extractCurrenLoading: false,
        }
      })
      setTimeout(() => {
        create()
      }, 200)
    } catch (error: any) {
      console.error('extractCurren failed: ', error)
      toast({
        title: 'Failed',
        description: error?.rawLog || '',
        position: 'bottom',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      setData((val: any) => {
        return {
          ...val,
          extractCurrenLoading: false,
        }
      })
    }
  }

  return (
    <div className={styles.container}>
      <PageTitle title={'GEA Wallet'} subTitle={'NETWORK'}></PageTitle>

      <Flex className={styles.head}>
        <Box w="100px">
          <Avatar size="45" src="./images/earth.webp" mr="6px">
            {/* <AvatarBadge borderColor="papayawhip" bg="green" boxSize="23px" fontSize="14px"></AvatarBadge> */}
          </Avatar>
        </Box>
        <Flex wrap="wrap" grow="1" flexDirection="column">
          <Box className={styles.name}>{username || '--'}</Box>
          <Box className={[ styles.val, styles.highlight ].join(' ')}>{address || '--'}</Box>
          <Box className={styles.val}>Power：{data.power} AS</Box>
        </Flex>
      </Flex>

      <Flex className={styles.boxWrap} wrap="wrap">
        <Flex className={styles.box2} direction="column">
          {/* <Box className={styles.t1}>Coin base</Box> */}
          <Box className={styles.t1} mt="25px">
            Total Balance Amount
          </Box>
          <Box className={styles.t2} mt="20px">
            <Text>
              {data.total} <span className={styles.highlight}>GEAC</span>
            </Text>
            <Text mt="2px">
              {data.showAg} <span className={styles.highlight}>GEAG</span>
            </Text>
          </Box>
          {/* <Box className={styles.t3}>Maximum APR：200%</Box> */}
          <Flex className={styles.t4} justifyContent="space-between">
            <Box></Box>
            <Box>
              <Button variant="solid" mr="17px" onClick={onOpen} isDisabled={status !== 'Connected'}>
                Send
              </Button>
              {/* 二维码 */}
              <Button variant="solid" mr="17px" onClick={onOpen6} isDisabled={status !== 'Connected'}>
                Deposit
              </Button>
              <Button variant="outline" onClick={onOpen2} isDisabled={status !== 'Connected'}>
                GEAG Exchange GEAC
              </Button>
            </Box>
          </Flex>
        </Flex>

        <Flex className={styles.box1} wrap="wrap" ml="25px">
          <Box alignItems="center" flexWrap="wrap">
            <Box className={styles.t1} mt="18px">
              Total staked Amount
            </Box>
            <Box className={styles.t2} mt="18px">
              {data.showTotalStaked} <span className={[ styles.highlight ].join(' ')}>GEAC</span>
            </Box>
            {/* <Box className={styles.t3}>$60</Box> */}
          </Box>
          <Box alignItems="center" flexWrap="wrap" ml="25px">
            <Box className={styles.t1} mt="18px">
              Available Balance
            </Box>
            <Box className={styles.t2} mt="18px">
              <Text>
                {data.showAc} <span className={styles.highlight}>GEAC</span>
              </Text>
              <Text mt="2px">
                {data.showAg} <span className={styles.highlight}>GEAG</span>
              </Text>
            </Box>
            {/* <Box className={styles.t3}>$60</Box> */}
          </Box>

          <Box flexWrap="wrap" mt="25px">
            <Box className={styles.t1}>Staked Claimable Rewards</Box>

            <Box className={styles.t2} w="100%">
              <Text>
                {data.fixedInterest} <span className={styles.highlight}>GEAG</span>
              </Text>
              <Text>{/* 200000 <span className={styles.highlight}>GEAG</span> */}</Text>
            </Box>

            <Flex direction="row-reverse" w="100%" mt="24px">
              <Button
                size="sm"
                minW="68px"
                onClick={extractFixed}
                isLoading={data.extractFixedLoading}
                isDisabled={isKyc && data.fixedInterest !== '0' ? false : true}
                mb="-4px"
              >
                Get
              </Button>
            </Flex>
          </Box>

          <Box flexWrap="wrap" mt="25px" ml="25px">
            <Box className={styles.t1}>Claimable Rewards</Box>

            <Box className={styles.t2} w="100%">
              <Text>
                {data.kycBonus?.src} <span className={styles.highlight}>GEAC</span>
              </Text>
              <Text>
                {data.kycBonus?.srg} <span className={styles.highlight}>GEAG</span>
              </Text>
            </Box>

            <Flex direction="row-reverse" w="100%">
              <Button
                size="sm"
                minW="68px"
                onClick={extractCurren}
                isLoading={data.extractCurrenLoading}
                isDisabled={isKyc && (data.kycBonus?.src !== '0' || data.kycBonus?.srg !== '0') ? false : true}
                mb="-4px"
              >
                Get
              </Button>
            </Flex>
          </Box>
        </Flex>

        {isKyc && <Flex className={styles.box3} flexWrap="wrap">
          <Box w="100%" fontSize="20px">
            {/* 区的所有power */}
            Area id: {areaInfo.regionId} <span style={{marginLeft: '20px'}}>{areaInfo.totalStakePower} AS</span>
          </Box>
          <Box w="50%" mt="10px" pl="0px">
            <Text fontSize="16px">Treasury Balance</Text>
            {/* 区可用资产 */}
            <Text className={[ styles.val ].join(' ')}>
              {areaInfo.areaShowSrc} <span className={styles.highlight}>GEAC</span>{' '}
            </Text>
            <Text className={[ styles.val ].join(' ')}>
              {areaInfo.areaShowSrg} <span className={styles.highlight}>GEAG</span>
            </Text>
          </Box>

          <Flex alignItems="center" justifyContent="space-between" w="50%" mt="10px" pl="150px">
            <Box>
              <Text fontSize="16px">Allow Stake Power</Text>
              {/* 区的可质押power */}
              <Text className={[ styles.val ].join(' ')}>
                {areaInfo.allowStakePower} <span className={styles.highlight}>AS</span>
              </Text>
            </Box>
            <Button onClick={onStake} mt="6px" h="34px" minW="100px">
              Stake
            </Button>
          </Flex>

          <Flex alignItems="center" justifyContent="space-between" w="50%" mt="15px" pl="0px">
            <Box>
              <Text fontSize="16px">Total Staking Balance</Text>
              {/* 区的活期和定期质押总额 */}
              <Text className={[ styles.val ].join(' ')}>
                {areaInfo.areaTotalPledge || 0} <span className={styles.highlight}>GEAC</span>
              </Text>
            </Box>

            <Box w="40%" mt="10px" pl="0px">
              <Text>Max APR</Text>
              <Text className={styles.val}>{areaInfo.maxRate}</Text>
            </Box>
          </Flex>

          <Flex alignItems="center" justifyContent="space-between" w="50%" mt="15px" pl="150px">
            <Box>
              <Text fontSize="16px">Total Treasury Lock</Text>
              <Text className={[ styles.val ].join(' ')}>
                {areaInfo.fixedInterestRegion || 0} <span className={styles.highlight}>GEAG</span>
              </Text>
            </Box>

            <Button onClick={onMange} h="34px" minW="100px">
              Mange
            </Button>
          </Flex>

          {/* <Box w="50%" mt="10px" pl="0px">
            <Text>Max APR</Text>
            <Text className={styles.val}>{areaInfo.maxRate}</Text>
          </Box> */}

          {fixedList.length ? (
            <>
              <Box w="100%">
                <FixedDepositTable showOperation={false} list={fixedList}></FixedDepositTable>
              </Box>
              <Flex mt="20px" textAlign="right" alignContent="flex-end" flexWrap="nowrap">
                <Button onClick={onOpen5}>Redeem</Button>
              </Flex>
            </>
          ) : (
            ''
          )}
        </Flex>}
      </Flex>

      <TransactionTable updateNum={updateNum}></TransactionTable>
      <Send onOpen={onOpen} onClose={onClose} isOpen={isOpen} data={data} sendCallback={create}></Send>
      <Exchange
        onOpen={onOpen2} onClose={onClose2} isOpen={isOpen2} areaInfo={areaInfo} data={data}
        sendCallback={create}></Exchange>
      <Mange
        onOpen={onOpen3} onClose={onClose3} isOpen={isOpen3} areaInfo={areaInfo} data={data}
        sendCallback={create}></Mange>
      <Stake
        onOpen={onOpen4} onClose={onClose4} isOpen={isOpen4} areaInfo={areaInfo} data={data}
        sendCallback={create}></Stake>
      <Unbonding
        onOpen={onOpen5} onClose={onClose5} isOpen={isOpen5} areaInfo={areaInfo} list={fixedList}
        sendCallback={create}></Unbonding>
      <Qrcode onOpen={onOpen6} onClose={onClose6} isOpen={isOpen6}></Qrcode>
    </div>
  )
}
