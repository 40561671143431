import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from '../style.module.scss'
import {setFloat} from "../../../utility/tools";
import { u2unit } from 'common'
export default function MsgUndelegate(props: any) {
  const render: any = (data = props.message) => {
    if (Object.prototype.toString.call(data) === '[object Object]') {
      return Object.keys(data).map((item) => {
        if (typeof data[item] === 'object') {
          return render(data[item])
        } else if (item === '@type') {
          return ''
        }
        if (item === 'delegatorAddress') {
          return (
            <div className={styles.line} key={item}>
              <div className={styles.key}>{item}</div>
              <div className={styles.val}>{data['creator']}</div>
            </div>
          )
        }
        if (item === 'denom') {
          const denom = (data[item]?.split?.('')?.[0] || '').toUpperCase() === 'U' ? data[item]?.slice(1, data[item].length).toUpperCase() : data[item]

          return (
              <div className={styles.line} key={item}>
                <div className={styles.key}>{item}</div>
                <div className={styles.val} style={{color: '#08ce9e'}}>{denom}</div>
              </div>
          )
        }

        if (item === 'amount' && data.denom && (data.denom?.split?.('')?.[0] || '').toUpperCase() === 'U') {

          return (
              <div className={styles.line} key={item}>
                <div className={styles.key}>{item}</div>
                <div className={styles.val}>{u2unit(data[item])}</div>
              </div>
          )
        }

        return (
          <div className={styles.line} key={item}>
            <div className={styles.key}>{item}</div>
            <div className={styles.val}>{`${data[item]}`}</div>
          </div>
        )
      })
    } else if (Object.prototype.toString.call(data) === '[object Array]') {
      return data.map((item: any) => {
        if (typeof item === 'object') {
          return render(item)
        }
        return { item }
      })
    } else if (typeof data === 'string') {
      return <div className={styles.val}>{data}</div>
    } else {
      return ''
    }
  }
  return <>{render()}</>

  // return (
  //   <>
  //     <div className={styles.line}>
  //       <div className={styles.key}>Creator</div>
  //       <div className={styles.val}>{props.message?.creator}</div>
  //     </div>
  //     <div className={styles.line}>
  //       <div className={styles.key}>Delegator Address</div>
  //       <div className={styles.val}>{props.message?.delegatorAddress}</div>
  //     </div>
  //     {Object.keys(props.message?.amount).map((item) => {
  //       return (
  //         <div className={styles.line} key={item}>
  //           <div className={styles.key}>{item}</div>
  //           <div className={styles.val}>{props.message?.amount[item]}</div>
  //         </div>
  //       )
  //     })}
  //   </>
  // )
}
