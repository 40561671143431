import React, { useEffect, useState } from 'react'
import {
  Flex,
  Text,
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  InputGroup,
  InputRightElement,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@chakra-ui/react'
import { useChain } from '@cosmos-kit/react'
import { getMaximum } from '../../utility/tools'
import { BASE_FEE, GAS, calculateFee } from 'common'

export default function Wallet({ onClose, onOpen, isOpen, data, sendCallback }: any) {
  const toast = useToast()
  const { isOpen:alertIsOpen, onOpen: alertOnOpen, onClose: alertOnClose } = useDisclosure()
  const cancelRef: any = React.useRef()
  const [memo, setMemo] = useState<string>('')
  const [sendLoading, setSendLoading] = useState<boolean>(false)
  // const [fee, setFee] = useState<any>()
  const [toAddress, setToAddress] = useState<string>('')
  const [amount, setAmount] = useState<any>('')
  const [fee, setFee] = useState<any>({
    feeAmount: BASE_FEE,
    gas: GAS,
  })
  const walletManager = useChain('srs')

  const { username, address } = walletManager

  const handleSend = async () => {
    alertOnClose()
    // console.log('发起活期存款')
    if (Number.isNaN(Number(amount))) {
      toast({
        title: 'Amount must be a number',
        position: 'bottom',
        status: 'info',
        duration: 4000,
        isClosable: true,
      })
      return
    }

    setSendLoading(true)
    try {
      // console.log('msgSend:', { msgType: 'msgSend', toAddress, amount, feeAmount: fee.feeAmount, gas: fee.gas, memo: memo })
      const res = await window.srs.sendTx({ msgType: 'msgSend', toAddress, amount, feeAmount: fee.feeAmount, gas: fee.gas, memo: memo })
      toast({
        title: 'Send success',
        position: 'bottom',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      sendCallback && sendCallback()
      setSendLoading(false)
      closeModal()
    } catch (error: any) {
      setSendLoading(false)
      console.error('Send failed: ', error)
      // gas不够, gas加倍, 再次发出交易
      if (error?.code === 11 && error?.log?.includes('out of gas')) {

        // 打开弹窗, 让用户确认
        alertOnOpen()
        return
      }
      toast({
        title: 'Send failed',
        description: error?.rawLog || error?.log || '',
        position: 'bottom',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  // 重新发送转账请求
  const reSend = () => {
    // gas加倍
    setFee((val: any) => {
      val.gas = val.gas * 1.5
      val.feeAmount = val.feeAmount * 1.5
      return val
    })

    // 重发交易
    handleSend()
  }

  const closeModal = async () => {
    setAmount('')
    onClose()
    alertOnClose()
  }

  useEffect(() => {
    setFee(() => {
      // if (Number.isNaN(Number(amount))) return
      // const amountFee = round(amount * 0.0001, 6)
      // const feeAmount = amount ? baseFee + (amountFee > 20000 ? amountFee : 20000) : 0

      const feeAmount = calculateFee(amount)
      return {
        ...fee,
        feeAmount,
      }
    })
  }, [amount])

  return (
    <>
    <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom" size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mt="0px">
            <Box>
              <FormLabel fontSize="16px">Form</FormLabel>
              <Box bg="blackAlpha.100" borderRadius="8px" border="1px solid rgba(0, 0, 0, 0.08)" padding="16px">
                <Text>Username: {username}</Text>
                <Text>Address: {address}</Text>
                <Text>
                  Available Balance: <span style={{fontWeight: 'bold'}}>{data.showAc}</span> GEAC
                </Text>
              </Box>
            </Box>
            <Box mt="18px">
              <FormLabel fontSize="16px">To</FormLabel>
              <Input bgColor="#FFF" placeholder="" onChange={(e) => setToAddress(e.target.value.trim())} autoComplete="off" />
            </Box>
            <Box mt="20px">
              <FormLabel fontSize="16px">Amount</FormLabel>
              <Flex wrap="wrap" justifyContent="space-between" alignItems="center">
                <InputGroup>
                  <Input placeholder="" value={amount} onChange={(e) => setAmount(e.target.value.trim())} size="lg" autoComplete="off" />
                  <InputRightElement pr="26px">
                    <Button
                      h="33px"
                      mt="6px"
                      size="md"
                      minW="50px"
                      colorScheme="gray"
                      fontWeight="normal"
                      onClick={() => setAmount(getMaximum(data.ac))}
                    >
                      Max
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {/* <Box fontSize="20px" color="#08CE9E">
                    GEAC
                  </Box> */}
              </Flex>
              <Box fontSize="16px" color="#B1B6BB" mt="4px">
                fees: {fee.feeAmount} GEAC
              </Box>
            </Box>

            <Box w="100%" mt="18px">
              <FormLabel fontSize="16px">Memo</FormLabel>
              <Textarea w="100%" h="80px" bgColor="#FFF" placeholder="" onChange={(e) => setMemo(e.target.value.trim())} />
            </Box>

            <Button mt="20px" minW="88px" onClick={handleSend} isLoading={sendLoading} isDisabled={!amount || !toAddress} float="right" mb="20px">
              Send
            </Button>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
    <AlertDialog motionPreset='slideInBottom'
                 leastDestructiveRef={cancelRef}
                 onClose={alertOnClose}
                 isOpen={alertIsOpen}
                 isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Tips</AlertDialogHeader>
        <AlertDialogBody>
            Insufficient Gas fees! It needs to be calculated again
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme='gray' variant='outline' ref={cancelRef} onClick={alertOnClose}>
              Cancel
            </Button>
            <Button onClick={reSend} isLoading={sendLoading} ml={3}>
              Confirm
            </Button>
          </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
      </>
  )
}
