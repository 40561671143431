import React, { useEffect, useState } from 'react'
import {
  Flex,
  Text,
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { round } from 'lodash-es'
import { BASE_FEE, GAS } from 'common'

export default function Wallet({ onClose, onOpen, isOpen, areaInfo, data, sendCallback }: any) {
  const toast = useToast()
  const [amount, setAmount] = useState<any>('')
  const [sendLoading, setSendLoading] = useState<boolean>(false)
  const [targetSrc, setTargetSrc] = useState<any>('')

  useEffect(() => {
    setTargetSrc(() => {
      return round(amount / 400, 2)
    })
  }, [amount])

  const handleMax = (value: number | string) => {
    const finalVal = Math.floor(+value / 400) * 400
    if (+data.showAg < finalVal) {
      toast({
        title: `The amount cannot exceed ${data.showAg}`,
        position: 'bottom',
        status: 'warning',
        duration: 4000,
        isClosable: true,
      })
    } else if (finalVal < 400) {
      toast({
        title: `Amount cannot be less than 400`,
        position: 'bottom',
        status: 'warning',
        duration: 4000,
        isClosable: true,
      })
    }
    setAmount(finalVal)
  }

  const handleExchange = async () => {
    // console.log('发起兑换')
    if (Number.isNaN(Number(amount))) {
      toast({
        title: 'Please enter a number',
        position: 'bottom',
        status: 'info',
        duration: 4000,
        isClosable: true,
      })
      return
    }

    setSendLoading(true)
    try {
      const res = await window.srs.sendTx({ msgType: 'msgAgToAc', amount: amount, feeAmount: BASE_FEE, gas: GAS, memo: '' })
      // console.log('handleDeletage res: ', res)

      sendCallback && sendCallback()
      toast({
        title: 'Transaction success',
        position: 'bottom',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setSendLoading(false)
      closeModal()
    } catch (error: any) {
      setSendLoading(false)
      console.error('Transaction failed: ', error)
      const errorLog = error?.rawLog.substring(0, error?.rawLog.match(/\n/)?.index || error?.rawLog?.length)
      toast({
        title: 'Transaction failed',
        description: errorLog || error?.rawLog || '',
        position: 'bottom',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const closeModal = async () => {
    setAmount('')
    onClose()
  }

  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom" size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>GEAG Exchange GEAC</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mt="0px">
            <Box>
              {/* <FormLabel fontSize="16px">Form</FormLabel> */}
              <Box bg="blackAlpha.100" borderRadius="8px" border="1px solid rgba(0, 0, 0, 0.08)" padding="16px">
                <Text>
                  Total Balance: {data.total} GEAC <span style={{ padding: '0 10px' }}>|</span> {data.showAg} GEAG
                </Text>
                <Text mt="4px">Total Staked: {data.showTotalStaked} GEAC</Text>
                <Text mt="4px">
                  Available Balance: {data.showAc} GEAC <span style={{ padding: '0 10px' }}>|</span> {data.showAg} GEAG
                </Text>
              </Box>
            </Box>
            <Box mt="20px">
              <FormLabel fontSize="16px">Amount</FormLabel>
              <Flex justifyContent="space-between" alignItems="center">
                {/* <Input bgColor="#FFF" placeholder="" onChange={(e) => changeAmount(e.target.value)} /> */}

                <InputGroup>
                  <Input
                    placeholder=""
                    value={amount}
                    onChange={(e) => setAmount(e.target.value.trim())}
                    onBlur={(e) => handleMax(e.target.value.trim())}
                    maxLength={20}
                    size="lg"
                    autoComplete="off"
                  />
                  <InputRightElement pr="26px">
                    <Button h="33px" mt="7px" size="md" minW="50px" colorScheme="gray" fontWeight="normal" onClick={(e) => handleMax(data.ag)}>
                      Max
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Box fontSize="20px" color="#08CE9E" ml="14px">
                  GEAG
                </Box>
              </Flex>
              <Flex justifyContent="space-between" fontSize="14px" color="#B1B6BB" mt="4px">
                <Box>Exchange Ratio 1GEAC = 400 GEAG</Box>
                <Box>≈ {targetSrc} GEAC</Box>
              </Flex>
            </Box>

            <Button mt="20px" minW="88px" onClick={handleExchange} isLoading={sendLoading} isDisabled={!amount || amount < 400 || amount > +data.showAg} float="right" mb="20px">
              Send
            </Button>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
