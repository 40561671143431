import React, { useEffect, useState } from 'react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { Table, Thead, Tbody, Th, Tr, Td, TableContainer, Image, Flex, Center, Box, IconButton } from '@chakra-ui/react'
import MobileTable from '../../components/mobileTable';
import styles from './style.module.scss'
import { Loading } from '../../components'
import {
  dealType,
  howLongAgo,
} from '../../utility/tools'
import { cutText } from 'common'
import { transByPage } from '../../resources/api'
import { Link } from 'react-router-dom'
import { Pagination, Tooltip } from 'antd'
import dayjs from 'dayjs'
import {renderAmount} from "../transactionDetail/renderAmount";

export default function TransactionTable({ style = {} }: any) {
  const [data, setData] = useState<any[]>([])
  const [page, setPage] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 0,
    // totalPage: 0,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const columns = [
    { 
      title: 'Hash',
      dataIndex: 'hash',
      tdAttr: {
        pl:'18px'
      },
      render: (hash: string, item: any) => {
        return (
          <Flex alignItems="center">
            {item.success === true ? (
              <Image boxSize="17px" src="./images/su.png" mt="-2px" mr="13px"></Image>
            ) : (
              // <Image boxSize="17px" src="./images/su.png" mt="-2px" mr="13px"></Image>
              <Center style={{ background: '#E53E3E', borderRadius: '100%' }} mr="13px">
                <SmallCloseIcon color="#fff"></SmallCloseIcon>
              </Center>
            )}

            <Box className={styles.highlight}>
              <Link to={{ pathname: '/transactionDetail', search: `?hash=${hash}` }}>
                <Tooltip placement="top" title={hash} color="#444948">
                  <span className={styles.highlight}>{cutText(hash)}</span>
                </Tooltip>
                {/* {cutText(item.hash)} */}
              </Link>
            </Box>
          </Flex>
        )
      }
    },
    { 
      title: 'Type',
      render: (_: any, item: any) => {
        return (
          <Flex alignItems="center">
            <Image boxSize="17px" src={srcMap[dealType(item.messages[0]['@type'])] || srcMap['def']} mt="-1px" mr="6px"></Image>
            <Box>{dealType(item.messages[0]['@type'])}</Box>
          </Flex>
        )
      }
    },
    { 
      title: 'Amount',
      render: (_: any, item: any) => {
        const amount = item?.messages?.[0]?.amount || item?.messages?.[0]?.AgAmount
        return (
          <span>{renderAmount(amount, {color: '#08ce9e'})}</span>
        )
      }
    },
    { 
      title: 'Height',
      dataIndex: 'height',
      tdAttr: {
        className: styles.highlight
      },
      render: (height: string, item: any) => {
        return (
          <Link to={{ pathname: '/blockDetail', search: `?height=${height}` }}>{height}</Link>
        )
      }
    },
    {
      title: 'Time',
      dataIndex: 'timestamp',
      render: (timestamp: string, item: any) => {
        return (
          <Tooltip placement="top" title={dayjs(timestamp).format('MMM D, YYYY, h:mm A')} color="#444948">
            {howLongAgo(timestamp)}
          </Tooltip> 
        )
      }
    },
  ]
  const srcMap: any = {
    def: './images/type@2x1.png',
    'IBC Transfer': './images/type@2x1.png',
    'Withdraw Rewards': './images/type@2x5.png',
    Send: './images/type@2x2.png',
    Delegate: './images/type@2x3.png',
    Vot: './images/type@2x4.png',
    Undelegate: './images/type@2x4.png',
    NewKyc: './images/type@2x4.png',
  }

  const initData = async () => {
    changePage(1)
  }

  const changePage = async (_page: number, type = 'pc') => {
    setLoading(true)
    const res = await transByPage(_page)
    if (res?.code !== 200) {
      return
    }
    // console.log('list::', res.data)
    res.data.sort((item: any, item2: any) => {
      return item2.height - item.height
    })

    setPage((val: any) => {
      return {
        ...val,
        page: _page,
        total: res.amount,
      }
    })
    if(type === 'mobile' && _page !== 1){
      setData(data.concat(res.data))
    }else{
      setData(res.data)
    }
    setLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  return (
    <Box position="relative" minH="773px" style={style}>
      {loading ? <Loading></Loading> : ''}
      <div className='pc_table_container'>
        <TableContainer mt="20px">
          <Table variant="striped" colorScheme="blackAlpha" size="lg">
            <Thead>
              <Tr>
                {columns?.map((item: any, index: number) => {
                  if (index === 0) {
                    return (
                      <Th key="{item.title}" pl="50px">
                        {item.title}
                      </Th>
                    )
                  }

                  return <Th key={item.title}>{item.title}</Th>
                })}
              </Tr>
            </Thead>

            <Tbody>
              {data?.map((item: any) => {
                return (
                  <Tr key={item.hash} h="72px">
                    {
                      columns.map((td: any, index: number) => {
                        const _val = item[td.dataIndex]
                        return ( 
                          <Td key={`${item.hash}_td_${index}`} {...(td.tdAttr || {})}>
                            { td.render(_val, item) }
                          </Td>
                        )
                      })
                    }
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>

        {page.total > 10 && (
          <Flex justifyContent="flex-end" mt="15px">
            <Pagination
              total={page.total}
              showSizeChanger={false}
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
              onChange={(page, pagesize) => changePage(page)}
            />
          </Flex>
        )}
      </div>
      <MobileTable
        dataSource={data}
        columns={columns}
        multipleColumns={[`Amount`,`Height`,`Time`]}
        rowKey={(record: any) => record?.hash}
        pagination={{
          current: page.page,
          pageSize: page.pageSize,
          total: page.total,
          onChange: (page: number) => {
            changePage(page, 'mobile')
          },
        }}
      /> 
    </Box>
  )
}
