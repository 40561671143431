import React, {useEffect, useState} from 'react'
import {Flex, Box, Avatar} from '@chakra-ui/react'
import {PageTitle} from '../../components'
import styles from './style.module.scss'

import {useLocation} from 'react-router-dom'
import {qs} from 'url-parse'

import ProposedBlock from './proposedBlock'
import Uptime from './uptime'
import {
    getBalanceByAddr,
    getTakingRegionById,
    getTakingRegionByName,
    blockByRegionName,
    blockStateByRegionName,
} from '../../resources/api'
import { u2unit, DENOM } from 'common'
import {useRequest} from "ahooks";
import {setFloat} from "../../utility/tools";

export default function ValidatorDetail() {
    const {search} = useLocation()
    const searchs = qs.parse(search)
    const [blocks, setBlocks] = useState<any>([])
    const [amount, setAmount] = useState<any>(0)
    const [blockState, setBlockStates] = useState<any>([])


    const [regionInfo, setRegionInfo] = useState<any>({})
    const [currentDenom, setCurrentDenom] = useState<string>('');
    const [balances, setBalances] = useState<any[]>([])

    useRequest(() => getTakingRegionByName(searchs.regionName || searchs.name), {
        ready: !!(searchs.regionName || searchs.name) && !searchs.id,
        refreshDeps: [searchs.regionName || searchs.name],
        onSuccess: (res: any) => {
            setRegionInfo(() => ({...(res ?? {})}))
            if (res?.region?.baseAccountAddr) {
                getDepositBalancesByAddrAction.run(res.region.baseAccountAddr)
            }
        }
    })

    useRequest(() => getTakingRegionById(searchs.id), {
        ready: !!searchs.id,
        refreshDeps: [searchs.id],
        onSuccess: (res: any) => {
            const _denomMatchResult = res.region.userMinDelegateAC.match(/\d+([A-Z]+)/)
            const _denom = _denomMatchResult?.length > 0 ? _denomMatchResult[1] : DENOM
            setCurrentDenom(_denom)
            setRegionInfo(() => ({...(res ?? {})}))
            if (res?.region?.baseAccountAddr) {
                getDepositBalancesByAddrAction.run(res.region.baseAccountAddr)
            }
        }
    })

    // // 获取金库可用资产
    const getDepositBalancesByAddrAction = useRequest(getBalanceByAddr, {
        manual: true,
        onSuccess: (res: any) => {
            const {balances = []} = res
            balances.forEach((item: any) => {
                const firstC = item?.denom?.split?.('')?.[0] || ''
                if (firstC.toUpperCase() === 'U') {
                    item.amount = u2unit(item.amount)
                }
            })

            setBalances(() => [...balances])
        }
    })


    const tempObj: any = {
        loopId: null,
    }

    const getArea = async () => {
        const [r5, r6] = await Promise.all([blockByRegionName(searchs.regionName || searchs.name), blockStateByRegionName(searchs.regionName|| searchs.name)])
        setBlocks(r5.data || [])
        setAmount(r5.amount || 0)
        const dataArr: any = []
        if(r6.data){
          Object.keys(r6.data).forEach((t: any, i: number) => {
              dataArr.push({key: t, value: Object.values(r6.data)[i]})
          })
        }
        
        setBlockStates(dataArr || [])
    }

    useEffect(() => {
        getArea()
        tempObj.loopId = setInterval(() => {
            getArea()
        }, 5000)
        return () => {
            clearInterval(tempObj.loopId)
        }
    }, [])

    return (
        <div className={styles.container}>
            <PageTitle title={'AREA DETAILS'} subTitle={''}></PageTitle>

            <Flex className={styles.head} alignItems="center">
                <Box w="100px">
                    <Avatar size="45" src="./images/earth.webp" mr="6px">
                    </Avatar>
                </Box>
                <Box className={styles.name}>{regionInfo.region?.regionName || '--'}</Box>

            </Flex>

            <div className={styles.summary}>
                <div className={styles.line}>
                    <div className={styles.key}>RegionId</div>
                    <div className={[styles.val].join(' ')}>{regionInfo.region?.regionId ?? '--'}</div>
                </div>
                <div className={styles.line}>
                    <div className={styles.key}>Commission Power Limit</div>
                    <div
                        className={[styles.val, styles.highlight].join(' ')}>{regionInfo.region?.regionTotalAS ? setFloat(regionInfo.region?.regionTotalAS) : '--'}
                        <span style={{color: '#08CE9E'}}>&nbsp;ANS</span></div>
                </div>
                <div className={styles.line}>
                    <div className={styles.key}>Bond Denom Limit</div>
                    <div
                        className={[styles.val, styles.highlight].join(' ')}>{u2unit(regionInfo.region?.regionTotalUAC) ?? '--'}
                        <span style={{color: '#08CE9E'}}>&nbsp;{currentDenom}</span></div>
                </div>
                <div className={styles.line}>
                    <div className={styles.key}>Commission Power Rate</div>
                    <div
                        className={[styles.val, styles.highlight].join(' ')}>{regionInfo.region?.regionAsRate ? regionInfo.region?.regionAsRate * 100 : 0}%
                    </div>
                </div>
                <div className={styles.line}>
                    <div className={styles.key}>Region KYC Stake Up Quota</div>
                    <div
                        className={[styles.val].join(' ')}>{setFloat(regionInfo.region?.regionTotalStakeAllow) ?? '--'}</div>
                </div>
                <div className={styles.line}>
                    <div className={styles.key}>Voting Power</div>
                    <div className={styles.val}>
                        {Number(regionInfo.region_commission?.currentOccupyAS || 0)}
                        <span style={{color: '#08CE9E'}}>&nbsp;ANS</span>
                        / {Number(regionInfo.region_commission?.regionTotalAS || 0)}
                        <span style={{color: '#08CE9E'}}>&nbsp;ANS</span>
                    </div>
                </div>
                <div className={styles.line}>
                    <div className={styles.key}>Available Balance</div>
                    <div className={styles.val}>{(balances.find((item: any) => item.denom === 'ugag') ?? {}).amount || 0}
                        <span style={{color: '#08CE9E'}}>&nbsp;GAG</span></div>
                </div>
                <div className={styles.line}>
                    <div className={styles.key}>Total Staked</div>
                    <div className={styles.val}>
                        {
                            u2unit(regionInfo?.region_commission?.currentDemandTotalUAC)  || 0
                        }
                        <span style={{color: '#08CE9E'}}>&nbsp;{currentDenom}</span>
                    </div>
                </div>
                <div className={styles.line}>
                    <div className={styles.key}>Treasury Balance</div>
                    <div className={styles.val}>{(balances.find((item: any) => item.denom === 'ugea') ?? {}).amount || 0}
                        <span style={{color: '#08CE9E'}}>&nbsp;GEA</span></div>
                </div>
            </div>

            <Flex justifyContent="space-between" wrap="wrap">
                <ProposedBlock list={blocks} amount={amount}></ProposedBlock>
                <Uptime list={blockState}></Uptime>
            </Flex>
        </div>
    )
}
