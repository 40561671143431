import { Box, Spinner } from '@chakra-ui/react'
import React from 'react'
import styles from './styles.module.scss'

type cprops = {}

export default function Loading(props: cprops) {
  return (
    <Box className={styles.loading}>
      <Spinner speed="0.8s" color="green.600" />
    </Box>
  )
}
