import { multiply, add } from '../utils'
import { BASE_FEE, MIN_FEE, RATE } from './config'

// 计算交易费
export const calculateFee = (amount: number) => {
  amount = +amount
  if (!amount || Number.isNaN(amount)) {
    return 0
  }

  let fees = multiply(amount, RATE)
  fees = fees > MIN_FEE ? fees : MIN_FEE

  return add(BASE_FEE, fees)
}
