import React from 'react'
import styles from '../style.module.scss'
import {setFloat} from "../../../utility/tools";

export default function MsgCreateRegion(props: any) {
  return (
    <>
      <div className={styles.line}>
        <div className={styles.key}>Creator</div>
        <div className={styles.val}>{props.message?.creator}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>Region Name</div>
        <div className={styles.val}>{props.message?.regionName}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>RegionId</div>
        <div className={styles.val}>{props.message?.regionId}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>Commission Power Limit</div>
        <div className={styles.val}>{setFloat(props.message?.regionTotalStakeAllow)}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>Delegators Limit</div>
        <div className={styles.val}>{props.message?.delegatorsLimit}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>Region Fee Rate</div>
        <div className={styles.val}>{setFloat(props.message?.regionFeeRate)}</div>
      </div>
    </>
  )
}
