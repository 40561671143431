import ajax from './axios'
import { BACKEND_BASE_URL } from './constants'
import { FIXED_DEPOSIT_QUERY_TYPE, FIXED_DEPOSIT_QUERY_TYPE_INTERFACE } from 'common'

const checkUrl = (str)=>{
  // 处理/重复
  // return str.replace(/(?<!:)\/\//gm,'/')
  let res = str.replace(/(?<!:)\/\//gm,'/')
    // 全使用gea-poa
    // .replace(/geapoa/gm,(_v)=>{
    //   return 'gea-poa'
    // })
  if(/localhost/.test(location.href) && /gea-?poa/.test(res)){
    res = res.replace('/52.199.174.6/geapoa','/geapoa')
    res = res.replace('/118.175.0.242/geapoa','/geapoa')
    res = res.replace('/52.199.174.6/gea-poa','/gea-poa')
    res = res.replace('/118.175.0.242/gea-poa','/gea-poa')
  }
  return res
}
export const getBlocksByLatest = (config?: any): Promise<any> => {
  return ajax.get('/cosmos/base/tendermint/v1beta1/blocks/latest', config)
}

export const getBlocksByHeight = (height: any): Promise<any> => {
  return ajax.get(`/cosmos/base/tendermint/v1beta1/blocks/${height}`)
}

export const getTxsByHash = (hash: any): Promise<any> => {
  return ajax.get(`/cosmos/tx/v1beta1/txs/${hash}`)
}

export const getAllTx = (): Promise<any> => {
  // return ajax.get(`${BACKEND_BASE_URL}/geapoa/transactions`, {})
  return ajax.get(checkUrl(`${BACKEND_BASE_URL}/geapoa/transactions`), {})
}

export const getValidatorByLatest = (): Promise<any> => {
  return ajax.get(`/cosmos/base/tendermint/v1beta1/validatorsets/latest`)
}

export const getValidatorByHeight = (height: any): Promise<any> => {
  return ajax.get(`/cosmos/base/tendermint/v1beta1/validatorsets/${height}`)
}

// 获取所有的区域
export const getTakingRegion = (): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/list-region`)
}

export const getTakingRegionById = (id: any): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/region/${id}`)
}
export const getTakingRegionByName = (name: any): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/region_by_name/${name}`)
}

export const getRegionVaultById = (regionId: any): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/region/${regionId}`)
}

export const getBalanceByAddr = (address: any): Promise<any> => {
  return ajax.get(`/cosmos/bank/v1beta1/balances/${address}`)
}

export const getAccountByAddr = (address: any): Promise<any> => {
  return ajax.get(`/cosmos/auth/v1beta1/accounts/${address}`)
}

export const getAccount = (): Promise<any> => {
  return ajax.get(`/cosmos/auth/v1beta1/accounts`)
}

export const delegation = (): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/list-delegation`)
}

export const getKyc = (address: string): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/kyc/${address}`)
}

export const delegationByAddress = (address?: string): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/delegation/${address}`)
}

// 获取账户相关交易信息
export const messageByAccount = (data: any): Promise<any> => {
  return ajax.post(checkUrl(`${BACKEND_BASE_URL}/geapoa/transaction/messageByAccount`), data)
}

export const getFixedDelegation = (address: any): Promise<any> => {
  return ajax.get(checkUrl(`/gea-poa/srstaking/fixed_delegation/${address}`))
}

export const getTransByHash = (data: any): Promise<any> => {
  return ajax.post(checkUrl(`${BACKEND_BASE_URL}/geapoa/transaction/transByHash`), data)
}

// export const getFixedDeposit = (address: any, query_type = 0): Promise<any> => {
//   return ajax.get(`/cosmos/srstaking/v1beta1/fixed_deposit_by_acct/${address}`, { data: { query_type } })
// }

// 查询交易总量
export const messageTotal = (): Promise<any> => {
  return ajax.get(checkUrl(`${BACKEND_BASE_URL}/geapoa/transaction/amount`))
}

// 根据页码查询交易
export const transByPage = (page: any): Promise<any> => {
  return ajax.post(checkUrl(`${BACKEND_BASE_URL}/geapoa/transaction/transByPage`), { page_number: page })
}

// 根据账户查定期
export const fixedDepositByAcct = (address: any, query_type:(keyof FIXED_DEPOSIT_QUERY_TYPE_INTERFACE) = 0): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/fixed_deposit_by_acct/${address}/${FIXED_DEPOSIT_QUERY_TYPE[query_type]}`)
}

// 根据区域查定期
export const fixedDepositByRegion = (regionid: any, query_type = 0): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/fixed_deposit_by_region/${regionid}`, { params: { query_type } })
}

// 赠送SRC的活期可提现收益
export const kycBonus = (address: any): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/delegation/${address}`)
}

// 获取质押的值
export const delegationAmount = (address: any): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/delegation/${address}`)
}

// 获取用户活期质押
/*export const showDelegate = (address: any): Promise<any> => {
  return ajax.get(`/srs-poa/srstaking/show_delegate/${address}`)
}*/

export const getValidatorList = (): Promise<any> => {
  // { params: { page_number: page } }
  return ajax.get(`/cosmos/srstaking/v1beta1/list-validator`)
}
// 获取根据地址获取区信息
export const validator = (address: any): Promise<any> => {
  return ajax.get(`/cosmos/srstaking/v1beta1/validator/${address}`)
}

// // 获取账户相关交易信息
// export const messageByAccount = (address: any): Promise<any> => {
//   return ajax.get(`//118.175.0.230:8080/srspoa/transaction/messageByAccount${address}`)
// }
// 区域 - 获取区块
export const blockByRegionName = (region_name: any): Promise<any> => {
  return ajax.post(checkUrl(`${BACKEND_BASE_URL}/geapoa/block/blockByRegionName`), { region_name })
}
// 区域 - 获取节点-获取100个状态
export const blockStateByRegionName = (region_name: any): Promise<any> => {
  return ajax.post(checkUrl(`${BACKEND_BASE_URL}/geapoa/block/blockStateByRegionName`), { region_name })
}
