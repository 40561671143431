import { getDecimalNum, u2unit } from './u2unit'
import { isObject, isArray, isString, isNumber } from '../utils'

export const getSrcAmountAndDenom = (data: any): {
  amount: number | string,
  denom: string
} => {
  let val: number | string = 0
  let unit: string = ''
  if (isArray(data)) {
    data = data[0]
  }
  if (isObject(data)) {
    const {denom, amount, AgAmount} = data
    unit = denom?.toUpperCase() || ''
    const firstDenom = unit?.substring(0,1) || ''
    if(firstDenom === 'U'){
      val = u2unit(amount || AgAmount)
      unit = unit.substring(1, unit.length)
    }else{
      val = getDecimalNum(amount || AgAmount, 8)
    }
  } else if (isString(data) || isNumber(data)) {
    val = getDecimalNum(data, 8)
  }
  return {
    amount: val || 0,
    denom: unit
  }
}


/**
 * 根据value获取值,
 * @param value
 */
export const getAmountByValue = (value: any) => {
  let result = getSrcAmountAndDenom(value?.amount || value?.AgAmount || value)
  return result.amount
}