export const assets = [
  {
    $schema: '../assetlist.schema.json',
    chain_name: 'srs',
    assets: [
      {
        description: 'BLD is the token used to secure the Srs chain through staking and to backstop Inter Protocol.',
        denom_units: [
          {
            denom: 'ubld',
            exponent: 0,
          },
          {
            denom: 'bld',
            exponent: 6,
          },
        ],
        base: 'ubld',
        name: 'Srs',
        display: 'bld',
        symbol: 'BLD',
        logo_URIs: {
          png: 'https://raw.githubusercontent.com/cosmos/chain-registry/master/srs/images/bld.png',
        },
        coingecko_id: 'srs',
      },
      {
        description: 'IST is the stable token used by the Srs chain for execution fees and commerce.',
        denom_units: [
          {
            denom: 'uist',
            exponent: 0,
          },
          {
            denom: 'ist',
            exponent: 6,
          },
        ],
        base: 'uist',
        name: 'Inter Stable Token',
        display: 'ist',
        symbol: 'IST',
        logo_URIs: {
          png: 'https://raw.githubusercontent.com/cosmos/chain-registry/master/srs/images/ist.png',
        },
      },
    ],
  },
]

export const chains = [
  {
    $schema: '../chain.schema.json',
    chain_name: 'srs',
    status: 'live',
    network_type: 'mainnet',
    website: 'https://srs.com/',
    pretty_name: 'Srs',
    chain_id: 'srs-3',
    // bech32_prefix: 'srs',
    bech32_prefix: 'sil',
    daemon_name: 'agd',
    node_home: '$HOME/.srs',
    slip44: 564,
    explorers: [
      {
        name: 'bigdipper',
        url: 'https://srs.bigdipper.live/',
        tx_page: 'https://srs.bigdipper.live/transactions/${txHash}',
        account_page: 'https://srs.bigdipper.live/accounts/${accountAddress}',
      },
      {
        name: 'explorers.guru',
        url: 'https://srs.explorers.guru',
        tx_page: 'https://srs.explorers.guru/transaction/${txHash}',
        account_page: 'https://srs.explorers.guru/account/${accountAddress}',
      },
      {
        kind: 'atomscan',
        url: 'https://atomscan.com/srs/',
        tx_page: 'https://atomscan.com/srs/transactions/${txHash}',
        account_page: 'https://atomscan.com/srs/accounts/${accountAddress}',
      },
    ],
    codebase: {
      git_repo: 'https://github.com/Srs/srs-sdk/',
      recommended_version: 'srs-upgrade-8',
      compatible_versions: ['srs-upgrade-8'],
      genesis: { genesis_url: 'https://main.srs.net/genesis.json' },
    },
    peers: {
      seeds: [
        {
          id: 'ade4d8bc8cbe014af6ebdf3cb7b1e9ad36f412c0',
          address: 'seeds.polkachu.com:14456',
          provider: 'Polkachu',
        },
        {
          id: '400f3d9e30b69e78a7fb891f60d76fa3c73f0ecc',
          address: 'srs.rpc.kjnodes.com:27659',
          provider: 'kjnodes',
        },
      ],
      persistent_peers: [
        {
          id: 'a26158a5cbb1df581dd6843ac427191af76d6d5d',
          address: '104.154.240.50:26656',
        },
        {
          id: '6e26a1b4afa6889f841d7957e8c2b5d50d32d485',
          address: '95.216.53.26:26656',
        },
      ],
    },
    apis: {
      rpc: [
        { address: 'https://main.rpc.srs.net:443' },
        { address: 'https://srs-rpc.polkachu.com', provider: 'Polkachu' },
        { address: 'https://rpc.srs.nodestake.top', provider: 'NodeStake' },
        { address: 'https://srs.rpc.kjnodes.com', provider: 'kjnodes' },
      ],
      rest: [
        { address: 'https://main.api.srs.net:443' },
        { address: 'https://api.srs.nodestake.top', provider: 'NodeStake' },
        { address: 'https://srs-api.polkachu.com', provider: 'Polkachu' },
        { address: 'https://srs.api.kjnodes.com', provider: 'kjnodes' },
      ],
      grpc: [
        { address: 'https://grpc.srs.nodestake.top', provider: 'NodeStake' },
        { address: 'srs-grpc.polkachu.com:14490', provider: 'Polkachu' },
      ],
    },
  },
]
