import React from 'react'
import { ChevronDownIcon, Search2Icon } from '@chakra-ui/icons'
import { Flex, Box, Menu, MenuButton, MenuList, MenuItem, Button, InputGroup, InputLeftElement, Input } from '@chakra-ui/react'
import { PageTitle } from '../../components'
import styles from './style.module.scss'
import { Image } from '@chakra-ui/react'
export default function Governance() {
  return (
    <div className={styles.container}>
      <PageTitle title={'GEA Governance'} subTitle={'NETWORK'}></PageTitle>
      <div className="style.bg">
        <Image src="./images/governnce.png" mt="-2px" mr="13px"></Image>
      </div>
      {/* <div className={styles.alist}>
        <div>
          <div className={styles.itemkey}>Total Balance</div>
          <div className={styles.itemvalue}>
            0.230000<span className={styles.unit}>SRC</span>
          </div>
          <div className={styles.cost}>$560</div>
        </div>
        <div>
          <div className={styles.itemkey}>Available Balance</div>
          <div className={styles.itemvalue}>
            0.230000<span className={styles.unit}>SRC</span>
          </div>
          <div className={styles.cost}>$-</div>
        </div>
        <div>
          <div className={styles.itemkey}>Total Staked</div>
          <div className={styles.itemvalue}>
            0<span className={styles.unit}>SRC</span>
          </div>
          <div className={styles.cost}>$-</div>
        </div>
        <div>
          <div className={styles.itemkey}>Claimable Rewards</div>
          <div className={styles.itemvalue}>
            0.21<span className={styles.unit}>SRC</span>
          </div>
          <div className={styles.cost}>$-</div>
        </div>
      </div>

      <PageTitle mt="20px" size="sm" title={'Governance'} subTitle={''}></PageTitle>
      <Flex>
        <InputGroup>
          <InputLeftElement h="48px" pointerEvents="none">
            <Search2Icon color="gray.300" />
          </InputLeftElement>
          <Input w="378px" size="lg" type="text" placeholder="Search Valldator" autoComplete="off" />
        </InputGroup>

        <Flex>
          <Menu>
            <MenuButton
              size="lg"
              colorScheme="gray"
              color="gray.650"
              borderColor="#EDEDED"
              variant="outline"
              fontWeight="normal"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              All Preiod
            </MenuButton>
            <MenuList>
              <MenuItem>Download</MenuItem>
              <MenuItem>Create a Copy</MenuItem>
              <MenuItem>Mark as Draft</MenuItem>
              <MenuItem>Delete</MenuItem>
              <MenuItem>Attend a Workshop</MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              size="lg"
              ml="28px"
              color="gray.650"
              borderColor="#EDEDED"
              colorScheme="gray"
              variant="outline"
              fontWeight="normal"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              ALL Proposals
            </MenuButton>
            <MenuList>
              <MenuItem>Download</MenuItem>
              <MenuItem>Create a Copy</MenuItem>
              <MenuItem>Mark as Draft</MenuItem>
              <MenuItem>Delete</MenuItem>
              <MenuItem>Attend a Workshop</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" wrap="wrap">
        <Box className={styles.gbox}>
          <Box>#78 Letter from many Cosmos maximalists to the hub</Box>
          <Box>Voting end time</Box>
          <Box>2022-10-10 06:30:31 UTC</Box>
        </Box>
        <Box className={styles.gbox}>
          <Box>#78 Letter from many Cosmos maximalists to the hub</Box>
          <Box>Voting end time</Box>
          <Box>2022-10-10 06:30:31 UTC</Box>
        </Box>
        <Box className={styles.gbox}>
          <Box>#78 Letter from many Cosmos maximalists to the hub</Box>
          <Box>Voting end time</Box>
          <Box>2022-10-10 06:30:31 UTC</Box>
        </Box>
        <Box className={styles.gbox}>
          <Box>#78 Letter from many Cosmos maximalists to the hub</Box>
          <Box>Voting end time</Box>
          <Box>2022-10-10 06:30:31 UTC</Box>
        </Box>
        <Box className={styles.gbox}>
          <Box>#78 Letter from many Cosmos maximalists to the hub</Box>
          <Box>Voting end time</Box>
          <Box>2022-10-10 06:30:31 UTC</Box>
        </Box>
        <Box className={styles.gbox}>
          <Box>#78 Letter from many Cosmos maximalists to the hub</Box>
          <Box>Voting end time</Box>
          <Box>2022-10-10 06:30:31 UTC</Box>
        </Box>
        <Box className={styles.gbox}>
          <Box>#78 Letter from many Cosmos maximalists to the hub</Box>
          <Box>Voting end time</Box>
          <Box>2022-10-10 06:30:31 UTC</Box>
        </Box>
        <Box className={styles.gbox}>
          <Box>#78 Letter from many Cosmos maximalists to the hub</Box>
          <Box>Voting end time</Box>
          <Box>2022-10-10 06:30:31 UTC</Box>
        </Box>
        <Box className={styles.gbox}>
          <Box>#78 Letter from many Cosmos maximalists to the hub</Box>
          <Box>Voting end time</Box>
          <Box>2022-10-10 06:30:31 UTC</Box>
        </Box>
        <Box className={styles.gbox}>
          <Box>#78 Letter from many Cosmos maximalists to the hub</Box>
          <Box>Voting end time</Box>
          <Box>2022-10-10 06:30:31 UTC</Box>
        </Box> */}
      {/* </Flex> */}
    </div>
  )
}
