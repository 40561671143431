import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from '../style.module.scss'

export default function MsgSetKycMaxStaking(props: any) {
  const render: any = (data = props.message) => {
    if (Object.prototype.toString.call(data) === '[object Object]') {
      return Object.keys(data).map((item) => {
        if (typeof data[item] === 'object') {
          return render(data[item])
        } else if (item === '@type') {
          return ''
        }
        return (
          <div className={styles.line} key={item}>
            <div className={styles.key}>{item}</div>
            <div className={styles.val}>{data[item]}</div>
          </div>
        )
      })
    } else if (Object.prototype.toString.call(data) === '[object Array]') {
      return data.map((item: any) => {
        if (typeof item === 'object') {
          return render(item)
        }
        return { item }
      })
    } else if (typeof data === 'string') {
      return <div className={styles.val}>{data}</div>
    } else {
      return ''
    }
  }
  return <>{render()}</>

  // return (
  //   <>
  //     <div className={styles.line}>
  //       <div className={styles.key}>RegionAdmin</div>
  //       <div className={styles.val}>{props.message?.regionAdmin}</div>
  //     </div>
  //     <div className={styles.line}>
  //       <div className={styles.key}>Account</div>
  //       <div className={styles.val}>{props.message?.account}</div>
  //     </div>
  //     <div className={styles.line}>
  //       <div className={styles.key}>RegionId</div>
  //       <div className={styles.val}>{props.message?.maxValue}</div>
  //     </div>
  //   </>
  // )
}
