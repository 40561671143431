import { divide } from "../utils";
import BigNumber from '../utils/bignumber'
import { DENOM_UNIT } from './config' 
// number decimal place
export const getDecimalNum = (value: string | number, decimal: number = 8)=>{
  if (new BigNumber(value).isFinite()) {
    return new BigNumber(value).decimalPlaces(decimal, 1).toFormat({ decimalSeparator: '.' })
  }
  return '0'
}

/**
 * 单位转换
 * 1e8u === 1
 * keep 8 decimal places
 */
export const u2unit = (u: string | number) => {
  let result:string = '';
  if (new BigNumber(u).isFinite()) {
    result = new BigNumber(u).div(DENOM_UNIT).decimalPlaces(8, 1).toFormat({ decimalSeparator: '.' });
  }
  return result || '0';
};
