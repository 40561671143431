import { debounce } from './functions'

enum EnumSize {
  Normal = `normal`,
  Pad=`pad`,
  Mobile=`mobile`
}
interface callbackArgs{
  isKeepSize: boolean,
  size: EnumSize
}
const responsive = {
  size: EnumSize.Normal,
  fontSize: 16,
  callback: ({ isKeepSize, size }: callbackArgs ) => {},
  executeSize() {
    const isRealMobile = /Mobile|Android/.test(navigator.userAgent);
    const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    const hiddenScroll = document.body.style?.overflow === 'hidden';
    const num = isRealMobile || hiddenScroll ? 0 : isMac ? 15 : 17;
    const bodyWidth = document.documentElement.clientWidth + num;
    if (bodyWidth > 1300) {
      this.size = EnumSize.Normal;
      this.fontSize = 16; // 100%
    } else if (bodyWidth > 1080) {
      this.size = EnumSize.Normal;
      this.fontSize = 15.36; // 96%
    } else if (bodyWidth > 640) {
      this.size = EnumSize.Pad;
      this.fontSize = 13.44; // 84%
    } else {
      this.size = EnumSize.Mobile;
      this.fontSize = 11.52; // 72%
    }
    const html = document.documentElement;
    html.style.fontSize = this.fontSize + 'px';
    const current = html?.dataset?.responsive;
    if (current !== this.size) {
      html.dataset.responsive = this.size;
    }
    const isKeepSize = current === this.size;
    if (this.callback) {
      this.callback({
        isKeepSize,
        size: this.size
      });
    }
  },
  init(cb: (arg0: callbackArgs) => void | null) {
    if (cb) {
      this.callback = cb;
    }
    this.executeSize();
    window.addEventListener(`resize`, debounce(this.executeSize.bind(this), 200));
  },
};
export default responsive;
