declare const window: any
import React from 'react'
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HashRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { ROUTES } from './resources/routes-constants'
import './styles/main.scss'

import { defaultTheme } from './config'
import { ChakraProvider } from '@chakra-ui/react'
import { ChainProvider } from '@cosmos-kit/react'
// import { WalletProvider } from '@cosmos-kit/react'
// import { chains, assets } from 'chain-registry'
import { chains, assets } from './config/assets'
import { ConfigProvider } from 'antd'
// import { wallets } from '@cosmos-kit/keplr'

import { SrsExtensionWallet, srsExtensionInfo } from './extension'
// import { wallets as cosmostationWallets } from '@cosmos-kit/cosmostation'
// import { wallets as keplrWallet } from '@cosmos-kit/keplr'
// import { wallets as keplrWallet } from './keplr/main/keplr.js'
const srs = new SrsExtensionWallet(srsExtensionInfo)
// console.log(keplrWallet)
import Layout from './components/layout/layout'

// import Home from './pages/home'
import Area from './pages/area'
import NotFound from './pages/notFound'

import BlockChain from './pages/blockchain'
import Dashboard from './pages/dashboard'
import BlockDetail from './pages/blockDetail'
import TransactionDetail from './pages/transactionDetail'
import Governance from './pages/governance'
import Wallet from './pages/wallet'
import ValidatorDetail from './pages/validatorDetail'
import Account from './pages/account'

// console.log('srs:::', srs)
// console.log('cosmostationWallets:::', cosmostationWallets)
// console.log('WalletProvider', WalletProvider)
// import crypto from '@keplr-wallet/crypto'

// console.log('crypto:::', crypto)

const RootComponent: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#08CE9E',
        },
      }}
    >
      <ChakraProvider theme={defaultTheme}>
        <ChainProvider
          // <ChainProvider
          chains={chains} // supported chains
          assetLists={assets} // supported asset lists
          wallets={[srs]} // supported wallets
        >
          <Router>
            <Layout>
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path={ROUTES.home} element={<Navigate to="/dashboard" />} />
                <Route path={ROUTES.area} element={<Area />} />
                <Route path={ROUTES.blockChain} element={<BlockChain />} />
                <Route path={ROUTES.dashboard} element={<Dashboard />} index={true} />
                <Route path={ROUTES.blockDetail} element={<BlockDetail />} />
                <Route path={ROUTES.transactionDetail} element={<TransactionDetail />} />
                <Route path={ROUTES.governance} element={<Governance />} />
                <Route path={ROUTES.wallet} element={<Wallet />} />
                <Route path={ROUTES.validatorDetail} element={<ValidatorDetail />} />
                <Route path={ROUTES.account} element={<Account />} />
              </Routes>
            </Layout>
          </Router>
        </ChainProvider>
      </ChakraProvider>
    </ConfigProvider>
  )
}

export default RootComponent
