import React, { useEffect, useState } from 'react'
import { ChevronDownIcon, Search2Icon } from '@chakra-ui/icons'
import { useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react'
import { debounce, round } from 'lodash-es'
import { useChain } from '@cosmos-kit/react'
import FixedDepositTable from './fixedDepositTable'

export default function Unbonding({ onClose, onOpen, isOpen, areaInfo, list, sendCallback }: any) {
  const toast = useToast()
  const toastIdRef: any = React.useRef()
  const walletManager = useChain('srs')

  useEffect(() => {}, [])

  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom" size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Redeem</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="25px">
          <FixedDepositTable showOperation={true} onClose={onClose} areaInfo="areaInfo" list={list} sendCallback={sendCallback}></FixedDepositTable>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
