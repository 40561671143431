import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Table, Thead, Tbody, Th, Tr, Td, TableContainer, Box } from '@chakra-ui/react'
import MobileTable from '../../components/mobileTable';
import styles from './style.module.scss'
import { Link } from 'react-router-dom'
import { getBlocksByLatest, getBlocksByHeight, validator } from '../../resources/api'
import { howLongAgo, getAverageTime, hash16 } from '../../utility/tools'
import { cutText } from 'common'
import { Loading } from '../../components'
import { round } from 'lodash-es'
import axios from 'axios'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'

type cprops = {
  list?: any[]
  setAverageTime?: any
  style?: any
}

export default function BlokTable({ list, setAverageTime, style = {} }: cprops) {
  const columns = [{ 
    title: 'Height',
    tdAttr: {
      h:'72px'
    },
    render: (_: any, item: any) => {
      return (
        <Link to={{ pathname: '/blockDetail', search: `?height=${item.block.header.height}` }}>
          <span className={styles.highlight}>{item.block.header.height}</span>
        </Link>
    )}
  }, {
    title: 'Block Hash',
    render: (_: any, item: any) => {
      return (
        <Link to={{ pathname: '/blockDetail', search: `?height=${item.block.header.height}` }}>
          <Tooltip placement="top" title={hash16(item.block_id.hash)} color="#444948">
            <span className={styles.highlight}>{cutText(hash16(item.block_id.hash))}</span>
          </Tooltip>
        </Link>
    )}
  }, {
    title: 'Proposer',
    render: (_: any, item: any) => {
      return (
        <Fragment>
          {
            item.RegionName && item.RegionName !== 'Global' ? 
            (
              <Link to={{ pathname: '/validatorDetail', search: `?name=${item.RegionName}` }}>
                <span className={styles.highlight}>{item.RegionName}</span>
              </Link>
            ) : 
            item.RegionName ? (<span className={styles.highlight}>{item.RegionName}</span>) : ('')
          }
        </Fragment>
    )}
  }, { 
    title: 'Txs',
    render: (_: any, item: any) => {
      return (
        <span>{item.block.data.txs.length}</span>
      )
    }
  }, { 
    title: 'Time',
    render: (_: any, item: any) => {
      return (
        <Tooltip placement="top" title={dayjs(item.block.header.time).format('MMM D, YYYY, h:mm A')} color="#444948">
          {howLongAgo(item.block.header.time)}
        </Tooltip>
      )
    }
  }]
  const [data, setData] = useState([] as any[])
  const [page, setPage] = useState<any>({
    page: 0,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  })
  const ref = useRef(data)
  ref.current = data
  const [loading, setLoading] = useState(false as boolean)

  const CancelToken = axios.CancelToken

  const blocksByHeight = async (height: any) => {
    const res = await getBlocksByHeight(height).catch((e) => console.error('e'))
    // res.block.header.proposer_address = 'BEAEEF13EB2235991CD157828C032D04B059FC10'
    const res2 = await validator(hash16(res.block.header.proposer_address)).catch((e) => console.error('e'))
    return {
      ...res,
      ...res2.validator,
    }
  }

  let loopId: any = null
  const loopLoad = () => {
    // let prev = curData[0]
    try {
      let source: any
      const request = async () => {
        // console.log('curData::', curData)
        if (source?.cancel) {
          source.cancel('cancel')
        }
        source = CancelToken.source()
        const last = await getBlocksByLatest({ cancelToken: source.token })
        // last.block.header.proposer_address = 'BEAEEF13EB2235991CD157828C032D04B059FC10'
        const res2 = await validator(hash16(last.block.header.proposer_address)).catch((e) => console.error('e'))

        const _data = {
          ...last,
          ...res2.validator,
        }

        // console.log(' ref.current::', ref.current)
        if(setAverageTime){
          setAverageTime((val: number) => {
            if (ref.current.find((v: any) => v?.block?.header.height === last.block.header.height)) {
              return val
            }
            const temp = (new Date(last?.block?.header.time).getTime() - new Date(ref.current[0]?.block?.header.time).getTime()) / 1000
            // console.log('temp::', temp)
            return round((val + temp) / 2, 2)
          })
        }

        // setLastTime(formatDate(last.block.header.time))
        setData((val) => {
          if (val.find((v) => v?.block?.header.height === last?.block?.header.height)) {
            return val
          }
          return [_data, ...val.slice(0, 9)]
        })

        // prev = last
      }

      loopId = setInterval(request, 3000)
    } catch (error) {
      console.error('loopLoad error: ', error)
    }
  }

  const initData = async () => {
    try {
      setLoading(true)
      const last = await getBlocksByLatest()
      // last.block.header.proposer_address = 'BEAEEF13EB2235991CD157828C032D04B059FC10'
      let res2 = { validator: {} }
      try {
        res2 = await validator(hash16(last.block.header.proposer_address))
      } catch (error) {
        console.error('e')
      }

      let height = Number(last.block.header.height)

      const _data = {
        ...last,
        ...res2.validator,
      }

      // setLastTime(formatDate(last.block.header.time))
      const temp = new Array(9).fill(1)
      const requestLsit = temp.map(() => blocksByHeight(--height))
      const list = await Promise.all(requestLsit)

      const time = getAverageTime(list)
      if(setAverageTime){
        setAverageTime(time)
      }
      setData([_data, ...list])
      setLoading(false)
      return last
    } catch (error) {
      setTimeout(() => {
        setLoading(false)
      }, 2000);
      console.error('initData error: ', error)
    }
  }

  useEffect(() => {
    initData().then(loopLoad)
    return () => {
      clearInterval(loopId)
    }
  }, [])

  return (
    <Box position="relative" minH="773px" style={style}>
      {loading ? <Loading></Loading> : ''}
      <TableContainer mt="20px" className='pc_table_container'>
        <Table variant="striped" colorScheme="blackAlpha" size="lg">
          <Thead>
            <Tr>
              {columns?.map((item: any) => {
                return <Th key={item.title}>{item.title}</Th>
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item: any) => {
              return (
                <Tr key={item?.block?.header?.height}>
                  {
                    columns.map((td: any, index: number) => {
                      const _val = item[td.dataIndex]
                      return ( 
                        <Td key={`${item.block_id}_td_${index}`} {...(td.tdAttr || {})}>
                          { td.render(_val, item) }
                        </Td>
                      )
                    })
                  }
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <MobileTable
        dataSource={data}
        columns={columns}
        rowKey={(record: any) => record?.block_id}
      />
    </Box>
  )
}
