declare const window: any
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './header.module.scss'
import { Menu, MenuButton, MenuList, MenuItem, Button, Image, Toast, useToast } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import XDMenu from './menu'
import WalletManager from './walletManager'
import { useLocation, useNavigate } from 'react-router-dom'
import { NET_TYPE_LOCAL_STORAGE_NAME } from '../../resources/constants'

export default function Header() {
  const navigate = useNavigate()

  const [type, setType] = useState(localStorage[NET_TYPE_LOCAL_STORAGE_NAME] ? localStorage[NET_TYPE_LOCAL_STORAGE_NAME] : 'Mainnet')

  const [isDrawer, setIsDrawer] = useState(false)

  const location = useLocation()
  // console.log('location::::::', location)
  const change = (selectType: string) => {
    if (selectType === type) {
      return
    }
    // if (selectType === 'Mainnet') {
    //   return
    // }
    // console.log('value: ', value.target.innerHTML)
    localStorage[NET_TYPE_LOCAL_STORAGE_NAME] = selectType
    setType(selectType)
    window.location.reload()
  }

  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <div className={styles.logo}>
          {/* <LinkIcon boxSize="20px" color="green.500" /> */}
          <Link to={{
            pathname: '/dashboard',
          }}>
            <Image boxSize="100%" src="./images/logo@2x.png" alt="Dan Abramov" />
          </Link>
        </div>
        <div className={styles.right}>
          <XDMenu></XDMenu>
          {location.pathname !== '/wallet' ? (
            <Menu>
              <MenuButton as={Button} w="115px" size="sm" h="34px" rightIcon={<ChevronDownIcon />}>
                {type}
              </MenuButton>
              <MenuList w="125px" className={styles.menu_list}>
                <MenuItem onClick={() => change('Mainnet')}>Mainnet</MenuItem>
                {/* <MenuItem onClick={() => change('Mainnet')} isDisabled={ type === 'Mainnet' }>Mainnet</MenuItem> */}
                {/* <div className={styles.horizon_line}></div>
                <MenuItem onClick={() => change('Devnet')} isDisabled={ type === 'Devnet' }>Devnet</MenuItem> */}
              </MenuList>
            </Menu>
          ) : (
            <WalletManager></WalletManager>
          )}

          {/* 123:{location.hash} */}
          {/* <Button isLoading={connecting} loadingText="Connecting" onClick={connectWallet}>
            Connect Wallet
          </Button> */}
          {/* <div>
            {username}-{address}
          </div> */}
        </div>
        <div className={styles.mobileMenu}>
          <div className={styles.mobileMenu_btn} onClick={()=>{
            setIsDrawer(true)
          }}>
            <Image boxSize="20px" src="./images/menu.png" alt="" />
          </div>
          
          <div className={[styles.mobileMenu_menu,isDrawer ? styles.mobileMenu_showMenu : ''].join(" ")}>
            <div className={styles.mobileMenu_mask} onClick={()=>{ 
              setIsDrawer(false) 
            }}></div> 
            <div className={styles.mobileMenu_menuList}>
              
              <div className={styles.mobileMenu_logo}>
                <Link to={{
                  pathname: '/dashboard',
                }}>
                  <Image boxSize="20px" src="./images/logo@2x.png" alt="" />
                </Link>
                <Image boxSize="16px" src="./images/close.png" alt="" onClick={()=>{setIsDrawer(false)}} />
              </div>
              <div  className={styles.mobileMenu_XDMenu} onClick={()=>{
                setIsDrawer(false)
              }}>
                <XDMenu></XDMenu>
              </div>
              {location.pathname !== '/wallet' ? (
                <div className={styles.mobileMenu_walletMenu}>
                  <Menu>
                    <MenuButton as={Button} w="116px" size="sm" h="36px" rightIcon={<ChevronDownIcon />}>
                      {type}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => {
                        change('Mainnet')
                        setIsDrawer(false)
                        }} >Mainnet</MenuItem>
                      {/* <MenuItem onClick={() => {
                        change('Mainnet')
                        setIsDrawer(false)
                        }} isDisabled={ type === 'Mainnet' }>Mainnet</MenuItem> */}
                      {/* <MenuItem onClick={() => {
                        change('Devnet')
                        setIsDrawer(false)
                        }} isDisabled={ type === 'Devnet' }>Devnet</MenuItem> */}
                    </MenuList>
                  </Menu>
                </div>
              ) : (
                <div className={styles.mobileMenu_walletMenu}> 
                  <WalletManager></WalletManager>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

