import React from 'react'
import styles from '../style.module.scss'
import { getSrcAmountAndDenom } from 'common'

export default function MsgAgToAc(props: any) {
  const result = getSrcAmountAndDenom(props.message?.AgAmount)
  return (
    <>
      <div className={styles.line}>
        <div className={styles.key}>Account</div>
        <div className={styles.val}>{props.message?.account}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>AgAmount</div>
        <div className={styles.val}>{result.amount} <span className='highlight'>{result.denom || ''}</span> </div>
      </div>
      {/* <div className={styles.line}>
        <div className={styles.key}>RegionId</div>
        <div className={styles.val}>{props.message?.regionId}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>Role</div>
        <div className={styles.val}>{props.message?.role}</div>
      </div> */}
    </>
  )
}
