import React, { useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { IconButton, Flex, useToast } from '@chakra-ui/react'
import { PageTitle } from '../../components'
import styles from './style.module.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import TransactionTable from './transactionTable'
import { qs } from 'url-parse'
import { JsonViewer } from '@textea/json-viewer'
import { getBlocksByHeight, validator } from '../../resources/api'
import { hash16, howLongAgo } from '../../utility/tools'
import { Base64 } from 'js-base64'
import { sha256 } from 'js-sha256'
import dayjs from 'dayjs'
import classNames from 'classnames'

export default function Home() {
  const toast = useToast()
  const [curTab, setCurTab] = useState<1 | 2>(1)
  const [data, setData] = useState<any>({ block: {}, block_id: {} })
  const [hash, setHash] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const { search } = useLocation()
  const searchs = qs.parse(search)
  // console.log('searchs:::', searchs)

  const prevBlock = () => {
    const height: number = Number(searchs.height) - 1 > 0 ? Number(searchs.height) - 1 : 0
    navigate({ pathname: '/blockDetail', search: `?height=${height}` }, { replace: true })
  }

  const nextBlock = () => {
    const height: number = Number(searchs.height) + 1
    navigate({ pathname: '/blockDetail', search: `?height=${height}` }, { replace: true })
  }

  const initData = async () => {
    if (loading) return

    if (Number.isNaN(Number(searchs.height)) || Number(searchs.height) < 1) {
      toast({
        title: 'Parameter is incorrect',
        position: 'bottom',
        status: 'info',
        duration: 3000,
        isClosable: true,
      })
      return
    }

    setLoading(true)
    // console.log('initData searchs:::', searchs)
    try {
      const res = await getBlocksByHeight(searchs.height)
      const res2 = await validator(hash16(res.block.header.proposer_address)).catch((e) => console.error(e))
      setData({
        ...res,
        ...res2.validator,
      })

      const hashList = res.block.data?.txs?.map((item: string) => {
        return sha256(Base64.toUint8Array(item))
      })
      setLoading(false)
      setHash(hashList)
    } catch (error: any) {
      setLoading(false)
      // console.log('error::::::::::', error)
      if (error?.response?.data?.code === 3) {
        toast({
          title: 'Currently is the latest block',
          position: 'bottom',
          status: 'info',
          duration: 4000,
          isClosable: true,
        })
        navigate({ pathname: '/blockDetail', search: `?height=${Number(searchs.height) - 1}` }, { replace: true })
      }
    }
  }

  useEffect(() => {
    initData()
  }, [searchs.height])

  return (
    <div className={styles.container}>
      <PageTitle title={`Details for Block #${data.block?.header?.height}`} subTitle={''}></PageTitle>
      <div className={styles.prev_next_box}>
        {/* <Button leftIcon={<EmailIcon />} variant="solid"></Button> */}
        <IconButton fontSize="20px" w="36px" h="25px" aria-label="prev" icon={<ChevronLeftIcon />} onClick={prevBlock} />
        <IconButton fontSize="20px" w="36px" h="25px" aria-label="next" icon={<ChevronRightIcon />} onClick={nextBlock} ml="22px" />
      </div>

      <Flex justifyContent="space-between" alignItems="center">
        <PageTitle size="md" title={'Header'} subTitle={''}></PageTitle>
        <div className={styles.tabs}>
          <div className={`${styles.tab} ${curTab === 1 ? styles.tabActive : undefined}`} onClick={() => setCurTab(1)}>
            Summary
          </div>
          <div className={`${styles.tab} ${curTab === 2 ? styles.tabActive : undefined}`} onClick={() => setCurTab(2)}>
            JSON
          </div>
        </div>
      </Flex>

      <div className={styles.summary} style={{ display: curTab === 1 ? '' : 'none' }}>
        <div className={styles.line}>
          <div className={classNames(styles.key, "mobile_global_normal")}>Chain Id</div>
          <div className={classNames(styles.val, "mobile_global_bold")}>gea</div>
        </div>
        <div className={styles.line}>
          <div className={classNames(styles.key, "mobile_global_normal")}>Height</div>
          <div className={classNames(styles.val, "mobile_global_bold")}>
            <span className={styles.highlight}>{data.block?.header?.height}</span>
          </div>
        </div>
        <div className={styles.line}>
          <div className={classNames(styles.key, "mobile_global_normal")}>Block Time</div>
          <div className={classNames(styles.val, "mobile_global_bold")}>
            {howLongAgo(data.block?.header?.time)}（{dayjs(data.block?.header?.time).format('MMM D, YYYY, h:mm A')}）
          </div>
        </div>
        <div className={styles.line}>
          <div className={classNames(styles.key, "mobile_global_normal")}>Block Hash</div>
          <div className={classNames(styles.val, "mobile_global_bold")}>{hash16(data.block_id?.hash)}</div>
        </div>
        <div className={styles.line}>
          <div className={classNames(styles.key, "mobile_global_normal")}>Number of Tx</div>
          <div className={classNames(styles.val, "mobile_global_bold")}>{data.block.data?.txs?.length}</div>
        </div>
        <div className={styles.line}>
          <div className={classNames(styles.key, "mobile_global_normal")}>Gas (used / wanted)</div>
          <div className={classNames(styles.val, "mobile_global_bold")}>
            --
            {/* {} / {} */}
          </div>
        </div>
        <div className={styles.line}>
          <div className={classNames(styles.key, "mobile_global_normal")}>Block Round</div>
          <div className={classNames(styles.val, "mobile_global_bold")}>{data.block.header?.round || '--'}</div>
        </div>
        <div className={styles.line}>
          <div className={classNames(styles.key, "mobile_global_normal")}>Proposer</div>
          <div className={classNames(styles.val, "mobile_global_bold")}>
            {/* <Link to={{ pathname: '/validatorDetail', search: `?id=${data.block.header?.proposer_address}` }}>
              <span className={styles.highlight}>{hash16(data.block.header?.proposer_address)}</span>
            </Link> */}
            {data.RegionName && data.RegionName !== 'Global' ? (
              <Link to={{ pathname: '/validatorDetail', search: `?name=${data.RegionName}` }}>
                <span className={styles.highlight}>{data.RegionName}</span>
              </Link>
            ) : data.RegionName ? (
              <span className={styles.highlight}>{data.RegionName}</span>
            ) : (
              ''
            )}
          </div>
        </div>

        {/* <div className={styles.line}>
          <div className={classNames(styles.key, "mobile_global_normal")}>memo</div>
          <div className={classNames(styles.val, "mobile_global_bold")}>{data.tx.body?.memo}</div>
        </div> */}
      </div>

      <div className={styles.jsonwrap} style={{ display: curTab !== 1 ? '' : 'none' }}>
        <JsonViewer value={data} rootName={false} defaultInspectDepth={4} />
      </div>

      <PageTitle size="sm" title={'Transactions'} subTitle={''}></PageTitle>
      {hash ? <TransactionTable hash={hash}></TransactionTable> : ''}
    </div>
  )
}
