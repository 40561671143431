import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../components'
import styles from './style.module.scss'
import { qs } from 'url-parse'
import BlockTable from './blockTable'
import TransactionTable from './transactionTable'
import { useNavigate, useLocation } from 'react-router-dom'
import {getBlocksByHeight, messageTotal, getValidatorByLatest} from '../../resources/api'
import dayjs from 'dayjs'
import {useRequest} from "ahooks";
import moment from "moment/moment";
import { getAverageTime } from "../../utility/tools";

export default function Blockchain() {
  const { search } = useLocation()
  const navigate = useNavigate()
  const [total, setTotal] = useState<any>('')
  const searchs = qs.parse(search)
  const [curTab, setCurTab] = useState<string>(searchs.tab || 'blocks')
  const [averageTime, setAverageTime] = useState<number>()
  const [beginTime, setBeginTime] = useState<string>('')

  useRequest(() => getBlocksByHeight(1), {
    ready: true,
    refreshDeps: [],
    onSuccess: (res: any) => {
      const {block = {}} = res
      const time = block?.header?.time
      const timeStamp = moment(new Date(time)).unix()
      const now: number = moment(new Date()).unix()
      const long:number = (now - timeStamp) / 3600 / 24

      setBeginTime(parseInt(`${long}`) + ' Day')
    }
  })
  useRequest(() => getValidatorByLatest(), {
    ready: true,
    refreshDeps: [],
    onSuccess: async (res: any) => {
      const temp = new Array(10).fill(1);
      const requestLsit = temp.map(() => getBlocksByHeight(--res.block_height));
      const list = await Promise.all(requestLsit);

      const time = getAverageTime(list);
      setAverageTime(time);
    },
  });
  const changeTab = (tab: any) => {
    setCurTab(tab)
    navigate({ pathname: '/blockchain', search: `?tab=${tab}` })
  }

  useEffect(() => {
    messageTotal().then((res) => {
      if (res?.code === 200) {
        setTotal(res.data)
      }
    })
  }, [])

  return (
    <div className={styles.container}>
      <PageTitle title={'GEA BlockChain'} subTitle={'NETWORK'}></PageTitle>

      <div className={styles.alist}>
        <div>
          <div className={styles.itemkey}>CHAIN ID</div>
          <div className={styles.itemvalue}>gea</div>
        </div>
        <div>
          <div className={styles.itemkey}>TOTAL TRANSACTIONS</div>
          <div className={styles.itemvalue}>{total}</div>
        </div>
        <div>
          <div className={styles.itemkey}>LATEST VERSION TIME</div>
          <div className={styles.itemvalue}>{beginTime}</div>
        </div>
        <div>
          <div className={styles.itemkey}>Block Time</div>
          <div className={styles.itemvalue}>{averageTime}s</div>
        </div>
      </div>

      <div className={styles.tabs}>
        <div className={`${styles.tab} ${curTab === 'blocks' ? styles.tabActive : undefined}`} onClick={() => changeTab('blocks')}>
          Bloks
        </div>
        <div className={`${styles.tab} ${curTab === 'transactions' ? styles.tabActive : undefined}`} onClick={() => changeTab('transactions')}>
          Transactions
        </div>
      </div>

      {/* {renderTable()} */}
      <BlockTable style={{ display: curTab === 'blocks' ? '' : 'none' }}></BlockTable>
      <TransactionTable style={{ display: curTab === 'transactions' ? '' : 'none' }}></TransactionTable>
    </div>
  )
}
