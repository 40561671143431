import { ReactElement, useState, useEffect, useRef, useMemo, Fragment } from 'react';
import classNames from 'classnames';
import { useInView } from "react-intersection-observer";
import { Spin } from 'antd';
// import { debounce } from '@/utils/index';
import styles from './index.module.scss';

const currentPageOptions: {
  current: number;
  pageSize: number;
  total: number;
} = {
  current: 0,
  pageSize: 0,
  total: 0,
};
const isThereMore = () => {
  return (
    currentPageOptions.current * currentPageOptions.pageSize <
    currentPageOptions.total
  );
};

interface Props {
  columns: Array<{
    title: string;
    dataIndex?: string;
    align?: string;
    render?: Function;
  }>;
  dataSource: Array<any>;
  rowKey: any;
  pagination?: {
    current: number;
    pageSize: number;
    total: number;
    onChange: Function;
  };
  multipleColumns?: string[];
  lastMultipleColumnsClass?: string
  className?: string
}
const mobileTable = (props: Props): ReactElement => {
  if (props.pagination) {
    const { current, pageSize, total } = props.pagination;
    currentPageOptions.current = current;
    currentPageOptions.pageSize = pageSize;
    currentPageOptions.total = total;
    // console.log(currentPageOptions)
  }
  const [mobileSpinDiv, spinInView] = useInView();
  useEffect(() => {
    if (props.pagination){
      let footer: HTMLElement | null = document.querySelector('#collapse-footer')
      if(isThereMore()){
        if(spinInView){
          const { current, onChange} = props.pagination;
          onChange(current + 1)
        }
        if(footer){
          const html: HTMLElement | null = document.documentElement;
          const current: String | undefined = html?.dataset?.responsive;
          if(current === 'mobile'){
            footer.style.display = 'none'
          }
        }
      }else{
        setTimeout(()=>{
          if(footer){
            footer.style.display = ''
          }
        },500)
      }
    }
  }, [spinInView]);
  
  interface tdData {
    data: any;
    dataIndex: Number;
    columnItem: any;
    columnClass?: any;
    lastMultipleColumnsClass: string;
    columnIndex: Number;
  }
  const currentData: Array<any> =
    props.dataSource.length > 0 ? props.dataSource : [{ _nodata: true }];
  const tdContent = ({ data, dataIndex, columnItem, columnIndex, columnClass, lastMultipleColumnsClass }: tdData) => {
    let dataIndexValue = columnItem.dataIndex ? data[columnItem.dataIndex] : '';
    let _value = dataIndexValue || '';
    let columnData = '';
    if (data._nodata === true) {
      columnData = '--';
    } else {
      if (columnItem?.render instanceof Function) {
        columnData = columnItem.render(_value, data, dataIndex) || '';
      }
    }
    return (
      <div
        className={classNames(`${styles.table_item} algin_${columnItem.align}`, lastMultipleColumnsClass)}
        key={`mobile_${props.rowKey(data)}_${dataIndex}_${columnItem.title}`}
      >
        <div className={classNames(styles.table_title, columnClass, "mobile_global_bold")}>{columnItem.title}</div>
        <div className={classNames(styles.table_value, columnClass)}>{columnData}</div>
      </div>
    );
  };
  const tableHtml = (
    <div className={`${styles.mobile_common_table} ${props.className}`}>
      {currentData.map((item, index) => {
        return (
          <div
            className={styles.mobile_common_table_tr}
            key={`mobile_${props.rowKey(item)}_${index}`}
          >
            {props.columns.map((columnItem, columnIndex) => {
              let lastMultipleColumnsClass = props.multipleColumns && props.multipleColumns.includes(columnItem.title) ? styles.multiple_items :""
              let columnClass = Number(columnIndex)%2 && classNames(styles.right_columns, "mobile_global_tr")
              if(lastMultipleColumnsClass && columnItem.title === `Height`){
                columnClass = `mobile_global_tc`
              }
              if(lastMultipleColumnsClass && columnItem.title === `Time`){
                columnClass = `mobile_global_tr`
              }
              return tdContent({
                data: item,
                dataIndex: index,
                columnItem,
                columnIndex,
                lastMultipleColumnsClass,
                columnClass,
              });
            })}
          </div>
        );
      })}
    </div>
  );

  return (
    <Fragment>
      {tableHtml}
      {props.pagination && isThereMore() ? (
        <div className={styles.mobile_spin_div} ref={mobileSpinDiv}>
          <Spin></Spin>
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};


// const currentElList: string[] = [] 
// const scrollPage = (elSelector: string, handler: Function) => {
//   let el: any = document.querySelector(elSelector)
//   if(!el){
//     console.warn('el undefined')
//     return 
//   }
//   if(currentElList.includes(elSelector)) return
//   currentElList.push(elSelector)
//   el?.addEventListener('scroll', debounce((e: any) => {
//     const { scrollHeight, scrollTop, clientHeight } = el;
//     const html: HTMLElement | null = document.documentElement;
//     const current: String | undefined = html?.dataset?.responsive;
//     if(current !== 'mobile') return
//     if(scrollHeight - scrollTop === clientHeight) {
//       if(currentPageOptions.current * currentPageOptions.pageSize < currentPageOptions.total){
//         handler(currentPageOptions.current + 1)
//       }
//     }
//   }, 300))
// };

export default mobileTable;
