import React, { useEffect, useState } from 'react'
import { ChevronDownIcon, Search2Icon } from '@chakra-ui/icons'
import {
  Flex,
  Text,
  Box,
  Button,
  Input,
  Avatar,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  InputRightAddon,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { debounce, round } from 'lodash-es'
import { useChain } from '@cosmos-kit/react'
import QRCode from 'qrcode'

export default function Wallet({ onClose, onOpen, isOpen }: any) {
  const toast = useToast()
  const toastIdRef: any = React.useRef()
  const walletManager = useChain('srs')

  const { status, username, address } = walletManager

  const createQrCode = ({ address }: any) => {
    // console.log('address::::::', address)
    const opts = {
      width: 300,
      errorCorrectionLevel: 'H',
      margin: 3,
      color: {
        dark: '#08A676',
        light: '#fff',
      },
    }

    QRCode.toCanvas(address, opts, function (error: any, canvas: any) {
      if (error) console.error(error)
      const container = document.getElementById('wallet-qrcode')
      if (container) container.appendChild(canvas)
      // console.log('success!')
    })
  }

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        createQrCode({ address })
      }, 20)
    }
  }, [address, isOpen])

  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom" size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div
            id="wallet-qrcode"
            style={{ width: '300px', height: '300px', borderRadius: '8px', overflow: 'hidden', margin: '5px auto 20px auto' }}
          ></div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
