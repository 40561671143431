import React, { useEffect, useState, Fragment } from 'react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { Table, Thead, Tbody, Th, Tr, Td, TableContainer, Image, Flex, Center, Box, Button } from '@chakra-ui/react'
import MobileTable from '../../components/mobileTable';
import styles from './style.module.scss'
import { Loading } from '../../components'
import { dealType, howLongAgo} from '../../utility/tools'
import { cutText } from 'common'
import { transByPage } from '../../resources/api'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'
import {renderAmount} from "../transactionDetail/renderAmount";


export default function TransactionTable() {
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const columns: any[] = [
    {
      title: 'Hash',
      dataIndex: 'hash',
      tdAttr: {
        pl:'18px'
      },
      render: (hash: string, item: any) => {
        return (
          <Flex alignItems="center">
            {item.success === true ? (
                <Image boxSize="17px" src="./images/su.png" mt="-2px" mr="13px"></Image>
            ) : (
                // <Image boxSize="17px" src="./images/su.png" mt="-2px" mr="13px"></Image>
                <Center style={{ background: '#E53E3E', borderRadius: '100%' }} mr="13px">
                  <SmallCloseIcon color="#fff"></SmallCloseIcon>
                </Center>
            )}

            <Box className={styles.highlight}>
              <Link to={{ pathname: '/transactionDetail', search: `?hash=${hash}` }}>
                <Tooltip placement="top" title={hash} color="#444948">
                  <span className={styles.highlight}>{cutText(hash)}</span>
                </Tooltip>
              </Link>
            </Box>
          </Flex>
        )
      }
    },
    {
      title: 'Type',
      dataIndex: 'messages',
      render: (_val: any, item: any) => {
        return (
          <Flex alignItems="center">
            <Image boxSize="17px" src={srcMap[dealType(item.messages[0]['@type'])] || srcMap['def']} mt="-1px" mr="6px"></Image>
            <Box className={styles.highlight}>{dealType(item.messages[0]['@type'])}</Box>
          </Flex>
        )
      }
    },
    {
      title: 'Amount',
      dataIndex: 'messages',
      render: (_val: any, item: any) => {
        return (
          renderAmount(item?.messages?.[0]?.amount || item?.messages?.[0]?.AgAmount, {color: '#08ce9e'})
        )
      }
    },
    {
      title: 'Height',
      dataIndex: 'height',
      render: (height: any) => {
        return (
          <Link to={{ pathname: '/blockDetail', search: `?height=${height}` }}>{height}</Link>
      )}
    },
    {
      title: 'Time',
      dataIndex: 'timestamp',
      render: (timestamp: any) => {
        return (
          <Tooltip placement="top" title={dayjs(timestamp).format('MMM D, YYYY, h:mm A')} color="#444948">
            {howLongAgo(timestamp)}
          </Tooltip>
        )
      }
    },
  ];
  const srcMap: any = {
    def: './images/type@2x1.png',
    'IBC Transfer': './images/type@2x1.png',
    'Withdraw Rewards': './images/type@2x5.png',
    Send: './images/type@2x2.png',
    Delegate: './images/type@2x3.png',
    Vot: './images/type@2x4.png',
    Undelegate: './images/type@2x4.png',
    NewKyc: './images/type@2x4.png',
  }

  const initData = async () => {
    // const requestLsit = hashList.map((v) => getTxsByHash(v))
    // const list = await Promise.all(requestLsit)

    // const list = await getAllTx()
    // const messageTotal = messageTotal()
    const res = await transByPage(1)
    if (res?.code !== 200) {
      return
    }
    // console.log('list::', res.data)
    res.data.sort((item: any, item2: any) => {
      return item2.height - item.height
    })
    // console.log('list::', res.data)
    setData(res.data)
    // setTotal(res.data)
    setLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])
  
  return (
    <Box position="relative" minH="500px" mt="0px">
      {loading ? <Loading></Loading> : ''}
      <TableContainer className='pc_table_container'>
        <Table variant="striped" colorScheme="blackAlpha" size="lg">
          <Thead>
            <Tr>
              {columns?.map((item: any, index: number) => {
                if (index === 0) {
                  return (
                    <Th key={item.title} pl="50px">
                      {item.title}
                    </Th>
                  )
                }
                return <Th key={item.title}>{item.title}</Th>
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item: any) => (
              <Tr key={item.hash} h="72px">
                {
                  columns.map((td: any, index: number) => {
                    const _val = item[td.dataIndex]
                    return ( 
                      <Td key={`${item.hash}_td_${index}`} {...(td.tdAttr || {})}>
                        { td.render(_val, item) }
                      </Td>
                    )
                  })
                }
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <MobileTable
        dataSource={data}
        columns={columns}
        rowKey={(record: any) => record?.hash}
      />      
      <Link to="/blockchain?tab=transactions">
        <Button w="140px" mt="20px" size="sm" h="30px">
          Show More
        </Button>
      </Link>
    </Box>
  )
}
