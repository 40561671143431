import { StdSignDoc } from '@cosmjs/amino'
import { Algo } from '@cosmjs/proto-signing'
import { BroadcastMode } from '@cosmos-kit/core'
import { DirectSignDoc, SignOptions, WalletClient } from '@cosmos-kit/core'

import { Srs } from './types'

// export { BroadcastMode } from '@cosmos-kit/core'

export class SrsClient implements WalletClient {
  readonly client: Srs

  constructor(client: Srs) {
    this.client = client
  }

  async enable(chainIds: string | string[]) {
    await this.client.enable(chainIds)
  }

  async disconnect() {
    await this.client.disconnect()
  }

  async getAccount(chainId: string) {
    const key = await this.client.getKey(chainId)
    // console.log('key:::', key)
    return {
      name: key.name,
      address: key.bech32Address,
      algo: key.algo as Algo,
      pubkey: key.pubKey,
    }
  }

  getOfflineSigner(chainId: string) {
    return this.client.getOfflineSigner(chainId)
  }

  async signAmino(chainId: string, signer: string, signDoc: StdSignDoc, signOptions?: SignOptions) {
    return await this.client.signAmino(chainId, signer, signDoc, signOptions)
  }

  async signDirect(chainId: string, signer: string, signDoc: DirectSignDoc, signOptions?: SignOptions) {
    return await this.client.signDirect(chainId, signer, signDoc, signOptions)
  }

  async sendTx(chainId: string, tx: Uint8Array, mode: BroadcastMode) {
    return await this.client.sendTx(chainId, tx, mode)
  }
}
