import React from 'react'
import Header from './header'
// import Footer from './footer'
import styles from './layout.module.scss'

type LayoutProps = {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <Header></Header>
      <div className={styles.content}>
          <div className={styles.content_body}>
              {children}
          </div>
      </div>
      {/* <Footer></Footer> */}
    </>
  )
}