import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toNumber } from "lodash-es";
import {
  Image,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Avatar,
  Box,
  Flex,
} from "@chakra-ui/react";
import { Input } from "antd";
import { Loading, PageTitle, Progress } from "../../components";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";
import MobileTable from "../../components/mobileTable";

import {
  getBalanceByAddr,
  getBlocksByHeight,
  getTakingRegion,
  getValidatorList,
  getValidatorByLatest,
  delegation,
} from "../../resources/api";
import { getAverageTime } from "../../utility/tools";
import { useRequest } from "ahooks";
import { BigNumber } from "bignumber.js";
import { u2unit, DENOM } from 'common'
import classNames from "classnames";
export default function Area() {
  const [searchKey, setSearchKey] = useState<string>("");
  // validator
  const [validatorList, setValidatorList] = useState<any[]>([]);
  const [validatorCount, setValidatorCount] = useState<number>();
  const [allDelegation, setAllDelegation] = useState<any[]>([]);
  // region
  const [regionList, setRegionList] = useState<any[]>([]);
  const [curryHeight, setCurryHeight] = useState<string>();
  const [averageTime, setAverageTime] = useState<number>();
  const [regionCommission, setRegionCommission] = useState<any[]>([]);
  const [totalStake, setTotalStake] = useState<number>(0);
  const [addressAvailableMap, setAddressAvailableMap] = useState<any>({});
  const [currentDenom, setCurrentDenom] = useState<string>('');
  // table Data
  const dataRecord = useMemo(() => {
    let temp = [];
    if (!searchKey) {
      temp = [...regionList];
    } else {
      temp = regionList.filter(
        (region: any) => region.regionName?.toUpperCase()?.indexOf(searchKey?.toUpperCase()) > -1
      );
    }
    // formatter record
    temp = temp.map((item) => {
      const _commission =
        regionCommission.find(
          (region: any) => region.regionId === item.regionId
        ) ?? {};

      const _isValidator = !!validatorList.find(
        (region: any) => region.RegionName === item.regionName
      );
      const _denomMatchResult = item.userMinDelegateAC.match(/\d+([A-Z]+)/)
      const _denom = _denomMatchResult?.length > 0 ? _denomMatchResult[1] : DENOM
      setCurrentDenom(_denom)
      return {
        ...item,
        ..._commission,
        _isValidator,
        _denom,
      };
    });

    return temp;
  }, [searchKey, regionList, validatorList]);

  const _getValidatorList = useRequest(() => getValidatorList(), {
    ready: true,
    refreshDeps: [],
    onSuccess: (res: any) => {
      if (res?.validator) {
        setValidatorList(res.validator);
      }
    },
  });

  const takingRegionAction = useRequest(() => getTakingRegion(), {
    ready: true,
    refreshDeps: [],
    onSuccess: (res: any) => {
      const _regionList = res?.region ?? [];
      const _commission = res?.region_commission ?? [];
      setRegionList(() => [..._regionList]);
      setRegionCommission(() => [..._commission]);
      const _total = _commission.reduce((prev: number, item: any) => {
        prev += toNumber(item.currentDemandTotalUAC);
        return prev;
      }, 0);

      setTotalStake(() => _total);
    },
  });

  const validatorAction = useRequest(() => getValidatorByLatest(), {
    ready: true,
    refreshDeps: [],
    onSuccess: async (res: any) => {
      setValidatorCount(res.validators.length);
      setCurryHeight(res.block_height);

      const temp = new Array(10).fill(1);
      const requestLsit = temp.map(() => getBlocksByHeight(--res.block_height));
      const list = await Promise.all(requestLsit);

      const time = getAverageTime(list);
      setAverageTime(time);
      console.log(`setAverageTime`)
    },
  });

  const getAreaAvailable = useCallback(
    async (records: any[]) => {
      const reqFn: any[] = records.map((item: any) =>
        getBalanceByAddr(item.baseAccountAddr)
      );
      const data = await Promise.all(reqFn);
      const newVal: any = {};
      // console.log(data)
      data.forEach((item: any, index: number) => {
        const amountObject =
          item.balances.find((item: any) => {
            return item.denom?.includes(DENOM.toLowerCase())
          }) ?? {};
        const amount = u2unit(amountObject?.amount)
        newVal[records[index as any].baseAccountAddr] = amount;
      });
      setAddressAvailableMap(newVal);
    },
    [addressAvailableMap]
  );
  /**
   * Get the regional available
   */
  useEffect(() => {
    if (dataRecord.length && !Object.keys(addressAvailableMap).length) {
      // request function arr
      getAreaAvailable(dataRecord);
    }
  }, [dataRecord]);

  const columns: any[] = [
    {
      title: "Area",
      dataIndex: "regionName",
      render: (text: string, record: any) => {
        return record._isValidator ? (
          <Link
            to={{
              pathname: "/validatorDetail",
              search: `?id=${record.regionId}&regionName=${record.regionName}`,
            }}
            className={styles.highlight}
          >
            <div className={styles.avatar}>
              <Avatar size="sm" src="./images/earth.webp" mr="6px" />
              <span className={styles.name}>{record.regionName}</span>
            </div>
          </Link>
        ) : (
          <div className={styles.avatar}>
            <Avatar size="sm" src="./images/earth.webp" mr="6px" />
            <span className={styles.name}>{record.regionName}</span>
          </div>
        );
      },
    },
    {
      title: "Weight",
      dataIndex: "regionTotalAS",
      align: "right",
      render: (text: string, record: any) => {
        return (
          <span>
            {Number(record.regionTotalAS)} AS /{" "}
            {(record.regionAsRate * 100).toFixed(5)}%
          </span>
        );
      },
    },
    {
      title: "Cumulative",
      dataIndex: "currentDemandTotalUAC",
      render: (currentDemandTotalUAC: string, record: any) => {
        const num: any = new BigNumber(Number(currentDemandTotalUAC || 0))
          .div(record.regionTotalUAC)
          .times(100);
        const floatLen: number =
          num.toString().indexOf(".") >= 0
            ? num.toString().split(".")[1].length - 1
            : 0;
        return (
          <div style={{ color: "#08CE9E" }}>
            {floatLen ? num.toFixed(floatLen) : num.toFixed(2)} %
          </div>
        );
      },
      pcRender: (currentDemandTotalUAC: string, record: any) => {
        return (
          <Progress
            value={
              toNumber(currentDemandTotalUAC) / toNumber(record.regionTotalUAC)
            }
          ></Progress>
        );
      },
    },
    {
      title: "Available",
      dataIndex: "baseAccountAddr",
      align: "right",
      render: (baseAccountAddr: string, record: any) => {
        return (
          <span>
            {addressAvailableMap[baseAccountAddr]}{" "}
            <span style={{ color: "#08CE9E" }}>{ addressAvailableMap[baseAccountAddr] ? record._denom : '' }</span>
          </span>
        );
      },
    },
    {
      title: "Staked",
      dataIndex: "currentDemandTotalUAC",
      render: (currentDemandTotalUAC: string, record: any) => {
        return (
          <Fragment>
            {u2unit(currentDemandTotalUAC)}{" "}
            <span style={{ color: "#08CE9E" }}>{ currentDemandTotalUAC ? record._denom : '' }</span>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Box position="relative" minH="773px">
      {takingRegionAction.loading || validatorAction.loading ? (
        <Loading></Loading>
      ) : (
        ""
      )}
      <div className={styles.container}>
        <PageTitle title={"GEA Area"} subTitle={"NETWORK"}></PageTitle>
        <Input
          allowClear
          placeholder={"Search Area"}
          prefix={<div className={styles.icon_search} />}
          onChange={(e: any) => setSearchKey(() => e.target.value)}
        />

        <div className={styles.alist}>
          <div>
            <div className={styles.itemicon}>
              <Image src="./images/alist@2x1.png" alt="" w="32px" h="34px" />
            </div>
            <div className={styles.itemkey}>Height</div>
            <div className={styles.itemvalue}>
              {curryHeight ? Number(curryHeight) : ""}
            </div>
          </div>
          <div>
            <div className={styles.itemicon}>
              <Image src="./images/alist@2x2.png" alt="" w="32px" h="32px" />
            </div>
            <div className={styles.itemkey}>Area</div>
            <div className={styles.itemvalue}>
              {/* {validatorCount} / {regionList.length} */}
              {regionList.length}
            </div>
          </div>
          <div>
            <div className={styles.itemicon}>
              <Image src="./images/alist@2x3.png" alt="" w="32px" h="32px" />
            </div>
            <div className={styles.itemkey}>Total Staked</div>
            <div className={classNames(styles.itemvalue, styles.totalStake, 'textOfTotalStaked')}>
              {u2unit(totalStake)}{" "}
              <span style={{ color: "#08CE9E" }}>{ totalStake ? currentDenom : ''}</span>
            </div>
          </div>
          <div>
            <div className={styles.itemicon}>
              <Image src="./images/alist@2x4.png" alt="" w="32px" h="32px" />
            </div>
            <div className={styles.itemkey}>Block Time</div>
            <div className={styles.itemvalue}>{averageTime}s</div>
          </div>
        </div>

        <TableContainer mt="55px" className={styles.table_wrapper}>
          <Table variant="striped" colorScheme="blackAlpha" size="lg">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                {columns?.map((item: any, index: number) => {
                  if (index === 0) {
                    return (
                      <Th key="{item.title}" pl="50px">
                        {item.title}
                      </Th>
                    );
                  }
                  return <Th key={item.title}>{item.title}</Th>;
                })}
              </Tr>
            </Thead>
            <Tbody>
              {dataRecord.map((item: any) => {
                return (
                  <Tr key={item.regionId}>
                    {columns.map((td: any, index: number) => {
                      const _val = item[td.dataIndex];
                      return (
                        <Td
                          key={`${item.regionId}_td_${index}`}
                          {...(td.tdAttr || {})}
                        >
                          {td.pcRender
                            ? td.pcRender(_val, item)
                            : td.render(_val, item)}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        {/*Mobile Table*/}
        <MobileTable
          dataSource={dataRecord}
          columns={columns}
          rowKey={(record: any) => record?.regionId}
        />
      </div>
    </Box>
  );
}
