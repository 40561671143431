import React, { useState } from 'react'
import { ChevronLeftIcon, InfoIcon, Search2Icon } from '@chakra-ui/icons'
import {
  Flex,
  Text,
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  InputGroup,
  InputRightElement,
  Center,
} from '@chakra-ui/react'
import { checkAmount, getMax, getMax2 } from '../../utility/tools'
import { u2unit, BASE_FEE, GAS } from 'common'

// STATUS_DELEGATE_NOCHANGE = 0 [(gogoproto.enumvalue_customname) = "Delegate_NoChange"];
// STATUS_CREATE_DELEGATE = 1 [(gogoproto.enumvalue_customname) = "Create_Delegate"];
// STATUS_DELEGATE = 2 [(gogoproto.enumvalue_customname) = "Delegate"];
// STATUS_UNDELEGATE = 3 [(gogoproto.enumvalue_customname) = "Undelegate"];
// STATUS_EXIT_DELEGATE = 4 [(gogoproto.enumvalue_customname) = "Exit_Delegate"];

export default function Stake({ onClose, onOpen, isOpen, areaInfo, data, sendCallback }: any) {
  const toast = useToast()
  const toastIdRef: any = React.useRef()
  const [amount, setAmount] = useState<any>('')
  const [redeletage, setRedeletage] = useState<any>('')
  const [memo, setMemo] = useState<string>('')
  const [sendLoading, setSendLoading] = useState<boolean>(false)
  const [step, setStep] = useState<any>(1)


  const handleDeletage = async () => {
    // console.log('发起活期存款')
    const max = getMax(data.ac)
    const { newAmount, res, msg } = checkAmount(amount, max)

    if (!res) {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current)
      }
      toastIdRef.current = toast({
        title: msg,
        position: 'bottom',
        status: 'info',
        duration: 4000,
        isClosable: true,
      })

      setAmount(newAmount)
      return
    }

    setSendLoading(true)
    try {
      /*let msg = 'msgCreateDelegate'
      // console.log('data:::::::', data)
      if (data.flexibleBalance > 0) {
        msg = 'msgDetegate'
      }*/
      const res = await window.srs.sendTx({
        msgType: 'msgCreateDelegate',
        regionID: areaInfo.regionId,
        amount: amount,
        feeAmount: BASE_FEE,
        gas: GAS,
        memo: memo,
      })
      // console.log('msgCreateDelegate res: ', res)

      sendCallback && sendCallback()
      toast({
        title: 'Success',
        position: 'bottom',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setSendLoading(false)
      closeModal()
    } catch (error: any) {
      setSendLoading(false)
      console.error('Transaction failed: ', error)
      toast({
        title: 'Failed',
        description: error?.rawLog || '',
        position: 'bottom',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const handleRedeletage = async () => {
    // console.log('发起赎回活期')

    const max = getMax2(data.flexibleBalance, data.ac)
    const { newAmount, res, msg } = checkAmount(redeletage, max)

    if (!res) {
      toast({
        title: msg,
        position: 'bottom',
        status: 'info',
        duration: 5000,
        isClosable: true,
      })

      setRedeletage(newAmount)
      return
    }

    // if (Number.isNaN(Number(redeletage))) {
    //   toast({
    //     title: 'Please enter a number',
    //     position: 'bottom',
    //     status: 'info',
    //     duration: 4000,
    //     isClosable: true,
    //   })
    //   return
    // }

    setSendLoading(true)
    try {
      /*let msg: any
      if (Number(redeletage) === data.flexibleBalance) {
        msg = 'msgExitDelegate'
      } else {
        msg = 'msgUndelegate'
      }*/

      // console.log('msgUndelegate before', { msgType: 'msgUndelegate', amount: redeletage, feeAmount: baseFee, gas: gas, memo: memo })

      const res = await window.srs.sendTx({ msgType: 'msgUndelegate', amount: redeletage, feeAmount: BASE_FEE, gas: GAS, memo: memo })

      // console.log('Undelegate res: ', res)
      sendCallback && sendCallback()
      toast({
        title: 'Undelegate success',
        position: 'bottom',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setSendLoading(false)
      closeModal()
    } catch (error: any) {
      setSendLoading(false)
      console.error('Undelegate failed: ', error)
      toast({
        title: 'Undelegate failed',
        description: error?.rawLog || '',
        position: 'bottom',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const closeModal = async () => {
    setStep(1)
    setAmount('')
    setRedeletage('')
    onClose()
  }

  return (
    <>
      <Modal isCentered onClose={closeModal} isOpen={isOpen} motionPreset="slideInBottom" size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {step === 1 ? (
              ''
            ) : (
              <Flex>
                <Center cursor="pointer" onClick={() => (step === 12 ? setStep(1) : setStep(step - 1))}>
                  <ChevronLeftIcon mt="-1px" fontSize="20px"></ChevronLeftIcon>
                </Center>
              </Flex>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mt="0px">
              <Box style={{ display: step === 1 ? '' : 'none' }}>
                <Box>
                  <Text>Area id: {areaInfo.regionId}</Text>
                  <Text mt="5px">Allow Stake Power: {areaInfo.allowStakePower} AS</Text>
                  <Text mt="5px">APR: 5%</Text>
                  <Text mt="10px">
                    <InfoIcon fontSize="15px" color="yellow.600" mt="-3px" mr="4px"></InfoIcon>
                    Staking will lock your funds for 24 hours You will need to undelegate in order for your staked assets to be liquid again. This
                    process will take 24 hours to complete.
                  </Text>
                  <Box border="1px solid #ededed" borderRadius="8px" padding="15px" mt="15px">
                    <Text>Your Delegation</Text>
                    <Text mt="5px" fontSize="16px">
                      {u2unit(data.flexibleBalance) || 0} GEAC
                    </Text>
                  </Box>
                  {/* <Text>6565 GEAC</Text> */}
                </Box>
                <Flex justifyContent="space-between" flexWrap="nowrap" w="100%">
                  <Box></Box>
                  <Box>
                    <Button variant="outline" mt="20px" minW="88px" onClick={() => setStep(2)} isDisabled={!data.flexibleBalance} mb="20px">
                      Redelegate
                    </Button>
                    <Button mt="20px" minW="88px" onClick={() => setStep(12)} mb="20px" marginLeft="20px">
                      Deletage
                    </Button>
                  </Box>
                </Flex>
              </Box>

              <Box style={{ display: step === 2 ? '' : 'none' }}>
                <Box border="1px solid #ededed" borderRadius="8px" padding="15px" mt="15px">
                  <Text>Your Delegation</Text>
                  <Text mt="5px" fontSize="16px">
                    {u2unit(data.showFlexibleBalance) || 0} GEAC
                  </Text>
                </Box>

                <Box mt="20px">
                  <FormLabel fontSize="16px" w="100%">
                    Redelegate
                  </FormLabel>
                  <InputGroup>
                    {/* autocomplete="off" */}
                    <Input placeholder="" value={redeletage} onChange={(e) => setRedeletage(e.target.value.trim())} size="lg" autoComplete="off" />
                    <InputRightElement pr="26px">
                      <Button
                        h="33px"
                        mt="6px"
                        size="md"
                        minW="50px"
                        colorScheme="gray"
                        fontWeight="normal"
                        onClick={(e) => setRedeletage(getMax2(u2unit(data.flexibleBalance), data.ac))}
                      >
                        Max
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Box>

                <Flex justifyContent="space-between" flexWrap="nowrap" w="100%">
                  <Box></Box>
                  <Box>
                    <Button mt="20px" minW="88px" onClick={() => setStep(3)} mb="20px">
                      Next
                    </Button>
                  </Box>
                </Flex>
              </Box>

              <Box style={{ display: step === 3 ? '' : 'none' }}>
                <Box>Redelegate Form</Box>
                <Box>{redeletage} GEAC</Box>
                <Box>Assets will be returned to your wallet address within 24 hours.</Box>

                <Flex justifyContent="space-between" flexWrap="nowrap" w="100%">
                  <Box></Box>
                  <Box>
                    <Button mt="20px" minW="88px" onClick={() => handleRedeletage()} isLoading={sendLoading} isDisabled={!redeletage} mb="20px">
                      Redeletage
                    </Button>
                  </Box>
                </Flex>
              </Box>

              <Box style={{ display: step === 12 ? '' : 'none' }}>
                <Box>
                  <Text>Area id: {areaInfo.regionId}</Text>
                  <Text mt="5px">Allow Stake Power: {areaInfo.allowStakePower}</Text>
                  <Text mt="5px">APR: 5%</Text>
                  <Text mt="10px">
                    <InfoIcon fontSize="15px" color="yellow.600" mt="-3px" mr="4px"></InfoIcon>
                    Staking will lock your funds for 24 hours You will need to undelegate in order for your staked assets to be liquid again. This
                    process will take 24 hours to complete.
                  </Text>
                  <Flex fontSize="16px">
                    <Box flexGrow="1" border="1px solid #ededed" borderRadius="8px" padding="15px" mt="15px">
                      <Text>Your Delegation</Text>
                      <Text mt="5px" fontSize="16px">
                        {u2unit(data.flexibleBalance) || 0} GEAC
                      </Text>
                    </Box>
                    <Box flexGrow="1" border="1px solid #ededed" borderRadius="8px" padding="15px" mt="15px" ml="20px">
                      <Text>Available to Delegate</Text>
                      <Text mt="5px">{data.showAc} GEAC</Text>
                    </Box>
                  </Flex>
                </Box>

                <FormLabel fontSize="16px" w="100%" mt="15px">
                  Amount to Delegate
                </FormLabel>
                <Flex justifyContent="space-between" alignItems="center" mt="5px">
                  <InputGroup w="100%">
                    <Input placeholder="" value={amount} onChange={(e) => setAmount(e.target.value.trim())} size="lg" autoComplete="off" />
                    <InputRightElement pr="26px">
                      <Button
                        h="33px"
                        mt="6px"
                        size="md"
                        minW="50px"
                        colorScheme="gray"
                        fontWeight="normal"
                        onClick={(e) => setAmount(getMax(data.ac))}
                      >
                        Max
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <Box fontSize="20px" color="#08CE9E" ml="14px">
                    GEAC
                  </Box>
                </Flex>

                <Flex justifyContent="space-between" flexWrap="nowrap" w="100%">
                  <Box></Box>
                  <Box>
                    <Button mt="20px" minW="88px" onClick={() => handleDeletage()} isLoading={sendLoading} isDisabled={!amount} mb="20px">
                      Delegate
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
