export interface FIXED_DEPOSIT_QUERY_TYPE_INTERFACE {
  readonly 0: string
  readonly 1: string
  readonly 2: string
}

// 定期质押查询类型
export const FIXED_DEPOSIT_QUERY_TYPE:FIXED_DEPOSIT_QUERY_TYPE_INTERFACE = {
  0: 'ALL_STATE', // 所有定期质押类型
  1: 'NOT_EXPIRED', // 未到期定期质押
  2: 'EXPIRED', // 到期定期质押
}

// 安全域名
export const SECURITY_DOMAIN = [
  'http://118.175.0.229',
  'http://118.175.0.230',
  'http://192.168.0.206',
  'http://localhost:3001',
  'http://localhost:3000',
  'http://localhost:3002',
]


export const DENOM = 'GEA' // 系统交易单位 当接口取值获取不到时使用
export const DENOM_UNIT = 1e8 // u单位转 单位换算值 1e8u === 1

export const PREFIX = 'sil' // 前缀
export const CHAIN_ID = 'srspoa' // 链ID

const gas_limit = 200000 // 2e5
const gas_price = 5

// 基础交易费
export const BASE_FEE = gas_limit * gas_price / 1e6 // 1

// 最小交易费
export const MIN_FEE = 200 / 1e6 // 0.0002

// 费率
export const RATE = 0.0001

// 交易费用阙值对应的金额阙值
export const AMOUNT_THRESHOLD = 20000 / 0.0001 // 200000000 / 2e8

// gas
export const GAS = 200000 // 2e5
