import styles from '../style.module.scss'
import {isArray, isNumber, isObject, isString} from "../../../utility/utils";
import {getSrcAmountAndDenom, u2unit} from "common";
import React from "react";

interface AmountObject {
    amount: string | number,
    denom: string
}

type Amount = AmountObject | AmountObject[] | string | number | any

export const renderAmount = (_amount: Amount, denomStyle = {}) => {

    if (isArray(_amount) || isObject(_amount)) {
        const rs = getSrcAmountAndDenom(_amount)
        return (
            <div className={styles.val}>
                {rs.amount} <span className={styles.highlight} style={denomStyle}>{rs.denom}</span>
            </div>
        )
    }

    if (isString(_amount) || isNumber(_amount)) {
        return (
            <div className={styles.val}>
                {u2unit(_amount)}
            </div>
        )
    }

    return (
        <div className={styles.val}>
            0
        </div>
    )
}
