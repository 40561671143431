import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from '../style.module.scss'

export default function MsgVote(props: any) {
  return (
    <>
      <div className={styles.line}>
        <div className={styles.key}>proposal_id</div>
        <div className={styles.val}>{props.message?.proposal_id}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>voter</div>
        <div className={styles.val}>{props.message?.voter}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>option</div>
        <div className={styles.val}>{props.message?.option}</div>
      </div>
    </>
  )
}
