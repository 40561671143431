import { NavLink } from 'react-router-dom'
import styles from './menu.module.scss'
type propsType = {}

export default function Menu({}: propsType) {
  const menuName = (name?: string) => {
    return ({ isActive }: any) => <div className={isActive ? styles.activeItem : undefined}><span>{name}</span></div>
  }

  return (
    <div className={styles.menuWrap}>
      <ul className={styles.menu}>
        <li>
          <NavLink to="dashboard">{menuName('Dashboard')}</NavLink>
        </li>
        {/* <li>
          <NavLink to="governance">{menuName('Governance')}</NavLink>
        </li> */}
        <li>
          <NavLink to="blockchain">{menuName('BlockChain')}</NavLink>
        </li>
        {/*<li>*/}
        {/*  <NavLink to="wallet">{menuName('Wallet')}</NavLink>*/}
        {/*</li>*/}
        <li>
          <NavLink to="area">{menuName('Area')}</NavLink>
        </li>
      </ul>
    </div>
  )
}
