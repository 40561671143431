import { useChain } from '@cosmos-kit/react'
import React, { useState } from 'react'
import { Button, useToast } from '@chakra-ui/react'
import { SECURITY_DOMAIN } from 'common'

export default function WalletManager () {

  const [connecting, setConnecting] = useState(false)

  const {
    connect,
    disconnect,
    status,
  } =  useChain('srs')

  const toast = useToast()

  // console.log('walletManager:::', walletManager)

  const connectWallet = async () => {
    setConnecting(true)
    // console.log(status, 'status')
    // setCurrentChain('srs')

    // debugger
    await connect()
    if (status === 'NotExist') {
      toast({ title: 'Failed to find gea extension', status: 'info', duration: 5000, isClosable: true })
    }
    // console.log('walletStatus', walletStatus)
    // console.log('address', address)
    // console.log('message', status)
    // console.log('currentChainName', currentChainName)
    // console.log('currentWallet', currentWallet)
    // console.log('currentChainRecord', currentChainRecord)
    // console.log('connectWallet:::', res)
    setConnecting(false)
  }

  const disconnectWallet = async () => {
    setConnecting(true)
    // setCurrentChain('agoric')

    await disconnect()
    setConnecting(false)
  }

  window.addEventListener(
    'message',
    async (event: MessageEvent) => {
      // console.log(event, 'header>>>')
      if (SECURITY_DOMAIN.includes(event.origin) && event.data.form === 'content' && event.data.value === 'disconnect') {
        // console.log('page header 收到断开的信息', walletManager)

        disconnect()
      }
    },
    false
  )

  return (
    <>
      {status !== 'Connected' ? (
        // isLoading={connecting} loadingText="Connecting"
        <Button size="sm" h="36px" onClick={connectWallet}>
          Connect Wallet
          {/* {status} */}
        </Button>
      ) : (
        // isLoading={connecting} loadingText="Connecting"
        <Button size="sm" colorScheme="orange"  h="36px" onClick={disconnectWallet}>
          Disconnect Wallet
        </Button>
      )}
    </>
  )
}
