import { Wallet } from '@cosmos-kit/core'
import { MainWalletBase } from '@cosmos-kit/core'

import { ChainSrsExtension } from './chain-wallet'
import { SrsClient } from './client'
import { getSrsFromExtension } from './utils'

export class SrsExtensionWallet extends MainWalletBase {
  constructor(walletInfo: Wallet) {
    super(walletInfo, ChainSrsExtension)
  }

  async fetchClient() {
    try {
      const srs = await getSrsFromExtension()
      // console.log('srs:::', srs)

      const appClient = srs ? new SrsClient(srs) : undefined
      // console.log('new SrsClient(srs):::', appClient)
      return appClient
    } catch (error) {
      this.setClientNotExist()
      return void 0
    }
  }
}
