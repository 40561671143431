import React, { useEffect, useState } from 'react'
import { InfoIcon, WarningIcon, ChevronDownIcon, Search2Icon } from '@chakra-ui/icons'
import {
  Flex,
  Text,
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  InputGroup,
  InputRightElement,
  useRadioGroup,
  useRadio,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { FixedDepositData2 } from '../../resources/constants'
import { checkAmount, getMax } from '../../utility/tools'
import { BASE_FEE, GAS } from 'common'

function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" margin="10px 0" width="48.5%">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="0"
        _checked={{
          color: 'white',
          bg: 'green.500',
          borderColor: 'green.600',
        }}
        _hover={{
          // border: '1px solid #ddd',
          // bg: 'blackAlpha.100',
          borderColor: 'green.600',
        }}
        transition="all 0.2s"
        _focus={{
          boxShadow: '0',
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  )
}

export default function Mange({ onClose, onOpen, isOpen, areaInfo, data, sendCallback }: any) {
  const toast = useToast()
  const toastIdRef: any = React.useRef()
  const { search } = useLocation()
  const [month, setMonth] = useState<any>()
  const [amount, setAmount] = useState<any>('')
  const [period, setPeriod] = useState<any>('')
  const [reward, setReward] = useState<any>('')
  const [rewards, setRewards] = useState<any>('')
  // const [depositData, setDepositData] = useState<any>({})
  const [memo, setMemo] = useState<string>('')
  const [sendLoading, setSendLoading] = useState<boolean>(false)
  const [fee, setFee] = useState<any>({
    feeAmount: BASE_FEE,
    gas: GAS,
  })

  const searchs = qs.parse(search)
  // console.log('searchs:::', searchs)

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    // defaultValue: 'react',
    onChange: (value: any) => {
      // console.log('value::::::::', value)
      // const depositData = FixedDepositData2[value]
      // console.log('depositData::::::::', depositData)
      const val = value.split('-')
      const months = val[0].split('_')
      setPeriod(val[2])
      setReward(val[1])
      setMonth(Number(months[1]))
      // setDepositData(depositData)
    },
  })

  useEffect(() => {
    // const rew = fixedDepositData[period]
    // eslint-disable-next-line no-debugger
    if (!reward) {
      return
    }
    const rs = (Number(amount) * (reward * 100) * (month / 12) * 400) / 100
    setRewards(Number(parseInt(String(rs))))
  }, [amount, reward, month])

  const handleDeletage = async () => {
    // console.log('发起定期存款')

    const max = getMax(data.ac)
    const { newAmount, res, msg } = checkAmount(amount, max)

    if (!period) {
      toast({
        title: 'Please select periodic',
        position: 'bottom',
        status: 'info',
        duration: 4000,
        isClosable: true,
      })
      return
    }

    if (!res) {
      toast({
        title: msg,
        position: 'bottom',
        status: 'info',
        duration: 5000,
        isClosable: true,
      })

      setAmount(newAmount)
      return
    }

    // if (Number.isNaN(Number(amount))) {
    //   toast({
    //     title: 'Please enter a number',
    //     position: 'bottom',
    //     status: 'info',
    //     duration: 4000,
    //     isClosable: true,
    //   })
    //   return
    // }

    setSendLoading(true)
    try {
      const res = await window.srs.sendTx({
        msgType: 'msgDoFixedDeposit',
        period: Number(period),
        amount,
        feeAmount: fee.feeAmount,
        gas: fee.gas,
        memo,
      })
      // console.log('handleDeletage res: ', res)

      sendCallback && sendCallback()
      toast({
        title: 'Transaction success',
        position: 'bottom',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setSendLoading(false)
      closeModal()
    } catch (error: any) {
      setSendLoading(false)
      console.error('Transaction failed: ', error)
      toast({
        title: 'Transaction failed',
        description: error?.rawLog || '',
        position: 'bottom',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const closeModal = async () => {
    // setPeriod('')
    setAmount('')
    onClose()
  }

  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom" size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mange</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mt="0px">
            <Box>
              {/* <FormLabel fontSize="16px">Form</FormLabel> */}
              <Box bg="blackAlpha.100" borderRadius="8px" border="1px solid rgba(0, 0, 0, 0.08)" padding="16px">
                <Text>Area name: {areaInfo.regionName}</Text>
                <Text mt="5px">Available to Delegate: {data.showAc} GEAC</Text>
                <Text mt="10px">
                  <InfoIcon fontSize="15px" color="yellow.600" mt="-3px" mr="4px"></InfoIcon>
                  Staking will lock your funds for 24 hours You will need to undelegate in order for your staked assets to be liquid again. This
                  process will take 24 hours to complete.
                </Text>
              </Box>
            </Box>
            <Flex justifyContent="space-between" flexWrap="wrap" mt="15px">
              {Object.keys(areaInfo.annualRate || {}).map((item: any, index: any) => {
                const depositData = FixedDepositData2[item]
                const showName = `${depositData.name}`
                const showApr = `APR ${areaInfo.annualRate[item] * 100}%`

                const radio = getRadioProps({ value: `${item}-${areaInfo.annualRate[item]}-${depositData.code}` })
                return (
                  <RadioCard key={item} {...radio}>
                    <Flex justifyContent="space-between">
                      <Box>{showName}</Box>
                      <Box>{showApr}</Box>
                    </Flex>
                  </RadioCard>
                )
              })}
            </Flex>
            <Box mt="20px">
              <FormLabel fontSize="16px">Amount</FormLabel>
              <Flex justifyContent="space-between" alignItems="center">
                {/* <Input bgColor="#FFF" placeholder="" onChange={(e) => changeAmount(e.target.value)} /> */}

                <InputGroup>
                  <Input
                    placeholder=""
                    value={amount}
                    maxLength={20}
                    onChange={(e) => setAmount(e.target.value.trim())}
                    size="lg"
                    autoComplete="off"
                  />
                  <InputRightElement pr="26px">
                    <Button
                      h="33px"
                      mt="6px"
                      size="md"
                      minW="50px"
                      colorScheme="gray"
                      fontWeight="normal"
                      onClick={(e) => setAmount(getMax(data.ac))}
                    >
                      Max
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Box fontSize="20px" color="#08CE9E" ml="14px">
                  GEAC
                </Box>
              </Flex>
              <Box fontSize="16px" color="#B1B6BB" mt="4px">
                Rewards: {rewards || 0} GEAG
              </Box>
            </Box>

            <Button mt="20px" minW="88px" onClick={handleDeletage} isLoading={sendLoading} isDisabled={!amount} float="right" mb="20px">
              Deletage
            </Button>
          </FormControl>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
