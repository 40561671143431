import { Box } from '@chakra-ui/react'
import React from 'react'
import styles from './styles.module.scss'

type cprops = {
  title?: string
  subTitle?: string
  size?: 'md' | 'sm'
  mt?: string
  mb?: string
}

export default function PageTitle(props: cprops) {
  const size = props.size ? styles[props.size] : ''

  return (
    <Box className={[styles.titleWrap, size].join(' ')} mt={props.mt} mb={props.mb}>
      <span className={styles.title}>{props.title}</span>
      <span className={styles.subtitle}>{props.subTitle}</span>
    </Box>
  )
}
