import { Wallet } from '@cosmos-kit/core'
import { GoDesktopDownload } from 'react-icons/go'
import { RiChromeFill } from 'react-icons/ri'

export const srsExtensionInfo: Wallet = {
  name: 'srs-extension',
  // name: 'Srs',
  logo: 'https://raw.githubusercontent.com/srswallet/assets/2289486990e1eaf9395270fffd1c41ba344ef602/images/srs-cosmos-logo.png',
  prettyName: 'Srs',
  mode: 'extension',
  mobileDisabled: true,
  rejectMessage: {
    source: 'Request rejected',
  },
  downloads: [
    {
      device: 'desktop',
      browser: 'chrome',
      icon: RiChromeFill,
      link: '',
    },
    {
      icon: GoDesktopDownload,
      link: 'https://chrome.google.com/webstore/detail/srs-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg',
    },
  ],
}
