import React from 'react'
import styles from '../style.module.scss'
import {renderAmount} from "../renderAmount";

export default function MsgCreateDelegate(props: any) {
  console.log(props)
  return (
    <>
      <div className={styles.line}>
        <div className={styles.key}>Creator</div>
        <div className={styles.val}>{props.message?.creator}</div>
      </div>
      {/* <div className={styles.line}>
        <div className={styles.key}>Delegator Address</div>
        <div className={styles.val}>{props.message?.delegatorAddress}</div>
      </div> */}
      <div className={styles.line}>
        <div className={styles.key}>Amount</div>
          {renderAmount(props.message?.amount)}
      </div>
      <div className={styles.line}>
        <div className={styles.key}>isUnmovable</div>
        <div className={styles.val}>{ `${props.message?.isUnmovable}` }</div>
      </div>
    </>
  )
}
