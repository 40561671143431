import React, { useEffect, useState } from 'react'
import { Table, Thead, Tbody, Th, Tr, Td, TableContainer, Image, Flex, Box, Button, useToast } from '@chakra-ui/react'
import styles from './style.module.scss'
import { FixedDepositData } from '../../resources/constants'
import { useChain } from '@cosmos-kit/react'
import { u2unit, BASE_FEE, GAS } from 'common'

export default function FixedDepositTable(props: any) {
  const toast = useToast()
  const toastIdRef: any = React.useRef()
  // const [list, setList] = useState<any[]>([])
  const [sendLoading, setSendLoading] = useState<any>('')
  // const [fee, setFee] = useState<any>({
  //   // feeAmount: baseFee,
  //   feeAmount: '15000000000',
  //   gas: '30000000',
  // })
  const columns = [{ title: 'Delegate' }, { title: 'Reward Maturity' }, { title: 'Type' }, { title: 'operation', show: props.showOperation }]
  const { status, username, address } = useChain('srs')

  const initData = async () => {
    if (!address) {
      return
    }
    // const res = await getFixedDeposit(address)
    // setList(res.FixedDeposit)
  }

  useEffect(() => {
    initData()
  }, [address])

  const handleUnbonding = async (item: any) => {
    // console.log('发起定期赎回')
    setSendLoading(item.id)
    try {
      const res = await window.srs.sendTx({
        msgType: 'msgDoFixedWithdraw',
        // regionID: props.areaInfo.regionId,
        id: Number(item.id),
        feeAmount: BASE_FEE,
        gas: GAS,
        memo: '',
      })
      // console.log('handleDeletage res: ', res)

      props.sendCallback && props.sendCallback()
      setSendLoading('')
      toast({
        title: 'Redeem success',
        position: 'bottom',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      closeModal()
    } catch (error: any) {
      console.error('Redeem failed: ', error)
      setSendLoading('')
      toast({
        title: 'Redeem failed',
        description: error?.rawLog || '',
        position: 'bottom',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const closeModal = () => {
    initData()
    props.onClose()
  }

  return (
    <TableContainer mt="10px">
      {/* <PageTitle size="md" mt="12px" title={'Activity'} subTitle={''}></PageTitle> */}

      <Table variant="striped" colorScheme="blackAlpha" size="lg">
        <Thead>
          <Tr>
            {columns?.map((item: any, index: number) => {
              if (item.show !== false) {
                return <Th key={item.title}>{item.title}</Th>
              }
            })}
          </Tr>
        </Thead>

        <Tbody>
          {props.list?.map((item: any) => {
            return (
              <Tr key={item.id}>
                <Td>
                  <Flex alignItems="center">
                    {/* <Image boxSize="17px" src="./images/su.png" mt="-2px" mr="13px"></Image> */}
                    <Box className={styles.highlight}>{u2unit(item.amount)} GEAC</Box>
                  </Flex>
                </Td>
                <Td>
                  <Flex alignItems="center">
                    {/* <Image boxSize="17px" src={srcMap[item.Type]} mt="-1px" mr="6px"></Image> */}
                    <Box>
                      {/* {((Number(item.amount) * FixedDepositData[item.period]?.reward * FixedDepositData[item.period]?.period) / 12).toLocaleString(
                        'en-US'
                      )}{' '} */}
                      {u2unit(item.interest)} GEAG
                    </Box>
                  </Flex>
                </Td>
                <Td>{FixedDepositData[item.period]?.name}</Td>
                {props.showOperation ? (
                  <Td>
                    <Button
                      variant="outline"
                      isLoading={sendLoading === item.id}
                      disabled={sendLoading || (sendLoading && sendLoading !== item.id)}
                      onClick={() => handleUnbonding(item)}
                    >
                      Redeem
                    </Button>
                  </Td>
                ) : (
                  ''
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
