import {Base64} from 'js-base64'
import {round} from 'lodash-es'
import {BASE_FEE, MIN_FEE, RATE, AMOUNT_THRESHOLD} from 'common'

// 计算某个时间距离当前时间多少秒
export const howLongAgo = (val: string): string => {
    if (!val) return ''
    const date = new Date(val)
    const now = new Date()
    // const offset = now.getTimezoneOffset() * 60 * 1000
    const diff = Math.ceil((now.getTime() - date.getTime()) / 1000)
    if (diff < 60) {
        return `${diff > 0 ? diff : 0}s ago`
    } else if (diff >= 60 && diff < 3600) {
        return `${round(diff / 60, 0)}m  ago`
    } else if (diff >= 3600 && diff < 86400) {
        return `${round(diff / 3600, 0)}h ago`
    }
    return `${round(diff / 86400, 0)}d ago`
}

// 总费用
export const totalGas = (gas: any, amount: any): string => {
    gas = gas ? Number(gas) : 0
    amount = amount || 0

    return gas + (amount || 0)
}

// 处理区块类型
export const dealType = (type: string) => {
    if (!type) {
        return ''
    }
    const typeArr = type.split('.')
    const typeName = typeArr[typeArr.length - 1].replace(/^Msg/, '')
    return typeName
}

// 固定小数位数
export const setDigit = (val: string, len = 0) => {
    if (val === null || val === undefined) {
        return ''
    }
    const arr = `${val}`.split('.')
    let temp = arr[1] || ''

    while (len - temp.length > 0) {
        temp = `${temp}0`
    }

    if (temp) {
        return `${arr[0]}.${temp}`
    }

    return val
}

export const setFloat = (val: string, len = 6) => {
    if (val === null || val === undefined) return ''

    const numberArr = val.split('.')
    const number = numberArr[0]
    const float = numberArr[1]

    if (float) {
        const fixFloat: string = float.slice(0, len)
        const floatArr = fixFloat.split('')
        const numberNotIsZeroIndex: number = floatArr.findIndex((str: string) => str != '0')

        return `${number}${numberNotIsZeroIndex > -1 ? `.${fixFloat}` : ''}`
    }

    return number
}

function formatNum(num: number) {
    return num < 10 ? `0${num}` : `${num}`
}

export const formatDate = (oldDate: any, format = 'yyyy-MM-dd HH:mm:ss') => {
    const date = new Date(oldDate)
    const hours: number | string = formatNum(date.getHours())

    const config: any = {
        yyyy: date.getFullYear(),
        M: date.getMonth() + 1,
        MM: formatNum(date.getMonth() + 1),
        W: date.getDay(),
        WW: formatNum(date.getDay()),
        d: date.getDate(),
        dd: formatNum(date.getDate()),
        H: date.getHours(),
        HH: hours,
        h: date.getHours() > 12 ? date.getHours() - 12 : date.getHours(),
        hh: Number(hours) > 12 ? Number(hours) - 12 : hours,
        m: date.getMinutes(),
        mm: formatNum(date.getMinutes()),
        s: date.getSeconds(),
        ss: formatNum(date.getSeconds()),
        A: date.getHours() < 12 ? 'AM' : 'PM',
        a: date.getHours() < 12 ? 'am' : 'pm',
    }
    const formatConfigs = format.match(/[a-zA-Z]+/g)
    formatConfigs?.forEach((item) => {
        format = format.replace(item, config[item])
    })
    return format
}

// 获取出块平均时间
export const getAverageTime = (list: any[]) => {
    const totalTime = list.reduce((prev, item, index) => {
        if (index === 0) {
            return prev
        }
        const temp = new Date(list[index - 1].block.header.time).getTime() - new Date(item.block.header.time).getTime()
        // console.log('temp::', temp)
        return temp + prev
    }, 0)

    return round(totalTime / 10 / 1000, 2)
}

export const uint8Array = (uint8Array: Uint8Array) => {
    return Array.prototype.map.call(uint8Array, (x) => ('00' + x.toString(16)).slice(-2)).join('')
}

export const hash16 = (hash: string) => {
    if (!hash) {
        return ''
    }
    const temp = Base64.toUint8Array(hash)
    return uint8Array(temp)
}

export const sliceArray = (array: any[], size: number) => {
    const result = []
    for (let x = 0; x < Math.ceil(array.length / size); x++) {
        const start = x * size
        const end = start + size
        result.push(array.slice(start, end))
    }
    return result
}

// 普通交易，根据可用金额，计算最大可交易额
export const getMaximum = (maxAmount: number) => {
    // console.log('maxAmount::::', maxAmount)
    if (maxAmount > AMOUNT_THRESHOLD) {
        // const val = (maxAmount - baseFee) / (1 + rate)
        const val = maxAmount - (maxAmount * RATE + BASE_FEE)
        return val > 0 ? parseInt(`${val}`) : 0
    } else {
        const val = maxAmount - MIN_FEE
        return val > 0 ? parseInt(`${maxAmount - MIN_FEE}`) : 0
    }
}

// 其他交易，根据可用金额，计算最大可交易额
export const getMaximumOther = (maxAmount: number) => {
    const max = maxAmount - BASE_FEE
    return max > 0 ? max : 0
}

// 校验金额，是某个值的整数倍，并且返回修正后的金额
export const checkAmount = (amount: any, max = 0, min = 1) => {
    // console.log('max::', max)
    if (!amount || Number.isNaN(Number(amount)) || Number(amount) < min) {
        return {
            msg: `Please enter a number greater than ${min}`,
            newAmount: amount,
            res: false,
        }
    }
    amount = Number(amount)
    if (amount > max) {
        return {
            msg: `Cannot be greater than ${max}. The amount has been automatically corrected`,
            newAmount: max,
            res: false,
        }
    }

    const remainder = amount % min

    // console.log('amount::', amount)
    // console.log('remainder::', remainder)
    if (remainder > 0) {
        return {
            msg: `The amount must be an integer multiple of ${min}. The amount has been automatically corrected`,
            newAmount: amount - remainder,
            res: false,
        }
    }

    return {
        msg: '',
        newAmount: amount,
        res: true,
    }
}

// 活期质押，根据可用金额，计算最大可交易额
export const getMax = (amount: any, min = 1) => {
    amount = Number(amount)
    const _max = amount - BASE_FEE
    if (_max < min) {
        return 0
    }

    const remainder = _max % min

    if (remainder > 0) {
        return _max - remainder
    }

    return _max
}

// 活期赎回，根据质押金额，计算最大可交易额
export const getMax2 = (amount: any, ac: any, min = 1) => {
    if (ac > min) {
        return amount
    } else {
        return 0
    }
}
