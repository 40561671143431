import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Search2Icon } from "@chakra-ui/icons";
import { debounce, round } from "lodash-es";
import { BigNumber } from "bignumber.js";
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import QRCode from "qrcode";
import { Loading, PageTitle, Progress } from "../../components";
import styles from "./style.module.scss";
import { useLocation } from "react-router-dom";
import {
  getAccountByAddr,
  getBalanceByAddr,
  getFixedDelegation,
  delegationByAddress,
} from "../../resources/api";
import TransactionTable from "./transactionTable";
import AgsListTable from "./agsListTable";
import { unitDataConvert, u2unit, getDecimalNum } from "common";
import { qs } from "url-parse";
import classNames from "classnames";

function transDenom(denom) {
  return denom.slice(1, denom.length).toUpperCase() ?? "";
}

function transAmount(amount) {
  return u2unit(amount);
}

function getDecimeNum(num) {
  return;
}

export default function Area() {
  // , { title: 'Total value' }
  const columns = [{ title: "Name" }, { title: "Amount" }];
  const [allData, setAllData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [appendData, setAppendData] = useState<any[]>([]);
  const [fullNormalData, setFullNormalData] = useState<any[]>([]);
  const [fullMobileData, setFullMobileData] = useState<any[]>([]);
  const [showAppend, setShowAppend] = useState(false);
  const [globalList, setGlobalList] = useState([]);
  const [agsList, setAgsList] = useState([]);
  const [searchKey, setSearchKey] = useState<string>("");
  const [validator1, setValidator1] = useState<number>();
  const [totalAmount, setTotalAmount] = useState<number>();
  const [validator2, setValidator2] = useState<number>();
  const [curryHeight, setCurryHeight] = useState<string>();
  const [averageTime, setAverageTime] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isKyc, setIskyc] = useState<boolean>(true);

  const { search } = useLocation();
  const searchs = qs.parse(search);
  const isLocal = location.href.includes(`localhost`);

  // eslint-disable-next-line no-debugger
  let res_fix_delegation,
    res2,
    res,
    // eslint-disable-next-line no-debugger
    resAsset,
    res_getDelegation = {};

  const initData = async () => {
    // setLoading(true)
    let _appendList = [],
      _leftAppendList = [],
      _rightAppendList = [],
      _isKyc = true;

    try {
      res = await getAccountByAddr(searchs.address);
    } catch (error) {
      if (isLocal) {
        res = {
          acount: {
            address: ``,
          },
        };
      }
    }
    try {
      res2 = await getBalanceByAddr(res.account.address);
    } catch (error) {
      if (isLocal) {
        // res2 = {
        //   balances: [
        //     {
        //       denom: `ugea`,
        //       amount: `1221`,
        //     },
        //     {
        //       denom: `ugeg`,
        //       amount: `222`,
        //     },
        //   ],
        // };
      }
    }
    try {
      res_getDelegation = await delegationByAddress(res.account.address);
      console.log(`res_getDelegation`, res_getDelegation);
    } catch (error) {
      //
      res_getDelegation = {
        delegation: {},
      };
    }
    resAsset = (res_getDelegation as any).delegation || {};
    // console.log(`resAsset`, resAsset);
    try {
      res_fix_delegation = await getFixedDelegation(res.account.address);
    } catch (error) {
      res_fix_delegation = {
        items: [],
      };
      _isKyc = false;
      setIskyc(false);
    }

    _appendList = [
      {
        label: `ANS`,
        value: getDecimalNum(
          String(
            new BigNumber(resAsset.kycAmount)
              .plus(resAsset.fixedAmount)
              .plus(resAsset.unmovableAmount)
              .plus(resAsset.amountAC)
              .div(100000000 * 400)
          )
        ),
      },
      {
        label: `ANS Reward:`,
        value: getDecimalNum(resAsset.interestAmount, 0),
      },
      {
        label: `ANS of AGR:`,
        // 1/400
        value: _isKyc ? `0.0025` : `0`,
      },
      {
        label: `ANS OF AGD:`,
        value: getDecimalNum(resAsset.amountAS),
      },
      {
        label: `ANS OF AGE:`,
        // unmovableAmount  / 1000000 / 400
        // unmovableInterest t截断 同一个值带上两个单位
        // value: `4  Unclaimed Reward:182.3GEA 122.2323GAG`,
        value: `${
          resAsset.unmovableAmount
            ? new BigNumber(resAsset.unmovableAmount).div(100000000 * 400)
            : `0`
        }  Unclaimed Reward:${getDecimalNum(
          resAsset.unmovableInterest,
          0
        )} GEA ${
          resAsset.unmovableInterest
            ? getDecimalNum(resAsset.unmovableInterest, 0)
            : `0`
        }GAG`,
      },
    ];

    // _appendList = []
    _appendList = _appendList.map((x) => {
      return {
        ...x,
        denom: x.label,
        amount: x.value,
        showText: `${x.label}  ${x.value}`,
      };
    });
    setAppendData([]);
    setAgsList(
      res_fix_delegation.items.map((x) => {
        let rewardStr = ``;
        if (x.interests?.length) {
          rewardStr = x.interests.reduce((prev, cur, index) => {
            if (/u/i.test(cur.denom)) {
              if (index > 0) {
                prev += " ";
              }
              prev +=
                getDecimalNum(
                  String(new BigNumber(cur.amount).div(100000000))
                ) + transDenom(cur.denom);
            }
            return prev;
          }, ``);
        }
        // console.log(`ans_alue`, x.amount.amount);
        return {
          rewardStr,
          ans_alue: getDecimalNum(
            String(new BigNumber(x?.amount?.amount || 0).div(100000000 * 400))
          ),
          ...x,
        };
      })
    );
    unitDataConvert(res2?.balances);
    res2.balances = res2.balances.map((x) => {
      x.denom = transDenom(x.denom);
      x.amount = transAmount(x.amount);
      return {
        ...x,
        showText: `${x.denom}  ${x.amount}`,
        showLabel: x.denom,
        showValue: getDecimalNum(x.amount),
        mobile_showLabel: x.denom,
        mobile_showValue: x.amount,
      };
    });
    // console.log(`res2.balances`, res2.balances);
    _leftAppendList = []
      .concat(res2.balances)
      .concat(_appendList.filter((x) => x.label === `ANS`))
      .map((x) => {
        return {
          ...x,
          showLabel: x.label,
          showValue: x.value,
        };
      });
    _rightAppendList = _appendList
      .filter((x) => x.label !== `ANS`)
      .map((x) => {
        return {
          ...x,
          showLabel: x.label,
          showValue: x.value,
        };
      });
    // console.log(`_rightAppendList`, _rightAppendList);
    const _globlList = [].concat(_leftAppendList, _rightAppendList);
    setGlobalList(_globlList);
    // console.log(`_globlList`, _globlList);
    if (_appendList?.length) {
      setShowAppend(true);
      const normalFullData = new Array(
        Math.max(_leftAppendList.length, _rightAppendList.length)
      )
        .fill({})
        .map((x, i) => {
          return {
            ...x,
            showLabel: _leftAppendList[i] ? _leftAppendList[i].showText : ``,
            showValue: _rightAppendList[i] ? _rightAppendList[i].showText : ``,
          };
        });
      // console.log(`normalFullData`, normalFullData);
      setData(normalFullData);
    } else {
      setData(res2.balances);
    }
    // console.log(`_appendList`, _appendList);
    setAllData(res2.balances);

    const opts = {
      width: 140,
      errorCorrectionLevel: "H",
      margin: 3,
      color: {
        dark: "#08A676",
        light: "#fff",
      },
    };

    QRCode.toCanvas(searchs.address, opts, function (error: any, canvas: any) {
      if (error) console.error(error);
      document.getElementById("canvas");
      const container = document.getElementById("qrcode");
      if (container) container.appendChild(canvas);
      // console.log('success!')
    });
  };

  useEffect(() => {
    initData();
  }, [searchs.address]);

  // const setTable = debounce((val) => {
  //   if (!val) {
  //     setData(allData)
  //     return
  //   }

  //   const temp = data.filter((item: any) => item.denom.indexOf(val) > -1)

  //   setData(temp)
  // }, 200)

  // const searchHandle = (e: ChangeEvent<HTMLInputElement>) => {
  //   // console.log('e.target.value::', e.target.value)
  //   setSearchKey(e.target.value)
  //   setTable(e.target.value)
  // }
  // const clearSearch = () => {
  //   setSearchKey('')
  //   setData(allData)
  // }

  return (
    <Box className={styles.container} position="relative" minH="773px">
      {loading ? <Loading></Loading> : ""}
      <div className={styles.container}>
        <PageTitle title={"GEA Account"} subTitle={""}></PageTitle>
        <Flex className={styles.flex_box}>
          <Box
            className={styles.code_box}
            color="white"
            bg="green.600"
            w="360px"
            padding="28px"
            borderRadius="8px"
          >
            <Box
              id="qrcode"
              style={{
                width: "140px",
                height: "140px",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            ></Box>
            <Box fontSize="20px" mt="25px">
              Address
            </Box>
            <Box fontSize="16px" color="whiteAlpha.700" mt="6px">
              {searchs.address}
            </Box>
          </Box>
          <Box
            className={styles.assets_box}
            padding="20px"
            borderRadius="10px"
            border="1px solid #ededed"
            ml="25px"
            flexGrow={1}
          >
            <Flex justifyContent="space-between">
              <Box fontSize="20px">Assets</Box>
              <Box>
                {/* <InputGroup w="378px">
                  <InputLeftElement pointerEvents="none">
                    <Search2Icon color="gray.400" />
                  </InputLeftElement>
                  <Input type="text" value={searchKey} placeholder="Search Asset" onChange={searchHandle} />
                  <InputRightElement>
                    <CloseButton size="sm" color="gray.500" onClick={clearSearch} style={{ display: searchKey ? '' : 'none' }} />
                  </InputRightElement>
                </InputGroup> */}

                {/* <Menu>
                  <MenuButton as={Button} w="115px" size="sm" h="34px" rightIcon={<ChevronDownIcon />}>
                    {type}
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={change}>mainnet</MenuItem>
                    <MenuItem onClick={change}>testnet</MenuItem>
                    <MenuItem onClick={change}>devnet</MenuItem>
                  </MenuList>
                </Menu> */}
              </Box>
            </Flex>

            <TableContainer mt="10px">
              <Table
                colorScheme="blackAlpha"
                size="lg"
                className={classNames()}
              >
                <Thead>
                  <Tr>
                    {columns?.map((item: any, index: number) => {
                      if (index === 0) {
                        return <Th key="{item.title}">{item.title}</Th>;
                      }

                      return <Th key={item.title}>{item.title}</Th>;
                    })}
                  </Tr>
                </Thead>
                {/* nomal */}
                <Tbody className={classNames(`onlyShowNomalSize`)}>
                  {data?.map((item: any) => {
                    return (
                      <Tr key={item.denom + item.showLabel} h="72px">
                        <Td>{item.showLabel}</Td>
                        <Td>{item.showValue}</Td>
                        {/* <Td>{'$46.89'}</Td> */}
                      </Tr>
                    );
                  })}
                </Tbody>
                {/* par or mobile */}
                <Tbody className={classNames(`onlyShowInPadOrMobile`)}>
                  {globalList?.map((item: any) => {
                    return (
                      <Tr key={item.denom + item.mobile_showLabel} h="72px">
                        <Td>{item.mobile_showLabel || item.showLabel}</Td>
                        <Td>{item.mobile_showValue || item.showValue}</Td>
                        {/* <Td>{'$46.89'}</Td> */}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Flex>
      </div>
      {agsList?.length ? (
        <AgsListTable agsList={agsList}></AgsListTable>
      ) : (
        <></>
      )}
      <TransactionTable></TransactionTable>
    </Box>
  );
}
