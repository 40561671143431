import React from 'react'
import { Link } from 'react-router-dom'
import {setFloat} from '../../../utility/tools'
import styles from '../style.module.scss'
import {renderAmount} from "../renderAmount";
import { CopyItem } from '../../../components'
export default function MsgSend(props: any) {
  return (
    <div>
      <div className={styles.line}>
        <div className={styles.key}>From Address</div>
        <div className={styles.val}>
          <Link to={{ pathname: '/account', search: `?address=${props.message?.from_address}` }}>
            <span className={styles.highlight}>{props.message?.from_address}</span>
          </Link>
          <CopyItem text={props.message?.from_address} />
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>To Address</div>
        <div className={styles.val}>
          <Link to={{ pathname: '/account', search: `?address=${props.message?.to_address}` }}>
            <span className={styles.highlight}>{props.message?.to_address}</span>
          </Link>
          <CopyItem text={props.message?.to_address} />
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.key}>Amount</div>
          {renderAmount({amount: setFloat(props.message?.amount?.[0]?.amount || '0'), denom: props.message?.amount?.[0]?.denom || ''})}
      </div>
    </div>
  )
}
