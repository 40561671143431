import React, { useEffect, useState } from 'react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { Table, Thead, Tbody, Th, Tr, Td, TableContainer, Image, Flex, Box, Center } from '@chakra-ui/react'
import styles from './style.module.scss'
import { PageTitle, MobileTable } from '../../components'
import { dealType } from '../../utility/tools'
import { cutText } from 'common'
import { useChain } from '@cosmos-kit/react'
import { getTransByHash, messageByAccount } from '../../resources/api'
import dayjs from 'dayjs'
import { Pagination, Tooltip } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { qs } from 'url-parse'
import {renderAmount} from "../transactionDetail/renderAmount";

export default function TransactionTable(props: any) {
  const [list, setList] = useState<any[]>([])
  const [page, setPage] = useState<any>({
    pageNumber: 1,
    total: 0,
    pageSize: 10,
  })
  const walletManager = useChain('srs')
  const { search } = useLocation()
  const searchs = qs.parse(search)
  // const { status, username, address } = walletManager

  const columns = [
    { 
      title: 'Hash',
      dataIndex: 'hash',
      tdAttr: {
        pl:'18px'
      },
      render: (hash: string, item: any) => {
        return (
          <Flex alignItems="center">
            {item.success === true ? (
              <Image boxSize="17px" src="./images/su.png" mt="-2px" mr="13px"></Image>
            ) : (
              // <Image boxSize="17px" src="./images/su.png" mt="-2px" mr="13px"></Image>
              <Center style={{ background: '#E53E3E', borderRadius: '100%' }} mr="13px">
                <SmallCloseIcon color="#fff"></SmallCloseIcon>
              </Center>
            )}
            <Box className={styles.highlight}>
              <Link to={{ pathname: '/transactionDetail', search: `?hash=${item.hash}` }}>
                <Tooltip placement="top" title={item.hash} color="#444948">
                  <span className={styles.highlight}>{cutText(item.hash)}</span>
                </Tooltip>
              </Link>
            </Box>
          </Flex>
        )
      } 
    },
    { 
      title: 'Type',
      dataIndex: 'type',
      render: (_val: any, item: any) => {
        return (
          <Flex alignItems="center">
            <Image boxSize="17px" src={srcMap[dealType(item.type)] || srcMap['def']} mt="-1px" mr="6px"></Image>
            <Box className={styles.highlight}>{dealType(item.type)}</Box>
          </Flex>
        )
      } 
    },
    // { title: 'From' },
    // { title: 'To' },
    { 
      title: 'Amount',
      dataIndex: 'messages',
      render: (_val: any, item: any) => {
        return (
          <span>{renderAmount(item?.messages?.[0]?.amount || item?.messages?.[0]?.AgAmount, {color: '#08ce9e'})}</span>
        )
      } 
    },
    { 
      title: 'Fee',
      dataIndex: 'fee',
      render: (_val: any, item: any) => {
        return (
          <span>{renderAmount(item?.fee?.amount, {color: '#08ce9e'})}</span>
        )
      }
    },
    { 
      title: 'Time',
      dataIndex: 'timestamp',
      render: (_val: any) => {
        return (
          <span>{dayjs(_val).format('MMM D, YYYY, h:mm A')}</span>
        )
      }
    },
    { 
      title: 'Memo',
      dataIndex: 'memo',
      render: (_val: any) => {
        return (
          <div className="textOver" style={{ maxWidth: '100px' }}>
            {_val}
          </div>
        )
      } 
    },
  ]

  const srcMap: any = {
    def: './images/type@2x1.png',
    'IBC Transfer': './images/type@2x1.png',
    'Withdraw Rewards': './images/type@2x5.png',
    Send: './images/type@2x2.png',
    Delegate: './images/type@2x3.png',
    Vot: './images/type@2x4.png',
    Undelegate: './images/type@2x4.png',
    NewKyc: './images/type@2x4.png',
  }

  const initData = async (address: any, page_number = 1, type ='pc') => {
    // const res = await getAccount()
    // res.address = 'sil157ykw7kanea77pkwkrhw6v6a7gpzlwwcwjztup'
    const res2: any = await messageByAccount({ account: searchs.address, page_number })
    // console.log('res2.data:::', res2.data)
    const trans = res2.data.map(async (item: any) => {
      const info = await getTransByHash({ transaction_hash: item.transaction_hash })
      return {
        ...item,
        ...info.data,
        // timestamp: info.timestamp,
        accountAddress: address,
      }
    })
    const res3 = await Promise.all(trans)

    // console.log('res3::', res3)
    // console.log('category::', category)
    // console.log('list::', list)

    setPage((val: any) => {
      return {
        ...val,
        pageNumber: page_number,
        total: res2.amount,
      }
    })
    if(type === 'mobile' && page_number !== 1){
      setList(list.concat(res3))
    }else{
      setList(res3)
    }
  }

  useEffect(() => {
    initData(searchs.address)
  }, [searchs.address])

  return (
    <>
      <PageTitle size="md" mt="12px" title={'Activity'} subTitle={''}></PageTitle>
      <div className='pc_table_container'>
        <TableContainer>
          <Table variant="striped" colorScheme="blackAlpha" size="lg">
            <Thead>
              <Tr>
                {columns?.map((item: any, index: number) => {
                  if (index === 0) {
                    return (
                      <Th key="{item.title}" pl="50px">
                        {item.title}
                      </Th>
                    )
                  }

                  return <Th key={item.title}>{item.title}</Th>
                })}
              </Tr>
            </Thead>

            <Tbody>
              {list?.map((item: any, index) => {
                return (
                  <Tr key={item.hash}>
                    {
                      columns.map((td: any, index: number) => {
                        const _val = item[td.dataIndex]
                        return ( 
                          <Td key={`${item.hash}_td_${index}`} {...(td.tdAttr || {})}>
                            { td.render(_val, item) }
                          </Td>
                        )
                      })
                    }
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>

        {page.total > 10 && (
          <Flex justifyContent="flex-end" mt="15px">
            <Pagination
              total={page.total}
              showSizeChanger={false}
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
              onChange={(page, pagesize) => initData(searchs.address, page)}
            />
          </Flex>
        )}
      </div>
      <MobileTable
        dataSource={list}
        columns={columns}
        rowKey={(record: any) => record?.hash}
        pagination={{
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: page.total,
          onChange: (page: number) => {
            initData(searchs.address, page, 'mobile')
          },
        }}
      />  
    </>
  )
}
