import React, { useEffect, useState } from "react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  TableContainer,
  Image,
  Flex,
  Box,
  Center,
} from "@chakra-ui/react";
import styles from "./style.module.scss";
import { PageTitle, MobileTable } from "../../components";
import { dealType } from "../../utility/tools";
import { cutText } from "common";
import { useChain } from "@cosmos-kit/react";
import { getTransByHash, messageByAccount } from "../../resources/api";
import dayjs from "dayjs";
import { Pagination, Tooltip } from "antd";
import { Link, useLocation } from "react-router-dom";
import { qs } from "url-parse";
import { renderAmount } from "../transactionDetail/renderAmount";

export default function TransactionTable(props: any) {
  const [list, setList] = useState<any[]>([]);
  const [page, setPage] = useState<any>({
    pageNumber: 1,
    total: 0,
    pageSize: 10,
  });
  const walletManager = useChain("srs");
  const { search } = useLocation();
  const searchs = qs.parse(search);
  // const { status, username, address } = walletManager

  const columns = [
    {
      title: "Product ID",
      dataIndex: "id",
      render: (_val: any, item: any) => {
        return <span>{item.id}</span>;
      },
    },
    {
      title: "ANS",
      dataIndex: "ans_alue",
      render: (_val: any, item: any) => {
        return <span>{_val}</span>;
      },
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      render: (_val: any, item: any) => {
        return <span>{dayjs(_val).format("MMM D, YYYY, h:mm A")}</span>;
      },
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      render: (_val: any, item: any) => {
        return <span>{dayjs(_val).format("MMM D, YYYY, h:mm A")}</span>;
      },
    },
    {
      title: "Term",
      dataIndex: "term",
      render: (_val: any, item: any) => {
        return <span>{_val}</span>;
      },
    },
    {
      title: "Accrued Reward",
      dataIndex: "fee",
      render: (_val: any, item: any) => {
        return <span>{item.rewardStr}</span>;
      },
    },

    // {
    //   title: "Time",
    //   dataIndex: "timestamp",
    //   render: (_val: any) => {
    //     return <span>{dayjs(_val).format("MMM D, YYYY, h:mm A")}</span>;
    //   },
    // },
  ];

  const srcMap: any = {
    def: "./images/type@2x1.png",
    "IBC Transfer": "./images/type@2x1.png",
    "Withdraw Rewards": "./images/type@2x5.png",
    Send: "./images/type@2x2.png",
    Delegate: "./images/type@2x3.png",
    Vot: "./images/type@2x4.png",
    Undelegate: "./images/type@2x4.png",
    NewKyc: "./images/type@2x4.png",
  };

  const initAgsList = (address?: any, page_number = 1, type = "pc") => {
    // console.warn(`res_fix_delegation`, props.agsList);
    setList(props.agsList);
  };

  useEffect(() => {
    initAgsList();
  });

  return (
    <>
      <PageTitle
        size="md"
        mt="12px"
        title={"AGS List"}
        subTitle={""}
      ></PageTitle>
      <div className="pc_table_container">
        <TableContainer>
          <Table variant="striped" colorScheme="blackAlpha" size="lg">
            <Thead>
              <Tr>
                {columns?.map((item: any, index: number) => {
                  if (index === 0) {
                    return (
                      <Th key="{item.title}" pl="50px">
                        {item.title}
                      </Th>
                    );
                  }

                  return <Th key={item.title}>{item.title}</Th>;
                })}
              </Tr>
            </Thead>

            <Tbody>
              {list?.map((item: any, index) => {
                return (
                  <Tr key={item.hash}>
                    {columns.map((td: any, index: number) => {
                      const _val = item[td.dataIndex];
                      return (
                        <Td
                          key={`${item.hash}_td_${index}`}
                          {...(td.tdAttr || {})}
                        >
                          {td.render(_val, item)}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>

        {page.total > 10 && (
          <Flex justifyContent="flex-end" mt="15px">
            <Pagination
              total={page.total}
              showSizeChanger={false}
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
              onChange={(page, pagesize) => initAgsList(searchs.address, page)}
            />
          </Flex>
        )}
      </div>
      <MobileTable
        dataSource={list}
        columns={columns}
        rowKey={(record: any) => record?.hash}
        pagination={{
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: page.total,
          onChange: (page: number) => {
            initAgsList(searchs.address, page, "mobile");
          },
        }}
      />
    </>
  );
}
