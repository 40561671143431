import { getBalanceByAddr } from './api'
import { u2unit } from 'common'
import { add } from '../utility/utils'

/**
 * 获取区域内活期和定期质押总额
 * @param depositAddress 活期质押地址
 * @param fixedDepositAccountAddr 定期质押地址
 */
export default async (depositAddress: string, fixedDepositAccountAddr: string) => {
  const [ current, fixed ] = await Promise.all([ getBalanceByAddr(depositAddress), getBalanceByAddr(fixedDepositAccountAddr) ])

  const currentTotal = (current?.balances?.find((item: any) => item.denom === 'usrc') || {}).amount || 0
  const fixedTotal = (fixed?.balances?.find((item: any) => item.denom === 'usrc') || {}).amount || 0

  return +u2unit(add(currentTotal, fixedTotal))
}
