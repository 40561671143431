import React from 'react'
import styles from '../style.module.scss'
import { unitDataConvert, u2unit } from 'common'
import {toNumber} from "lodash-es";
import {setFloat} from "../../../utility/tools";

export default function MsgDeposit(props: any) {
  const render: any = (data = props.message) => {
    unitDataConvert(data?.amount)
    if (Object.prototype.toString.call(data) === '[object Object]') {
      if (data?.demon === 'usrc') {
        data.amount = u2unit(data?.amount)
      }
      if (data?.demon === 'usrg') {
        data.amount = u2unit(data?.amount)
      }
      return Object.keys(data).map((item) => {

        if (typeof data[item] === 'object') {
          return render(data[item])
        } else if (item === '@type') {
          return ''
        }

        if (item === 'denom') {
          const value = data[item].slice(0, 1).toUpperCase() === 'U' ? data[item].slice(1, item.length).toUpperCase() : data[item].toUpperCase()

          return (
              <div className={styles.line} key={item}>
                <div className={styles.key}>{item}</div>
                <div className={styles.val}>{value}</div>
              </div>
          )
        }

        if (item === 'amount') {
          const denom = data.denom ?? ''

          if (denom && denom.slice?.(0, 1).toUpperCase() === 'U') {

            return (
                <div className={styles.line} key={item}>
                  <div className={styles.key}>{item}</div>
                  <div className={styles.val}>{u2unit(data[item])}</div>
                </div>
            )
          }
        }

        if (item === 'rate') {
          return (
              <div className={styles.line} key={item}>
                <div className={styles.key}>{item}</div>
                <div className={styles.val}>{setFloat(data[item])}</div>
              </div>
          )
        }

        if (!data[item]) return null

        return (
          <div className={styles.line} key={item}>
            <div className={styles.key}>{item}</div>
            <div className={styles.val}>{data[item]}</div>
          </div>
        )
      })
    } else if (Object.prototype.toString.call(data) === '[object Array]') {
      return data.map((item: any) => {
        if (typeof item === 'object') {
          return render(item)
        }
        return {item}
      })
    } else if (typeof data === 'string') {
      return <div className={styles.val}>{data}</div>
    } else {
      return ''
    }
  }
  return <>{render()}</>
}
