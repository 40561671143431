export const ROUTES = {
  home: '/',
  area: '/area',
  blockChain: '/blockChain',
  dashboard: '/dashboard',
  blockDetail: '/blockDetail',
  transactionDetail: '/transactionDetail',
  governance: '/governance',
  wallet: '/wallet',
  validatorDetail: '/validatorDetail',
  account: 'account',
}
