import React from 'react'
import styles from '../style.module.scss'
import {toNumber} from "lodash-es";

const renderValue = (key: string, message: any) => {
    if (['regionFeeRate', 'regionTotalAS', 'regionTotalStakeAllow', ''].includes(key)) {
        return <div className={styles.val}>{toNumber(message[key])}</div>
    }

    return <div className={styles.val}>{`${message[key]}`}</div>
}

export default function MsgUpdateRegion(props: any) {
    const {message = {}} = props
    return (
        <>
            {
                Object.keys(message).map((key: string, index: number) => {
                    if (message[key] === '' || toNumber(message[key]) < 0 || key === '@type') return ''

                    return (
                        <div className={styles.line} key={index}>
                            <div className={styles.key}>{key}</div>
                            {renderValue(key, message)}
                        </div>
                    )
                })
            }
        </>
    )
}
