import React, {useEffect, useState} from 'react'
import {Search2Icon} from '@chakra-ui/icons'
import {InputGroup, Flex, InputLeftElement, Input, InputRightElement, CloseButton, useToast} from '@chakra-ui/react'
import {PageTitle} from '../../components'
import styles from './style.module.scss'
import {useNavigate} from 'react-router-dom'
import {useRequest} from 'ahooks'

import Map from './map'
import BlockTable from './blockTable'
import TransactionTable from './transactionTable'
import {debounce} from 'lodash-es'
import {getAccount, getBlocksByHeight, getBlocksByLatest, messageTotal} from '../../resources/api'
import {isInteger} from "../../utility/utils";
import moment from "moment";

export default function Dashboard() {
    const toast = useToast()
    const [searchKey, setSearchKey] = useState<string>('')
    const navigate = useNavigate()
    const [accountNum, setAccountNum] = useState<string>('')
    const [total, setTotal] = useState<any>('')
    const [beginTime, setBeginTime] = useState<string>('')

    useRequest(() => getBlocksByHeight(1), {
        ready: true,
        refreshDeps: [],
        onSuccess: (res: any) => {
            const {block = {}} = res
            const time = block.header?.time
            const timeStamp = moment(new Date(time)).unix()
            const now: number = moment(new Date()).unix()
            const long:number = (now - timeStamp) / 3600 / 24
            setBeginTime(parseInt(`${long}`) + ' Day')
        }
    })



    const searchHandle = debounce((e: any) => {
        if (e.keyCode !== 13) return
        const value = e.target.value.trim()
        setSearchKey(value)
        toSearch(value)
    }, 300)

    const toSearch = (value: string)=>{
        //  todo 地址验证写成配置
        if (value?.length === 42 && /^gea/.test(value)) {
            navigate({pathname: '/account', search: `?address=${value}`})
            toast.closeAll()
        } else if (!Number.isNaN(Number(value)) && Number(value) >= 1 && isInteger(Number(value))) {
            navigate({pathname: '/blockDetail', search: `?height=${value}`})
            toast.closeAll()
        } else if (value?.length === 64) {
            navigate({pathname: '/transactionDetail', search: `?hash=${value}`})
            toast.closeAll()
        } else {
            toast({
                title: 'Please enter the correct block height/transaction hash/tx hash',
                // description: '',
                status: 'info',
                duration: 6000,
                isClosable: true,
            })
        }
    }

    const clearSearch = () => {
        setSearchKey('')
        // setData(allData)
    }

    useEffect(() => {
        getAccount().then((res) => {
            // console.log('res:', res)
            setAccountNum(Number(res.pagination?.total || 0) + '')
        })
        messageTotal().then((res) => {
            // console.log('res:::', res)
            if (res?.code === 200) {
                setTotal(res.data)
            }
        }).catch(() => {
            setTotal(0) // 请求失败, 默认赋值为0, 避免展示为空
        })
    }, [])

    return (
        <div className={`${styles.container} dashboard_page`}>
            <PageTitle title={'GEA Explorer'} subTitle={'NETWORK'}></PageTitle>

            <div className={styles.alist}>
                <div>
                    <div className={styles.itemkey}>CHAIN ID</div>
                    <div className={styles.itemvalue}>gea</div>
                </div>
                <div>
                    <div className={styles.itemkey}>TOTAL TRANSACTIONS</div>
                    <div className={styles.itemvalue}>{total ?? 0}</div>
                </div>
                <div>
                    <div className={styles.itemkey}>LATEST VERSION TIME</div>
                    <div className={styles.itemvalue}>{beginTime}</div>
                </div>
                <div>
                    <div className={styles.itemkey}>ACCOUNTS</div>
                    <div className={styles.itemvalue}>{accountNum}</div>
                </div>
            </div>
            <InputGroup className='input_group pc_display' mt="36px">
                <InputLeftElement h="48px" pointerEvents="none">
                    <div className={styles.icon_search}></div>
                </InputLeftElement>
                <Input
                    style={{border: '1px solid #EDEDED'}}
                    size="lg"
                    type="text"
                    placeholder="Search by Address,Block Height,TxHash"
                    onKeyDown={searchHandle}
                    autoComplete="off"
                />
                <InputRightElement>
                    <CloseButton size="sm" color="gray.500" onClick={clearSearch}
                                 style={{display: searchKey ? '' : 'none'}}/>
                </InputRightElement>
            </InputGroup>
            <InputGroup className='input_group pad_display' mt="36px">
                <Input
                    style={{border: '1px solid #EDEDED'}}
                    size="lg"
                    type="text"
                    placeholder="Search by Address,Block Height,TxHash"
                    autoComplete="off"
                    onKeyDown={searchHandle}
                    onChange={(event)=>{setSearchKey(event.target.value)}}
                />
                <InputRightElement onClick={()=>{toSearch(searchKey)}}>
                    <div className={styles.icon_search}></div>
                </InputRightElement>
            </InputGroup>
            <PageTitle size="sm" mt="30px" title={'Latest Blocks'}></PageTitle>
            <Flex justifyContent="space-between" shrink="0" wrap={'wrap'}>
                <BlockTable />
                {/* <Map /> */}
            </Flex>

            <PageTitle size="sm" mt="30px" title={'Latest Transactions'}></PageTitle>
            <TransactionTable></TransactionTable>
        </div>
    )
}
