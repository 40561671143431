import { ReactElement, useState, useEffect, useRef, useMemo, Fragment } from 'react';
import copy from 'copy-to-clipboard';
import { useToast } from '@chakra-ui/react'
import { AiOutlineCopy } from "react-icons/ai";
import styles from './index.module.scss';
interface Props {
  text: string | null | undefined
}
const copyItem = (props: Props): ReactElement => {
  const toast = useToast({
    position: 'top',
    status: 'info',
    duration: 3000,
    isClosable: true
  })
  function copyText(){
    try {
      copy(props.text)
      toast({ title: 'Copied' })
    } catch (error) {
      toast({ title: 'Copy Failed' })
    }
  }
  return (
    props.text ?
      <div className={styles.copy_container} >
        <AiOutlineCopy className={styles.icon_copy}  onClick={copyText}/>
      </div>
      : <span className='span_no_copy'></span>
  )
}

export default copyItem;